import React from "react";
import Layout from "./Layout.tsx";


import {
  Box,
  Grid,
} from "@mui/material";

const WelcomePage = () => {
  return (
    <Layout>
        <Box className='welcome-page-wrapper'>
            <img src='/img/transportr-welcome.png' height='92%'/>
        </Box>
    </Layout>
  );
};

export default WelcomePage;
