import React, { useEffect } from "react";
import { useFieldArray } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { IconButton, Grid } from "@mui/material";
import { Close as CloseIcon, Add as AddIcon } from "@mui/icons-material";
import { SelectField } from "../FormFields/RenderSelectField.tsx";
import i18next from '../../i18n.js';
import { DateField } from "../FormFields/RenderDateField.tsx";
import { selectFilteredUsers } from "../../data/selectors/filterSelector.js";
import { getUsers } from "../../data/users.js";
import { GetUserOptions } from "./SelectOptionsFormatter.tsx";
import { MultipleFileCollectionField } from "../FormFields/RenderMultipleFileCollectionField.tsx";

const TrainingUsers = ({
  name,
  control,
  register,
  setValue,
}: any) => {
  const dispatch = useDispatch();
  const filteredUsers = useSelector(selectFilteredUsers);

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const { fields, append, remove } = useFieldArray({
    name: name,
    control,
  });

  return (
    <>
      <Grid sx={{ maxHeight: '20vh', overflow: 'auto', paddingTop: '10px' }}>
      {fields.map((field, index) => {
        return (
          <section key={field.id}>
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={3} sx={{ marginBottom: "10px" }}>
                <SelectField
                  name="user"
                  label={i18next.t("user")}
                  control={control}
                  options={GetUserOptions(filteredUsers)}
                  {...register(`${name}.${index}.user`, {})}
                />
              </Grid>
              <Grid item xs={3} sx={{ marginBottom: "10px" }} className="form-field-datetime">
                <DateField
                    name="certificateExpirationDate"
                    label={i18next.t("certificateExpiration")}
                    control={control}
                    required
                    {...register(`${name}.${index}.certificateExpirationDate`, '')}
                />
              </Grid>
              <Grid item xs={3}>
                <MultipleFileCollectionField
                  control={control}
                  setValue={setValue}
                  register={register}
                  name={`${name}.${index}.fileGroup.files`}
                  existingFiles={field.fileGroup.files ?? []}
                  object={field}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <IconButton
                  type="button"
                  color="error"
                  size="small"
                  onClick={() => remove(index)}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </section>
        );
      })}
      </Grid>
      <IconButton
        type="button"
        color="info"
        size="small"
        onClick={() => {
          append({
            user: undefined,
            certificateExpirationDate: '',
          });
        }}
      >
        <AddIcon />
      </IconButton>
    </>
  );
};

export default TrainingUsers;
