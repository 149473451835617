import React from "react";

import {
  Grid,
  Typography,
} from "@mui/material";

export const TRANSPORT_TYPE_INCOMING = 'incoming'
export const TRANSPORT_TYPE_OUTGOING = 'outgoing'
export const TRANSPORT_MEDIUM_COLLI = 'colli'
export const TRANSPORT_MEDIUM_TANK = 'tank'
export const TRANSPORT_MEDIUM_CONTAINER = 'container'

const OUTGOING_COLLI_CHECK_LIST = [
  {
    question: {
      en: 'Loading floor – clean and free of product residues and moisture?',
      nl: 'Laadvloer – zuiver en vrij van productresten en van vocht?',
      fr: 'Plancher: état de propreté: absence de résidus de matières dangereuses, d’humidité ?',
      de: 'Ladeboden – sauber und frei von Produktrückständen und Feuchtigkeit?'
    },
    answer: false,
  },
  {
    question: {
      en: 'Loading floor – free of cracks or holes, of protruding nails or bolts?',
      nl: 'Laadvloer – vrij van scheuren of gaten, van uitstekende nagels of bouten?',
      fr: 'Plancher : état général : absence de déchirures, de trous, de clous ou boulons? état général (absence de résidu, déformation, palettes endommagées) (§ 4.1.1.1)',
      de: 'Ladeboden – frei von Rissen oder Löchern, von hervorstehenden Nägeln oder Bolzen?',
    },
    answer: false,
  },
  {
    question: {
      en: 'Packaging in good transport condition (not dirty, dented, defective pallets) (§ 4.1.1.1)',
      nl: 'Verpakkingen in goede transport toestand (niet vuil, gedeukt, defecte paletten) ( § 4.1.1.1)',
      fr: 'état général (absence de résidu, déformation, palettes endommagées) (§ 4.1.1.1)',
      de: 'Verpackung in gutem Transportzustand (nicht verschmutzt, verbeult, defekte Paletten) (§ 4.1.1.1)',
    },
    answer: false,
  },
  {
    question: {
      en: 'Orange shields ( § 8.1.3) or + 8 Tons LQ labels',
      nl: 'Oranje schilden ( § 8.1.3) of + 8 Ton LQ etiketten',
      fr: 'Panneaux orange ( § 8.1.3) ou – 8 ton LQ étiquettes',
      de: 'Orangefarbene platten (§ 8.1.3) ode + 8 Tonnen LQ-Etiketten',
    },
    answer: false,
  },
  {
    question: {
      en: 'Stowage of cargo ( § 7.5.7.1)',
      nl: 'Stuwage van de lading ( § 7.5.7.1)',
      fr: 'Arrimage du chargement ( § 7.5.7.1)',
      de: 'Stauen von Ladung (§ 7.5.7.1)',
    },
    answer: false,
  },
  {
    question: {
      en: 'Danger labels and + 8 Tons LQ labels, mark "environmentally hazardous substance" (§ 5.2.2) / LQ (§3.4) visible?',
      nl: 'Gevaar etiketten en  + 8 Ton LQ etiketten, merkteken “milieugevaarlijke stof” ( § 5.2.2) / LQ (§3.4) zichtbaar?',
      fr: 'étiquettes de danger + marque "matière dangereuse pour l’environnement" (§ 5.2.2)/LQ (§3.4) visible ?',
      de: 'Gefahretiketten und + 8 Tonnen LQ-Etiketten, Kennzeichnung "umweltgefährdender Stoff" (§ 5.2.2) / LQ (§3.4) sichtbar?',
    },
    answer: false,
  },
  {
    question: {
      en: 'Segregation respected (§ 7.5.2)',
      nl: 'Samenladingsverbod gerespecteerd  ( § 7.5.2)',
      fr: 'Segregation  ( § 7.5.2)',
      de: 'Ladeverbot respektiert (§ 7.5.2)',
    },
    answer: false,
  },
  {
    question: {
      en: 'Segregation (toxic substances + food) respected',
      nl: 'Samenladingsverbod ( giftige stoffen + voedsel) ( § 7.5.4)',
      fr: 'Précautions relatives aux denrées alimentaires ( § 7.5.4)',
      de: 'Ladeverbot (giftige Stoffe + Lebensmittel) (§ 7.5.4)',
    },
    answer: false,
  },
  {
    question: {
      en: 'Transport document',
      nl: 'Vervoerdocument',
      fr: 'Document de transport',
      de: 'Transportdokument',
    },
    answer: false,
  },
  {
    question: {
      en: 'Stowage of cargo ( § 7.5.7.1)',
      nl: 'Stuwage van de lading ( § 7.5.7.1)',
      fr: 'Arrimage du chargement ( § 7.5.7.1)',
      de: 'Stauen von Ladung (§ 7.5.7.1)',
    },
    answer: false,
  },
  {
    question: {
      en: 'Written Guidelines: (§ 5.4.3)',
      nl: 'Schriftelijke richtlijnen: taal (talen) (§ 5.4.3)',
      fr: 'Consignes écrites : langue(s) (§5,4,3)',
      de: 'Schriftliche Richtlinien: (§ 5.4.3)',
    },
    answer: false,
  },
  {
    question: {
      en: 'ADR certificate for the driver - expiry date',
      nl: 'ADR getuigschrift voor de chauffeur – vervaldatum',
      fr: 'Certificat de formation ADR du chauffeur – date de validité',
      de: 'ADR-Zertifikat für den Fahrer – Ablaufdatum',
    },
    answer: false,
  },
]

const INCOMING_TANK_CHECK_LIST = [
  {
    question: {
      en: 'Transport document',
      nl: 'Vervoerdocument',
      fr: 'Document de transport',
      de: 'Transportdokument',
    },
    answer: false,
  },
  {
    question: {
      en: 'Stowage of cargo ( § 7.5.7.1)',
      nl: 'Stuwage van de lading (§ 7.5.7.1)',
      fr: 'Arrimage du chargement (§ 7.5.7.1)',
      de: 'Stauen von Ladung (§ 7.5.7.1)',
    },
    answer: false,
  },
  {
    question: {
      en: 'Orange shields ( § 8.1.3)',
      nl: 'Oranje schilden ( § 8.1.3)',
      fr: 'Panneaux orange ( § 8.1.3)',
      de: 'Orangefarbene platten (§ 8.1.3) ',
    },
    answer: false,
  },
  {
    question: {
      en: 'Large labels (§ 8.1.3) The mark "environmentally hazardous substance" (§ 5.3.6)',
      nl: 'Grote etiketten(§ 8.1.3) Het merkteken “milieugevaarlijke stof” (§5.3.6)',
      fr: 'Plaques étiquettes +marque "matière dangereuse pour l’environnement" (§5.3.6)',
      de: 'Große Etiketten (§ 8.1.3) Das Zeichen "umweltgefährdender Stoff" (§ 5.3.6)',
    },
    answer: false,
  },
  {
    question: {
      en: 'UN number corresponds and Substance/product is allowed in the tank',
      nl: 'UN nummer stemt overeen en Stof/product is toegelaten in de tank',
      fr: 'Nummero UNO conforme au produit  et la matière/produit est autorisé dans le réservoir',
      de: 'UN-Nummer zustimmen und das Substanz/Produkt ist im Tank erlaubt',
    },
    answer: false,
  },
  {
    question: {
      en: 'Mandatory use of Stop Block ( § 8.1.5)',
      nl: 'Verplicht gebruik Stopblok ( § 8.1.5)',
      fr: 'Utilisation obligatoire du bloc d’arrêt (§ 8.1.5)',
      de: 'Obligatorische Verwendung von Stop Block (§ 8.1.5)',
    },
    answer: false,
  },
  {
    question: {
      en: 'Installation 2 Warning signs (flashing lights or danger triangles or cones) ( § 8.1.5)',
      nl: 'Plaatsen 2 Waarschuwingstekens (knipperlichten of gevarendriehoeken of kegels) ( § 8.1.5)',
      fr: 'Installation 2 Signaux d’avertissement (feux clignotants ou triangles ou cônes de danger) (§ 8.1.5)',
      de: 'Installation 2 Warnzeichen (Blinklichter oder Gefahrendreiecke oder -kegel) ( § 8.1.5)',
    },
    answer: false,
  },
  {
    question: {
      en: 'Mandatory use of personal protective equipment (§ 5.4.3 )',
      nl: 'Verplicht gebruik persoonlijke beschermingsmiddelen (§ 5.4.3)',
      fr: 'Utilisation obligatoire d’équipements de protection individuelle (§ 5.4.3 )',
      de: 'Obligatorische Verwendung von persönlicher Schutzausrüstung (§ 5.4.3 )',
    },
    answer: false,
  },
  {
    question: {
      en: 'Condition of the hoses checked – properly connected before discharging',
      nl: 'Toestand van de slangen gecontroleerd – goed aangesloten voor het ontladen',
      fr: 'Vérification de l’état des tuyaux – correctement raccordés avant le déchargement',
      de: 'Zustand der Schläuche überprüft – vor dem Ablassen ordnungsgemäß angeschlossen',
    },
    answer: false,
  },
  {
    question: {
      en: 'Filling quantity (§ 4.3.2.2 and 4.3.3.2.5)',
      nl: 'Vullingsgraad (§ 4.3.2.2 en 4.3.3.2.5)',
      fr: 'Taux de remplissage ( § 4.3.2.2 et § 4.3.3.2.5)',
      de: 'Füllgrad (§ 4.3.2.2 und 4.3.3.2.5)',
    },
    answer: false,
  },
  {
    question: {
      en: 'All lids, valves and flaps are closed after loading',
      nl: 'Alle deksels en kleppen zijn gesloten na belading',
      fr: 'Tous les couvercles et vannes sont fermées après chargement',
      de: 'Alle Deckel und Klappen werden nach dem Beladen geschlossen',
    },
    answer: false,
  },
]

const INCOMING_CONTAINER_CHECK_LIST = [
  {
    question: {
      en: 'The doors of the container close properly? No defects in the closure?',
      nl: 'Sluiten de deuren van container degelijk? Geen defecten aan de sluiting?',
      fr: 'Portes du conteneur : fermetures convenables? Pas de défectuosités au niveau des fermetures?',
      de: 'Schließen die Türen des Containers richtig? Keine Mängel im Verschluss?',
    },
    answer: false,
  },
  {
    question: {
      en: 'Are the ‘twistlocks’ of containers locked?',
      nl: 'Zijn de ‘twistlocks’ van containers vergrendeld?',
      fr: 'Les ‘twistlocks’ des conteneurs sont-ils vérroullés?',
      de: 'Sind die "Twistlocks" von Containern verschlossen?',
    },
    answer: false,
  },
  {
    question: {
      en: 'Are the engorgement points of containers in good condition, sufficiently sturdy and not corroded?',
      nl: 'Zijn de stuwingshechtpunten van containers in goede staat, voldoende stevig en niet gecorrodeerd?',
      fr: 'Les points d’ancrage pour l’arrimage des conteneurs sont-ils en bon état, solides et pas corrodés?',
      de: 'Sind die Schwellstellen von Containern in gutem Zustand, ausreichend stabil und nicht korrodiert?',
    },
    answer: false,
  },
  {
    question: {
      en: 'Orange shields ( § 8.1.3)',
      nl: 'Oranje schilden ( § 8.1.3)',
      fr: 'Panneaux orange ( § 8.1.3)',
      de: 'Orangefarbene platten (§ 8.1.3)',
    },
    answer: false,
  },
  {
    question: {
      en: 'Stowage of cargo (§ 7.5.7.1)',
      nl: 'Stuwage van de lading (§ 7.5.7.1)',
      fr: 'Arrimage du chargement (§ 7.5.7.1)',
      de: 'Stauen von Ladung (§ 7.5.7.1)',
    },
    answer: false,
  },
  {
    question: {
      en: 'Danger labels and + 8 Tons LQ labels, mark "environmentally hazardous substance" ( § 5.2.2) / LQ (§3.4) visible?',
      nl: 'Gevaar etiketten en  + 8 Ton LQ etiketten, merkteken “milieugevaarlijke stof” ( § 5.2.2) / LQ (§3.4) zichtbaar?',
      fr: 'étiquettes de danger + marque "matière dangereuse pour l’environnement" (§ 5.2.2)/LQ (§3.4) visible?',
      de: 'Gefahretiketten und + 8 Tonnen LQ-Etiketten, Kennzeichnung "umweltgefährdender Stoff" (§ 5.2.2) / LQ (§3.4) sichtbar?',
    },
    answer: false,
  },
  {
    question: {
      en: 'Segregation respected (§ 7.5.2)',
      nl: 'Samenladingsverbod gerespecteerd (§ 7.5.2)',
      fr: 'Segregation ( § 7.5.2)',
      de: 'Ladeverbot respektiert (§ 7.5.2)',
    },
    answer: false,
  },
  {
    question: {
      en: 'Segregation (toxic substances + food) respected',
      nl: 'Samenladingsverbod ( giftige stoffen + voedsel) ( § 7.5.4)',
      fr: 'Précautions relatives aux denrées alimentaires ( § 7.5.4)',
      de: 'Ladeverbot (giftige Stoffe + Lebensmittel) (§ 7.5.4)',
    },
    answer: false,
  },
  {
    question: {
      en: 'Transport document',
      nl: 'Vervoerdocument',
      fr: 'Document de transport',
      de: 'Transportdokument',
    },
    answer: false,
  },
  {
    question: {
      en: 'CPC container packing certificate',
      nl: 'Container packing certificaat',
      fr: 'Certificat d’empotage du conteneur',
      de: 'Zertifikat für die Containerverpackung',
    },
    answer: false,
  },
  {
    question: {
      en: 'Schriftelijke richtlijnen: taal (talen) (§ 5.4.3)',
      nl: 'Written Guidelines: (§ 5.4.3)',
      fr: 'Consignes écrites : langue(s) (§5,4,3)',
      de: 'Schriftliche Richtlinien: (§ 5.4.3)',
    },
    answer: false,
  },
  {
    question: {
      en: 'ADR certificate for the driver - expiry date',
      nl: 'ADR getuigschrift voor de chauffeur – vervaldatum',
      fr: 'Certificat de formation ADR du chauffeur – date de validité',
      de: 'ADR-Zertifikat für den Fahrer – Ablaufdatum',
    },
    answer: false,
  },
]

const INCOMING_COLLI_CHECK_LIST = [
  {
    question: {
      en: 'Transport document',
      nl: 'Vervoerdocument',
      fr: 'Document de transport',
      de: 'Transportdokument',
    },
    answer: false,
  },
  {
    question: {
      en: 'Stowage of cargo ( § 7.5.7.1)',
      nl: 'Stuwage van de lading (§ 7.5.7.1)',
      fr: 'Arrimage du chargement (§ 7.5.7.1)',
      de: 'Stauen von Ladung (§ 7.5.7.1)',
    },
    answer: false,
  },
  {
    question: {
      en: 'Orange shields (§ 8.1.3) or + 8 Tons LQ labels',
      nl: 'Oranje schilden (§ 8.1.3) of + 8 Ton LQ etiketten',
      fr: 'Panneaux orange ( § 8.1.3) ou – 8 ton LQ étiquettes',
      de: 'Orangefarbene platten (§ 8.1.3) ode + 8 Tonnen LQ-Etiketten ',
    },
    answer: false,
  },
  {
    question: {
      en: 'Packaging in good transport condition (not dirty, dented, defective pallets) (§ 4.1.1.1)',
      nl: 'Verpakkingen in goede transport toestand (niet vuil, gedeukt, defecte paletten) (§ 4.1.1.1)',
      fr: 'état général (absence de résidu, déformation, palettes endommagées) (§ 4.1.1.1)',
      de: 'Verpackung in gutem Transportzustand (nicht verschmutzt, verbeult, defekte Paletten) (§ 4.1.1.1)',
    },
    answer: false,
  },
  {
    question: {
      en: 'UN number on colli (§ 5.2.1.1)',
      nl: 'UN nummer op de colli (§ 5.2.1.1)',
      fr: 'Nummero UNO sur l’emballages (§ 5.2.1.1)',
      de: 'UN Zahl auf den Verpakunigen (§ 5.2.1.1)',
    },
    answer: false,
  },
  {
    question: {
      en: 'Danger labels and and LQ labels, mark "environmentally hazardous substance" ( § 5.2.2) / LQ (§3.4) visible?',
      nl: 'Gevaar etiketten en LQ etiketten, merkteken “milieugevaarlijke stof” (§ 5.2.2) / LQ (§3.4) zichtbaar? ',
      fr: 'étiquettes de danger + marque "matière dangereuse pour l’environnement" (§ 5.2.2)/LQ (§3.4) visible?',
      de: 'Gefahretiketten und LQ-Etiketten, Kennzeichnung "umweltgefährdender Stoff" (§ 5.2.2) / LQ (§3.4) sichtbar?',
    },
    answer: false,
  },
  {
    question: {
      en: 'IBC test date for liquid: not expired (2.5 years) ( § 6.5.4.14)',
      nl: 'Testdatum IBC’s voor vloeistof: niet vervallen (2,5jaar) ( § 6.5.4.14)',
      fr: 'GRV métallique, plastique rigide, composite :validité de l’épreuve d’étanchéité :2,5 ans ( § 6.5.4.14)',
      de: 'IBC-Prüfdatum für Flüssigkeit: nicht abgelaufen (2,5 Jahre) (§ 6.5.4.14)',
    },
    answer: false,
  },
  {
    question: {
      en: 'Expiration date of plastic packaging and IBCs = max. 5 years (§ 4.1.1.15)',
      nl: 'Gebruiksduur van verpakkingen en IBC’s uit kunststof = max. 5jaar (§ 4.1.1.15)',
      fr: 'fûts en plastique, GRV plastique rigide et composite durée d’utilisation = max. 5 ans ( § 4.1.1.15)',
      de: 'Nutzungsdauer von Kunststoffverpackungen und IBC = max. 5 Jahre ( § 4.1.1.15)',
    },
    answer: false,
  },
]

export const DRIVER_ADN_CERTIFICATE = {
  en: 'Does the driver have a valid ADN certificate?',
  nl: 'Heeft de bestuurder een geldig ADN-certificaat?',
  fr: "Le conducteur dispose-t-il d'un certificat ADN valide ?",
  de: 'Verfügt der Fahrer über eine gültige ADN-Bescheinigung?',
}

const CHECK_LIST = {
  incoming: {
    colli: INCOMING_COLLI_CHECK_LIST,
    tank: INCOMING_TANK_CHECK_LIST,
    container: INCOMING_CONTAINER_CHECK_LIST,
  },
  outgoing: {
    colli: OUTGOING_COLLI_CHECK_LIST,
    tank: INCOMING_TANK_CHECK_LIST,
    container: INCOMING_CONTAINER_CHECK_LIST,
  },
}

export const TransportChecklist= (
  transportType=TRANSPORT_TYPE_INCOMING, 
  medium=TRANSPORT_MEDIUM_COLLI, 
) => {
    return CHECK_LIST[transportType][medium];
};

export const getChecklistAnswers = (checklist:any) => {
  let checklistAnswers = [];

  checklist.map((cl:any) => {
        checklistAnswers.push(cl.answer)
  })

  return checklistAnswers;
}

export const getChecklistQuestions = (questions:any, languages) => {
  let questionsByLanguages = languages.map((language) => {
      return `(${language.toUpperCase()}) ${ questions[language] }`;
  })

  return (
    <Grid item>
      { questionsByLanguages && questionsByLanguages.map((question) => (
        <Typography fontSize='12px'>{question}</Typography>
      ))}
    </Grid>
  );
}
