import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { showModal } from "../../data/modal.tsx";
import { useLocation } from "react-router-dom";
import i18next from '../../i18n.js';

import {
    IconButton,
} from "@mui/material";

import {
    Star as StarIcon,
    StarBorder as StarBorderIcon,
} from '@mui/icons-material';

const UserPreferredLanding = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const selectedData = useSelector((state) => ({
        currentUser: state.currentUser?.data,
    }));

    const {
        currentUser
    } = selectedData;

    return (
        <IconButton
            title={i18next.t("titleSetPreferredLandingPage")}
            onClick={() => {
                currentUser && currentUser?.preferredLanding !== location.pathname ?
                dispatch(
                    showModal({
                        title: i18next.t("defaultLandingPage"),
                        description: "User's default landing page",
                        form: "preferredLanding",
                        formProps: { object: currentUser, pathname: location.pathname },
                    })) 
                : '#'
            }}
        >
            { currentUser && currentUser?.preferredLanding === location.pathname  ? (
                <StarIcon size="small" color='warning' />
            ) : (
                <StarBorderIcon size="small" />
            ) } 
        </IconButton>
    )
}

export default UserPreferredLanding;
