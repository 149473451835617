import './App.css';
import './index.css';
import React from 'react';
import { Provider } from "react-redux";
import store from "./data";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/login.tsx';
import RedirectionPage from './components/Redirection.tsx';
import TransportDocument from './components/transport/index.tsx';
import ProductManage from './components/admin/product/index.tsx';
import UserManage from './components/admin/user/index.tsx';
import TenantManage from './components/admin/tenant/index.tsx';
import SiteManage from './components/admin/site/index.tsx';
import PackagingManage from './components/admin/packaging/index.tsx';
import ContactManage from './components/settings/contact/index.tsx';
import LabelManage from './components/settings/label/index.tsx';
import TransporterManage from './components/settings/transporter/index.tsx';
import Incidents from './components/incidents/index.tsx';
import ProductList from './components/products/index.tsx';
import TransportReport from './components/reports/transport/index.tsx';
import WelcomePage from './components/welcome.tsx';
import UserList from './components/users/index.tsx';
import TrainingList from './components/training/index.tsx';
import ADNIndexReport from './components/reports/adnIndex/index.tsx';
import TrainingManage from './components/admin/training/index.tsx';
import InspectionManage from './components/admin/inspection/index.tsx';
import InspectionList from './components/inspection/index.tsx';

function App() {
  return (
    <Provider store={store} >
      <Router>
        <Routes>
          <Route path='/' Component={Login}></Route>
          <Route path='/redirection' Component={RedirectionPage}></Route>
          <Route path='/welcome' Component={WelcomePage}></Route>
          <Route path='/incidents' Component={Incidents}></Route>
          <Route path='/reports/transport/:type' Component={TransportReport}></Route>
          <Route path='/reports/adn-index/:type' Component={ADNIndexReport}></Route>
          <Route path='/settings/admin/product' Component={ProductManage}></Route>
          <Route path='/product-list' Component={ProductList}></Route>
          <Route path='/settings/label' Component={LabelManage}></Route>
          <Route path='/settings/contact' Component={ContactManage}></Route>
          <Route path='/settings/transporter' Component={TransporterManage}></Route>
          <Route path='/settings/admin/user' Component={UserManage}></Route>
          <Route path='/user-list' Component={UserList}></Route>
          <Route path='/training-list' Component={TrainingList}></Route>
          <Route path='/inspection-list' Component={InspectionList}></Route>
          <Route path='/settings/admin/tenant' Component={TenantManage}></Route>
          <Route path='/settings/admin/site' Component={SiteManage}></Route>
          <Route path='/settings/admin/packaging' Component={PackagingManage}></Route>
          <Route path='/settings/admin/training' Component={TrainingManage}></Route>
          <Route path='/settings/admin/inspection' Component={InspectionManage}></Route>
          <Route path='/transport-document/:type' Component={TransportDocument}></Route>
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
