import * as React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Controller } from "react-hook-form";
import { showChildModal } from "../../data/modal.tsx";
import { useDispatch } from "react-redux";
import { findObjectById } from '../Helpers.tsx';

import {  
  Chip,
  Stack, 
  Typography,
  Grid,
  IconButton,
} from "@mui/material";

import {
  Add as AddIcon,
  Edit as EditIcon,
} from "@mui/icons-material";

export const SelectCrudField: any = ({
  name,
  control,
  label,
  data,
  options,
  optionLabel = [],
  required = false,
  disabled = false,
  disableCreate = false,
  createModalProps = {},
  editModalProps = {},
  multiple=false,
}) => {
  const dispatch = useDispatch();

  const formatOptionLabel = (object) => {
    const labels = [];
    
    optionLabel.map((label) => {
      if (object[label] !== undefined) {
        labels.push(object[label])
      }
    })

    return labels.join(' ')
  }

  const formattedOptions = () => {
    const formattedOptions = [];

    if (options) {
      return options;
    }

    if (!data || data.length === 0) {
      return formattedOptions;
    }
    
    data.map((object) => {
      formattedOptions.push({
        value: object["@id"],
        label: formatOptionLabel(object),
      });
    });

    return formattedOptions;
  };

  if (formattedOptions().length === 0 && !data) {
    return <></>;
  }

  const renderChipOption = (option) => {
    return (
      <MenuItem key={`key-${option.value}`} value={option.value}>
        <Chip
          label={option.label}
          onDelete={ () => {
            dispatch(showChildModal({
              ...editModalProps,
              formProps: {
                ...editModalProps.formProps,
                object: (option.value && option.value['@id'] ? option.value : findObjectById(data, option.value))
              }
            }))
          }}
          deleteIcon={<EditIcon />}
        />
      </MenuItem>
    );
  }

  const renderOption = (option) => {
    return (
      <MenuItem key={`key-${option.value}`} value={option.value}>
        <Stack direction="row" spacing={1} alignItems='center'>
          {option.content ?? ''}
          {option.icon ?? ''}
          <Typography>{option.label}</Typography>
        </Stack>
      </MenuItem>
    );
  }

  const generateOptions = () => {
    return formattedOptions().map((option: any) => {
      if (multiple) {
        return renderChipOption(option);
      }

      return renderOption(option);
    });
  };

  const valueHandler = (value) => {
    if (multiple) {
      return value ? value.map(v => v['@id'] ? v['@id'] : v) : [] 
    }

    return value && value !== undefined ? (value['@id'] ? value['@id'] : value) : '';
  }

  return (
    <FormControl size={"small"} sx={{ width: '100%' }}>
      <InputLabel>{`${label}${ required ? '*' : ''}`}</InputLabel>
      <Controller
        render={({ field: { onChange, value } }) => (
          <Grid container flexDirection='row' alignItems='center' flexWrap='nowrap' gap='2px'>
            <Grid item xs={9}>
              <Select 
                onChange={onChange} 
                value={ valueHandler(value) } 
                required={required} 
                fullWidth
                disabled={disabled}
                multiple={multiple}
              >
                <MenuItem key='All' value={ null }>
                  Select
                </MenuItem>
                { formattedOptions().length > 0 ? generateOptions() : '' }
              </Select>
            </Grid>
            <Grid item xs={3}>
              { createModalProps ? (
                <IconButton
                  size='small'
                  disabled={disableCreate}
                  onClick={ () => {
                    dispatch(showChildModal(createModalProps))
                  }}
                >
                  <AddIcon />
                </IconButton>
              ) : ''}
              { editModalProps && !multiple ? (
                <IconButton
                  size='small'
                  disabled={!value}
                  onClick={ () => {
                    dispatch(showChildModal({
                      ...editModalProps,
                      formProps: {
                        ...editModalProps.formProps,
                        object: (value && value['@id'] ? value : findObjectById(data, value))
                      }
                    }))
                  }}
                >
                  <EditIcon />
                </IconButton>
              ) : ''}
            </Grid>
          </Grid>
        )}
        control={control}
        name={name}
        defaultValue={undefined}
      />
    </FormControl>
  );
};