import React, { useState } from 'react';
import i18next from '../../i18n.js';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import {
  Delete as DeleteIcon,
} from "@mui/icons-material";

export default function DeleteButton({ children }:any) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        onClick={handleClickOpen}
        variant="contained"
        color="error"
      >
        <DeleteIcon fontSize="inherit" />
        &nbsp;{i18next.t("delete")}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {i18next.t("deleteWarningMessage")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
              {i18next.t("deleteInformation")}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display:'flex', justifyContent:'space-between' }}>
          <Button onClick={handleClose}>{i18next.t("close")}</Button>
          {children}
        </DialogActions>
      </Dialog>
    </>
  );
}
