import { createSelector } from "@reduxjs/toolkit";

const selectOutgoingTransports = (state) => state.outgoingTransports;
const selectIncomingTransports = (state) => state.incomingTransports;
const selectProducts = (state) => state.products;
const selectUsers = (state) => state.users;
const selectSites = (state) => state.sites;
const selectContacts = (state) => state.contacts;
const selectLabels = (state) => state.labels;
const selectPackagings = (state) => state.packagings;
const selectTransporters = (state) => state.transporters;
const selectTrainings = (state) => state.trainings;
const selectDrivers = (state) => state.drivers;
const selectInspections = (state) => state.inspections;

const selectTransportFilter = (state) => state.filter.transport;
const selectTenantFilter = (state) => state.filter.tenant;
const selectActiveFilter = (state) => state.filter.active;
const selectTransportTypeFilter = (state) => state.filter.transportType;
const selectTransporterFilter = (state) => state.filter.transporter;

export const selectFilteredIncomingTransports = createSelector(
  [selectTenantFilter, selectIncomingTransports, selectTransportFilter],
  (tenant, transports, filter) => {
    let filteredData = transports.data;

    if (tenant) {
      filteredData = filteredData.filter((transport) =>
        transport.tenant && transport.tenant['@id'] === tenant['@id']
      );
    }

    if (filter.labels.length) {
      filteredData = filteredData.filter((transport) =>
        transport.labels.some((label) => filter.labels.includes(label['@id']))
      );
    }
    
    if (filter.sites.length) {
      filteredData = filteredData.filter((transport) =>
        filter.sites.includes(transport.site)
      );
    }

    return filteredData;
  }
);

export const selectFilteredOutgoingTransports = createSelector(
  [selectTenantFilter, selectOutgoingTransports, selectTransportFilter],
  (tenant, transports, filter) => {
    let filteredData = transports.data;

    if (tenant) {
      filteredData = filteredData.filter((transport) =>
        transport.tenant && transport.tenant['@id'] === tenant['@id']
      );
    }

    if (filter.labels.length) {
      filteredData = filteredData.filter((transport) =>
        transport.labels.some((label) => filter.labels.includes(label['@id']))
      );
    }
    
    if (filter.sites.length) {
      filteredData = filteredData.filter((transport) =>
        filter.sites.includes(transport.site)
      );
    }

    return filteredData;
  }
);

export const selectFilteredProducts = createSelector(
  [selectActiveFilter, selectTenantFilter, selectProducts, selectTransportTypeFilter],
  (active, tenant, products, transportType) => {
    let filteredData = products.data;

    if (tenant !== undefined) {
      filteredData = filteredData.filter((product) =>
        product.tenant && product.tenant === tenant['@id']
      );
    }
    
    if (transportType) {
      filteredData = filteredData.filter((product) =>
         (product.transportType && product.transportType === transportType)
      );
    }

    filteredData = filteredData.filter((product) =>
      active === undefined || !active ? (product.active === undefined || product.active === false) : product.active === active
    );

    return filteredData;
  }
);

export const selectFilteredUsers = createSelector(
  [selectActiveFilter, selectTenantFilter, selectUsers],
  (active, tenant, users) => {
    let filteredData = users.data;

    if (tenant !== undefined) {
      filteredData = filteredData.filter((user) =>
        user.tenant && user.tenant['@id'] === (tenant['@id'] ?? tenant)
      );
    }

    filteredData = filteredData.filter((user) =>
      active === undefined || !active ? (user.isActive === undefined || user.isActive === false) : user.isActive === active
    );

    return filteredData;
  }
);

export const selectFilteredSites = createSelector(
  [selectActiveFilter, selectTenantFilter, selectSites],
  (active, tenant, sites) => {
    let filteredData = sites.data;

    if (tenant !== undefined) {
      filteredData = filteredData.filter((site) =>
        site.tenant && site.tenant === tenant['@id']
      );
    }

    filteredData = filteredData.filter((site) =>
      active === undefined || !active ? (site.active === undefined || site.active === false) : site.active === active
    );

    return filteredData;
  }
);

export const selectFilteredContacts = createSelector(
  [selectTenantFilter, selectContacts],
  (tenant, contacts) => {
    let filteredData = contacts.data;

    if (tenant !== undefined) {
      filteredData = filteredData.filter((contact) =>
        contact.tenant && contact.tenant === tenant['@id']
      );
    }

    return filteredData;
  }
);

export const selectFilteredLabels = createSelector(
  [selectTenantFilter, selectLabels],
  (tenant, labels) => {
    let filteredData = labels.data;
    
    if (tenant !== undefined) {
      filteredData = filteredData.filter((label) =>
        (label.tenant === tenant['@id']) || label.tenant === null
      );
    }

    return filteredData;
  }
);

export const selectFilteredPackagings = createSelector(
  [selectTenantFilter, selectPackagings],
  (tenant, packagings) => {
    let filteredData = packagings.data;

    if (tenant !== undefined) {
      filteredData = filteredData.filter((packaging) =>
        packaging.tenant === tenant['@id']
      );
    }

    return filteredData;
  }
);

export const selectFilteredTransporters = createSelector(
  [selectActiveFilter, selectTenantFilter, selectTransporters],
  (active, tenant, transporters) => {
    let filteredData = transporters.data;

    if (tenant !== undefined) {
      filteredData = filteredData.filter((transporter) =>
        (transporter.tenant && transporter.tenant['@id'] && transporter.tenant['@id'] === tenant['@id']) || transporter.tenant === null
      );
    }

    filteredData = filteredData.filter((transporter) =>
      active === undefined || !active ? (transporter.active === undefined || transporter.active === false) : transporter.active === active
    );

    return filteredData;
  }
);

export const selectFilteredTrainings = createSelector(
  [selectTenantFilter, selectTrainings],
  (tenant, trainings) => {
    let filteredData = trainings.data;

    if (tenant !== undefined) {
      filteredData = filteredData.filter((training) =>
        training.tenant === tenant['@id']
      );
    }

    return filteredData;
  }
);

export const selectFilteredDrivers = createSelector(
  [selectTenantFilter, selectDrivers, selectTransporterFilter],
  (tenant, drivers, transporter) => {
    let filteredData = drivers.data;

    if (tenant !== undefined) {
      filteredData = filteredData.filter((driver) =>
        driver.tenant === tenant['@id']
      );
    }

    if (transporter !== undefined) {
      filteredData = filteredData.filter((driver) =>
        driver.transporter === transporter['@id']
      );
    }
   
    return filteredData;
  }
);

export const selectFilteredInspections = createSelector(
  [selectTenantFilter, selectInspections],
  (tenant, inspections) => {
    let filteredData = inspections.data;

    if (tenant !== undefined) {
      filteredData = filteredData.filter((inspection) =>
        inspection.tenant === tenant['@id']
      );
    }

    return filteredData;
  }
);