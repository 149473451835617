import axios from "axios";
import { hideModal } from "./modal.tsx";
import { useJWTExpirationCheck } from "./authentication.js";

/*****************/
/* INITIAL STATE */
/*****************/
const initialState = {
  loading: false,
  error: "",
  data: [],
  progress: {
    page: 0,
    pages: 0,
    total: 0,
    loaded: 0,
  },
};

/*********/
/* TYPES */
/*********/
const FETCH_TENANTS = "FETCH_TENANTS";
const FETCH_TENANTS_SUCCESS = "FETCH_TENANTS_SUCCESS";
const FETCH_TENANTS_ERROR = "FETCH_TENANTS_ERROR";
const PUT_TENANT = "PUT_TENANT";
const POST_TENANT = "POST_TENANT";
const DELETE_TENANT = "DELETE_TENANT";
const FETCH_NEXT_TENANTS = "FETCH_NEXT_TENANTS";
const FETCH_NEXT_TENANTS_SUCCESS = "FETCH_NEXT_TENANTS_SUCCESS";

/*******************/
/* ACTION CREATORS */
/*******************/
export const getTenants = () => async (dispatch) => {
  dispatch(loadTenants());

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/tenants?page=1`,
    {
      headers: {
        "Content-Type": "application/ld+json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  const json = await response.json();
  useJWTExpirationCheck(json);

  if (json["hydra:member"].length === 0) {
    dispatch(setError("No tenants found"));
  } else {
    dispatch(
      setTenants(json["hydra:member"], parseInt(json["hydra:totalItems"], 10))
    );
    if (parseInt(json["hydra:totalItems"], 10) > json["hydra:member"].length) {
      dispatch(getNextTenants());
    }
  }
};

export const getNextTenants = () => async (dispatch, getState) => {
  const {
    tenants: {
      progress: { page, pages },
    },
  } = getState();
  const pageToLoad = page + 1;
  dispatch(loadNextTenants(pageToLoad));

  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/tenants?page=${pageToLoad}
    }`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  dispatch(setNextTenants(response.data["hydra:member"]));
  if (pageToLoad < pages) {
    dispatch(getNextTenants());
  }
};

export const updateTenant = (tenant) => async (dispatch, getState) => {
  dispatch(putTenant());
  const {
    tenants: {
      data,
      progress: { total },
    },
  } = getState();

  const response = await axios({
    method: "put",
    url: `${process.env.REACT_APP_API_URL}/tenants/${tenant["@id"].replace(
      "/api/tenants/",
      ""
    )}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: tenant,
  });

  if (response.status === 200) {
    const resultData = data.map((tenant) => {
      if (tenant["@id"] === response.data["@id"]) {
        return response.data;
      }

      return tenant;
    });

    dispatch(setTenants(resultData, total));
    dispatch(hideModal());
  } else {
    setError("Something went wrong updating");
  }
};

export const createTenant = (tenant) => async (dispatch, getState) => {
  dispatch(postTenant());
  const {
    tenants: {
      data,
      progress: { total },
    },
  } = getState();

  const response = await axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/tenants`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: tenant,
  });

  if (response.status === 201) {
    const resultData = [...data, ...[response.data]];

    dispatch(setTenants(resultData, total));
    dispatch(hideModal());
  } else {
    setError("Something went wrong deleting");
  }
};

export const removeTenant = (tenant) => async (dispatch, getState) => {
  dispatch(putTenant());
  const {
    tenants: {
      data,
      progress: { total },
    },
  } = getState();

  const response = await axios({
    method: "delete",
    url: `${process.env.REACT_APP_API_URL}${tenant["@id"].replace("/api", "")}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.status === 204) {
    const resultData = data.filter((x) => x["@id"] !== tenant["@id"]);

    dispatch(setTenants(resultData, total));
    dispatch(hideModal());
  } else {
    setError("Something went wrong deleting");
  }
};

export const loadTenants = () => ({ type: FETCH_TENANTS });

export const putTenant = () => ({ type: PUT_TENANT });

export const postTenant = () => ({ type: PUT_TENANT });

export const deleteTenant = () => ({ type: DELETE_TENANT });

export const setTenants = (tenants, total) => ({
  type: FETCH_TENANTS_SUCCESS,
  payload: { tenants, total },
});

export const loadNextTenants = (page) => ({
  type: FETCH_NEXT_TENANTS,
  payload: page,
});
export const setNextTenants = (tenants) => ({
  type: FETCH_NEXT_TENANTS_SUCCESS,
  payload: tenants,
});

export const setError = (msg) => ({
  type: FETCH_TENANTS_ERROR,
  payload: msg,
});

/***********/
/* REDUCER */
/***********/
const tenantReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_TENANTS:
      return {
        ...state,
        loading: true,
        error: "",
        progress: {
          page: 1,
          pages: 0,
          loaded: 0,
          total: 0,
        },
      };
    case FETCH_TENANTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        data: payload.tenants,
        progress: {
          ...state.progress,
          loaded: payload.tenants.length,
          pages: Math.ceil(payload.total / payload.tenants.length),
          total: payload.total,
        },
      };

    case PUT_TENANT:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case POST_TENANT:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case DELETE_TENANT:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_TENANTS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case FETCH_NEXT_TENANTS:
      return {
        ...state,
        error: "",
        progress: {
          ...state.progress,
          page: payload,
        },
      };
    case FETCH_NEXT_TENANTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, ...payload],
        progress: {
          ...state.progress,
          loaded: state.progress.loaded + payload.length,
        },
      };
    default:
      return state;
  }
};
export default tenantReducer;
