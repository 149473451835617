import axios from "axios";
import { hideChildModal, hideModal } from "../data/modal.tsx";
import { useJWTExpirationCheck } from "./authentication.js";

/*****************/
/* INITIAL STATE */
/*****************/
const initialState = {
  loading: false,
  error: "",
  data: [],
  progress: {
    page: 0,
    pages: 0,
    total: 0,
    loaded: 0,
  },
};

/*********/
/* TYPES */
/*********/
const FETCH_FILES = "FETCH_FILES";
const FETCH_FILES_SUCCESS = "FETCH_FILES_SUCCESS";
const FETCH_FILES_ERROR = "FETCH_FILES_ERROR";
const PUT_FILE = "PUT_FILE";
const POST_FILE = "POST_FILE";
const DELETE_FILE = "DELETE_FILE";
const FETCH_NEXT_FILES = "FETCH_NEXT_FILES";
const FETCH_NEXT_FILES_SUCCESS = "FETCH_NEXT_FILES_SUCCESS";

/*******************/
/* ACTION CREATORS */
/*******************/
export const getFiles = () => async (dispatch) => {
  dispatch(loadFiles());

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/files?page=1`,
    {
      headers: {
        "Content-Type": "application/ld+json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  const json = await response.json();
  useJWTExpirationCheck(json);

  if (json["hydra:member"].length === 0) {
    dispatch(setError("No files found"));
  } else {
    dispatch(
      setFiles(json["hydra:member"], parseInt(json["hydra:totalItems"], 10))
    );
    if (parseInt(json["hydra:totalItems"], 10) > json["hydra:member"].length) {
      dispatch(getNextFiles());
    }
  }
};

export const getNextFiles = () => async (dispatch, getState) => {
  const {
    files: {
      progress: { page, pages },
    },
  } = getState();
  const pageToLoad = page + 1;
  dispatch(loadNextFiles(pageToLoad));

  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/files?page=${pageToLoad}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  dispatch(setNextFiles(response.data["hydra:member"]));
  if (pageToLoad < pages) {
    dispatch(getNextFiles());
  }
};

export const updateFile = (file) => async (dispatch, getState) => {
  dispatch(putFile());
  const {
    files: {
      data,
      progress: { total },
    },
  } = getState();

  const response = await axios({
    method: "put",
    url: `${process.env.REACT_APP_API_URL}${file["@id"].replace("/api", "")}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: file,
  });

  if (response.status === 200) {
    const resultData = data.map((file) => {
      if (file["@id"] === response.data["@id"]) {
        return response.data;
      }

      return file;
    });

    dispatch(setFiles(resultData, total));
  } else {
    setError("Something went wrong updating");
  }
};

export const createFile = (file, callback) => async (dispatch, getState) => {
  dispatch(postFile());
  const {
    files: {
      data,
      progress: { total },
      modal: childModal,
    },
  } = getState();

  const formData = new FormData();
  formData.append("file", file);

  const response = await axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/files`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: formData,
  });

  if (response.status === 201) {
    const resultData = [...data, ...[response.data]];

    dispatch(setFiles(resultData, total));
    return response.data;
  } else {
    setError("Something went wrong deleting");
  }
};

export const removeFile = (file) => async (dispatch, getState) => {
  dispatch(putFile());
  const {
    files: {
      data,
      progress: { total },
    },
  } = getState();

  const response = await axios({
    method: "delete",
    url: `${process.env.REACT_APP_API_URL}${file["@id"].replace("/api", "")}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.status === 204) {
    const resultData = data.filter((x) => x["@id"] !== file["@id"]);

    dispatch(setFiles(resultData, total));
    dispatch(hideModal());
  } else {
    setError("Something went wrong deleting");
  }
};

export const loadFiles = () => ({ type: FETCH_FILES });

export const putFile = () => ({ type: PUT_FILE });

export const postFile = () => ({ type: POST_FILE });

export const deleteFile = () => ({ type: DELETE_FILE });

export const setFiles = (files, total) => ({
  type: FETCH_FILES_SUCCESS,
  payload: { files, total },
});

export const loadNextFiles = (page) => ({
  type: FETCH_NEXT_FILES,
  payload: page,
});
export const setNextFiles = (files) => ({
  type: FETCH_NEXT_FILES_SUCCESS,
  payload: files,
});

export const setError = (msg) => ({
  type: FETCH_FILES_ERROR,
  payload: msg,
});

/***********/
/* REDUCER */
/***********/
const fileReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_FILES:
      return {
        ...state,
        loading: true,
        error: "",
        progress: {
          page: 1,
          pages: 0,
          loaded: 0,
          total: 0,
        },
      };
    case FETCH_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        data: payload.files,
        progress: {
          ...state.progress,
          loaded: payload.files.length,
          pages: Math.ceil(payload.total / payload.files.length),
          total: payload.total,
        },
      };

    case PUT_FILE:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case POST_FILE:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case DELETE_FILE:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_FILES_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case FETCH_NEXT_FILES:
      return {
        ...state,
        error: "",
        progress: {
          ...state.progress,
          page: payload,
        },
      };
    case FETCH_NEXT_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, ...payload],
        progress: {
          ...state.progress,
          loaded: state.progress.loaded + payload.length,
        },
      };
    default:
      return state;
  }
};
export default fileReducer;
