import * as React from 'react';
import { Controller } from "react-hook-form";
import { Checkbox, FormControlLabel } from "@mui/material";

export const CheckboxField = ({ name, label, control, defaultValue = true, required = false }: any) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, value },
        fieldState: { error },
      }) => (
       
        <FormControlLabel
          label={label}
          control={
            <Checkbox
              size="small"
              helperText={ error ? error.message : null }
              error={!!error}
              onChange={onChange}
              checked={ value ?? defaultValue }
              required={ required }
            />
          }
        />
      )}
    />
  );
};