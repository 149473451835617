import React from "react";
import { jsPDF } from "jspdf";
import { v4 as uuid } from "uuid";
import i18next from '../../i18n.js';

import {
    Button,
    IconButton,
} from "@mui/material";
  
import {
    PictureAsPdfSharp as PictureAsPdfSharpIcon,
} from "@mui/icons-material";

export const DOCUMENT_ORIENTATION_PORTRAIT = 'portrait';
export const DOCUMENT_ORIENTATION_LANDSCAPE = 'landscape';
export const BUTTON_TYPE_BUTTON = 'button';
export const BUTTON_TYPE_ICON = 'icon';

const DOCUMENT_ORIENTATIONS = {
    'portrait': 'p',
    'landscape': 'l',
}

const createPdf = (orientation: string) => {
    return new jsPDF(DOCUMENT_ORIENTATIONS[orientation], "pt", "a3", true);
}

const generatePdf = (filename?: string | undefined, orientation: string = DOCUMENT_ORIENTATION_PORTRAIT) => {
    const pdf = createPdf(orientation)
    const htmlContainer: any = document.querySelector(".pdf-document-content");
    const imgs: any = document.querySelectorAll(".pdf-document-content img");
    
    if (imgs.length > 0) {
        imgs.forEach((img) => {
            pdf.addImage(img, "PNG", 0, 0, 0, 0, "", "FAST");
        })
    }

    pdf.html(htmlContainer, {
        html2canvas: {
            scale: 0.92,
            scrollX: 0,
            scrollY: -window.scrollY,
            useCORS: true,
        },
        callback(pdf) {
            //window.open(pdf.output('bloburl')); //open to a new tab to debug
            pdf.save(`${filename !== undefined ? filename : uuid()}.pdf`);
        },
        x: 0,
        y: 0,
    });
};

const GeneratePDF = ({filename=undefined, orientation, buttonType = BUTTON_TYPE_BUTTON}) => {
    if (buttonType === BUTTON_TYPE_ICON) {
        return (
            <>
                <IconButton onClick={() => generatePdf(filename, orientation)}>
                    <PictureAsPdfSharpIcon/>
                </IconButton>
            </>
        )
    }

    return (
        <>
            <Button variant="contained"  onClick={() => generatePdf(filename, orientation)}>
                <PictureAsPdfSharpIcon fontSize="inherit" />
                &nbsp;{i18next.t("generatePdf")}
            </Button>
        </>
    );
}
  
  export default GeneratePDF;
  