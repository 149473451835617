import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { showModal, showChildModal } from "../../data/modal.tsx";
import i18next from '../../i18n.js';

import {
    Grid,
    Typography,
} from "@mui/material";

import {
  FileOpenSharp as FileOpenSharpIcon,
} from "@mui/icons-material";

const ProductSDS = ({product}) => {
  const dispatch = useDispatch();

  const selectedData = useSelector((state) => ({
      modal: state.modal
  }));

  const handleModalShow = (file) => {
      const fileViewModal = {
        title: i18next.t("sdsFile"),
        description: 'SDS File',
        form: "fileView",
        formProps: { 
          file: file,
          showCloseButton: true,
        },
      }

      if (!selectedData.modal.childModal) {
        dispatch(showChildModal(fileViewModal));
      } else {
        dispatch(showModal(fileViewModal));
      }
  };

  const renderSDSFile = (file, index) => {
    return (
      <Grid 
        className='product-sds'
        item 
        sx={{ 
          display:'flex', 
          alignItems: 'center', 
          flexDirection:'row', 
          flexWrap: 'nowrap',
          width: '100px',
        }}
        onClick={() => handleModalShow(file)}
      >
        <Grid item sx={{ display:'flex', alignItems: 'center' }}>
          <FileOpenSharpIcon size='small' color='default'/>
        </Grid>
        <Grid item paddingLeft={0}>
          <Typography fontSize='12px'>
              {`${i18next.t("sdsFile")} #${index+1}`}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
    { product && product.fileGroup &&  product.fileGroup.files?.length > 0 ? (
      <>
        <Grid 
          xs={12}
          sm={12} 
          gap={3} 
          flexWrap='nowrap' 
          flexDirection='row' 
          justifyContent='flex-start'
          alignItems='center'
          marginTop={1}
        >
          <Grid 
            item
            xs={12}
            sm={12} 
          >
            <Typography fontSize='inherit' fontWeight={700}>
              { `SDS ${product.nameEn ?? ''} ${product.nameNl ? `- ${product.nameNl}`: ''} ${product.nameFr ? `- ${product.nameFr}`: ''} ${product.nameDe ? `- ${product.nameDe}`: ''}` }
            </Typography>
          </Grid>
          <Grid 
            item 
            sx={{ 
              display:'flex', 
              alignItems: 'center', 
              flexDirection:'row', 
              flexWrap: 'nowrap',
              gap: 1,
            }}
          >
          { product.fileGroup.files.map((file: any, index:any) => (
            renderSDSFile(file, index)
          ))}
          </Grid>
        </Grid>
      </>
    ) : ''}
    </>
  )
}

export default ProductSDS
