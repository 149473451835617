import React from "react";
import { useWatch } from "react-hook-form";

import {
  Tooltip,
  Typography,
} from "@mui/material";

function findProductForId(productId, products) {
  return products.find((x) => x["@id"] === productId);
}

function getProductUnit(value, products) {
  if (!value["product"]) {
    return '';
  }

  let product = value.product;
  if (typeof value.product === "string") {
    product = findProductForId(value.product, products);
  }

  if (product === undefined) {
    return '';
  }


  return product.unit
}

export function ProductUnit({ control, products, index, name }) {
  const results = useWatch({ control, name: `${name}` });
  let row = results[index];

  if (!row) {
    return <p></p>;
  }

  let unit = getProductUnit(row, products);

  return (
    <Tooltip title={  unit ?? "No unit"}>
      <Typography className='text-ellipsis'>{  unit ?? ""}</Typography>
    </Tooltip>
  );
}

export default ProductUnit;
