import React from "react";
import  { Link } from 'react-router-dom'

import { 
  MenuList,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';

import {
  ContactEmergency as ContactEmergencyIcon,
  Label as LabelIcon,
  TransferWithinAStationSharp as TransferWithinAStationSharpIcon,
  Inventory2Sharp as Inventory2SharpIcon,
} from '@mui/icons-material';

const SETTINGS_CONTACT = 'contact'
const SETTINGS_LABEL = 'label';
const SETTINGS_TRANSPORTER = 'transporter';
const SETTINGS_PACKAGING = 'packaging';

export default function SettingsMenu({ activeMenu }) {
  return (
    <MenuList>
      <Link to='/settings/contact'>
        <MenuItem className={`${ activeMenu === SETTINGS_CONTACT ? 'active-menu' : '' }`}>
          <ListItemIcon>
            <ContactEmergencyIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Contact</ListItemText>
        </MenuItem>
      </Link>
      <Link to='/settings/label'>
        <MenuItem className={`${ activeMenu === SETTINGS_LABEL ? 'active-menu' : '' }`}>
          <ListItemIcon>
            <LabelIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Label</ListItemText>
        </MenuItem>
      </Link>
      <Link to='/settings/transporter'>
        <MenuItem className={`${ activeMenu === SETTINGS_TRANSPORTER ? 'active-menu' : '' }`}>
          <ListItemIcon>
            <TransferWithinAStationSharpIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Transporter</ListItemText>
        </MenuItem>
      </Link>
    </MenuList>
  );
}