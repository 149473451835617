import React from "react";
import { useWatch } from "react-hook-form";

import {
  Tooltip,
  Typography,
} from "@mui/material";

function findProductForId(productId, products) {
  return products.find((x) => x["@id"] === productId);
}

function getProductPackaging(value, products, language) {
  if (!value["product"]) {
    return '';
  }

  let product = value.product;
  if (typeof value.product === "string") {
    product = findProductForId(value.product, products);
  }

  if (!product) {
    return '';
  }


  return product.packaging && product.packaging[`name${language}`] ? product.packaging[`name${language}`] : '';
}

export function ProductPackaging({ control, products, index, name, language = 'En' }) {
  const results = useWatch({ control, name: `${name}` });
  let row = results[index];

  if (!row) {
    return <p></p>;
  }

  let packaging = getProductPackaging(row, products, language);

  return (
    <Tooltip title={  packaging ?? "No Packaging"}>
      <Typography className='text-ellipsis'>{  packaging ?? ""}</Typography>
    </Tooltip>
  );
}

export default ProductPackaging;
