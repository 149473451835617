import * as React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Controller } from "react-hook-form";
import { RenderAvatarChip, RenderLabelChip } from '../Helpers.tsx';

export const SelectMultipleField: any = ({
  name,
  control,
  label,
  options,
  required = false,
}) => {
  if (!options) {
    return <></>;
  }

  const generateOptions = () => {
    return options.map((option: any) => {
      return (
        <MenuItem key={`key-${option.value}`} value={option.value}>
          { option.imagesrc 
            ? RenderAvatarChip(option.label, option.imagesrc) 
            : (option.color ? RenderLabelChip(option.label, option.color) : option.label) }
        </MenuItem>
      );
    });
  };

  return (
    <FormControl size={"small"} sx={{ width: '100%' }}>
      <InputLabel>{`${label}${ required ? '*' : ''}`}</InputLabel>
      <Controller
        render={({ field: { onChange, value } }) => (
          <Select 
            onChange={onChange} 
            value={ value ? value.map(v => v['@id'] ? v['@id'] : v) : [] } 
            required={required} 
            fullWidth
            multiple
          >
            <MenuItem key='All' value={ null } disabled>
              Select
            </MenuItem>
            { options.length > 0 ? generateOptions() : '' }
          </Select>
        )}
        control={control}
        name={name}
        defaultValue={undefined}
      />
    </FormControl>
  );
};