import React from "react";
import { useFieldArray } from "react-hook-form";
import { NumberField } from "../FormFields/RenderNumberField.tsx";
import { InputTextField } from "../FormFields/RenderInputTextField.tsx";
import i18next from '../../i18n.js';

import { IconButton, Grid } from "@mui/material";
import { Close as CloseIcon, Add as AddIcon } from "@mui/icons-material";

const Containers = ({
  name,
  control,
  register,
}: any) => {
  const { fields, append, remove, watch } = useFieldArray({
    name: name,
    control,
    rules: {
      required: "Please append at least 1 item",
    },
  });

  return (
    <>
      <Grid sx={{ maxHeight: '10vh', overflow: 'auto', paddingTop: '10px' }}>
      {fields.map((field, index) => {
        return (
          <section key={field.id}>
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={3} sx={{ marginBottom: "5px" }}>
                <InputTextField
                  name="uid"
                  label="UID"
                  control={control}
                  {...register(`${name}.${index}.uid`, {
                    required: true,
                  })}
                />
              </Grid>

              <Grid item xs={3} sx={{ marginBottom: "5px" }}>
                <InputTextField
                  name="type"
                  label={i18next.t("containerType")}
                  control={control}
                  {...register(`${name}.${index}.type`, {})}
                />
              </Grid>
              
              <Grid item xs={3} sx={{ marginBottom: "5px" }}>
                <NumberField
                    name="weight"
                    label={i18next.t("weight")}
                    control={control}
                    {...register(`${name}.${index}.weight`, {
                      valueAsNumber: true,
                    })}
                />
              </Grid>

              <Grid item xs={1}>
                <IconButton
                  type="button"
                  color="error"
                  size="small"
                  onClick={() => remove(index)}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </section>
        );
      })}
      </Grid>
      <IconButton
        type="button"
        color="info"
        size="small"
        onClick={() => {
          append({
            product: "",
            quantity: 0,
          });
        }}
      >
        <AddIcon />
      </IconButton>
    </>
  );
};

export default Containers;
