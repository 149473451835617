/*****************/
/* INITIAL STATE */
/*****************/
const initialState = {
  transport: {
    labels: [],
    sites: [],
  },
  tenant: undefined,
  active: undefined,
  transportType: undefined,
  transporter: undefined
};

/*********/
/* TYPES */
/*********/
interface FilterProperties {
  transport: {
    labels?: Array<string>;
    sites?: Array<string>;
  };
  tenant?: string;
  active?: string;
  transportType?: string;
}

enum FilterActionTypes {
  CLEAR_FILTER = "CLEAR_FILTER",
  ADD_TRANSPORT_LABEL_FILTER = "ADD_TRANSPORT_LABEL_FILTER",
  REMOVE_TRANSPORT_LABEL_FILTER = "REMOVE_TRANSPORT_LABEL_FILTER",
  ADD_TRANSPORT_SITE_FILTER = "ADD_TRANSPORT_SITE_FILTER",
  REMOVE_TRANSPORT_SITE_FILTER = "REMOVE_TRANSPORT_SITE_FILTER",
  ADD_TENANT_FILTER = "ADD_TENANT_FILTER",
  REMOVE_TENANT_FILTER = "REMOVE_TENANT_FILTER",
  ADD_ACTIVE_FILTER = "ADD_ACTIVE_FILTER",
  REMOVE_ACTIVE_FILTER = "REMOVE_ACTIVE_FILTER",
  ADD_TRANSPORT_TYPE_FILTER = "ADD_TRANSPORT_TYPE_FILTER",
  REMOVE_TRANSPORT_TYPE_FILTER = "REMOVE_TRANSPORT_TYPE_FILTER",
  ADD_TRANSPORTER_FILTER = "ADD_TRANSPORTER_FILTER",
  REMOVE_TRANSPORTER_FILTER = "REMOVE_TRANSPORTER_FILTER",
}

export interface FilterAction {
  type: FilterActionTypes;
  payload?: FilterProperties;
}

/*******************/
/* ACTION CREATORS */
/*******************/
export function clearFilter(): FilterAction {
  return {
    type: FilterActionTypes.CLEAR_FILTER,
  };
}

export function addTransportLabelFilterValue(payload): FilterAction {
  return {
    type: FilterActionTypes.ADD_TRANSPORT_LABEL_FILTER,
    payload,
  };
}

export function removeTransportLabelFilterValue(payload): FilterAction {
  return {
    type: FilterActionTypes.REMOVE_TRANSPORT_LABEL_FILTER,
    payload,
  };
}

export function addTransportSiteFilterValue(payload): FilterAction {
  return {
    type: FilterActionTypes.ADD_TRANSPORT_SITE_FILTER,
    payload,
  };
}

export function removeTransportSiteFilterValue(
  payload
): FilterAction {
  return {
    type: FilterActionTypes.REMOVE_TRANSPORT_SITE_FILTER,
    payload,
  };
}

export function addTenantFilterValue(payload): FilterAction {
  return {
    type: FilterActionTypes.ADD_TENANT_FILTER,
    payload,
  };
}

export function removeTenantFilterValue(
  payload
): FilterAction {
  return {
    type: FilterActionTypes.REMOVE_TENANT_FILTER,
    payload,
  };
}

export function addActiveFilterValue(payload): FilterAction {
  return {
    type: FilterActionTypes.ADD_ACTIVE_FILTER,
    payload,
  };
}

export function removeActiveFilterValue(
  payload
): FilterAction {
  return {
    type: FilterActionTypes.REMOVE_ACTIVE_FILTER,
    payload,
  };
}

export function addTransportTypeFilterValue(payload): FilterAction {
  return {
    type: FilterActionTypes.ADD_TRANSPORT_TYPE_FILTER,
    payload,
  };
}

export function removeTransportTypeFilterValue(
  payload
): FilterAction {
  return {
    type: FilterActionTypes.REMOVE_TRANSPORT_TYPE_FILTER,
    payload,
  };
}

export function addTransporterFilterValue(payload): FilterAction {
  return {
    type: FilterActionTypes.ADD_TRANSPORTER_FILTER,
    payload,
  };
}

export function removeTransporterFilterValue(
  payload
): FilterAction {
  return {
    type: FilterActionTypes.REMOVE_TRANSPORTER_FILTER,
    payload,
  };
}

/***********/
/* REDUCER */
/***********/
function filterReducer(state = initialState, action: FilterAction) {
  switch (action.type) {
    case FilterActionTypes.CLEAR_FILTER:
      return initialState;
    case FilterActionTypes.ADD_TRANSPORT_LABEL_FILTER:
      return {
        ...state,
        transport: {
          labels: [...state.transport.labels, action.payload],
          sites: [...state.transport.sites],
        },
      };
    case FilterActionTypes.REMOVE_TRANSPORT_LABEL_FILTER:
      return {
        ...state,
        transport: {
          labels: state.transport.labels.filter(
            (element) => element !== action.payload
          ),
          sites: [...state.transport.sites],
        },
      };
    case FilterActionTypes.ADD_TRANSPORT_SITE_FILTER:
      return {
        ...state,
        transport: {
          labels: [...state.transport.labels],
          sites: [...state.transport.sites, action.payload],
        },
      };
    case FilterActionTypes.REMOVE_TRANSPORT_SITE_FILTER:
      return {
        ...state,
        transport: {
          labels: [...state.transport.labels],
          sites: state.transport.sites.filter(
            (element) => element !== action.payload
          ),
        },
      };
    case FilterActionTypes.ADD_TENANT_FILTER:
      return {
        ...state,
        tenant: action.payload,
      };
    case FilterActionTypes.REMOVE_TENANT_FILTER:
      return {
        ...state,
        tenant: state.tenant !== action.payload ? action.payload : undefined,
      };
    case FilterActionTypes.ADD_ACTIVE_FILTER:
      return {
        ...state,
        active: action.payload,
      };
    case FilterActionTypes.REMOVE_ACTIVE_FILTER:
      return {
        ...state,
        active: state.active !== undefined ? action.payload : undefined,
      };
    case FilterActionTypes.ADD_TRANSPORT_TYPE_FILTER:
      return {
        ...state,
        transportType: action.payload,
      };
    case FilterActionTypes.REMOVE_TRANSPORT_TYPE_FILTER:
      return {
        ...state,
        transportType: state.transportType !== undefined ? action.payload : undefined,
      };
    case FilterActionTypes.ADD_TRANSPORTER_FILTER:
      return {
        ...state,
        transporter: action.payload,
      };
    case FilterActionTypes.REMOVE_TRANSPORTER_FILTER:
      return {
        ...state,
        transporter: state.transporter !== undefined ? action.payload : undefined,
      };
    default:
      return state;
  }
}

export default filterReducer;
