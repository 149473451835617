import React from "react";
import "../styles/modal.css";

import {
  Box,
  Fade,
  Modal as MuiModal,
  Grid,
  Paper,
  Button,
} from "@mui/material";

import { Close as CloseIcon } from "@mui/icons-material";
import { connect, ConnectedProps } from "react-redux";

import { hideChildModal, hideModal } from "../data/modal.tsx";
import ProductForm from "./Forms/Product.tsx";
import LabelForm from "./Forms/Label.tsx";
import ContactForm from "./Forms/Contact.tsx";
import TransportForm from "./Forms/Transport.tsx";
import TransporterForm from "./Forms/Transporter.tsx";
import TransportChecklistForm from "./Forms/TransportDocumentChecklist.tsx";
import TenantForm from "./Forms/Tenant.tsx";
import ADRTransport from "./reports/ADRTransport.tsx";
import PackagingForm from "./Forms/Packaging.tsx";
import FileGroupForm from "./Forms/FileGroup.tsx";
import TrainingForm from "./Forms/Training.tsx";
import InspectionForm from "./Forms/Inspection.tsx";
import SiteForm from "./Forms/Site.tsx";
import UserForm from "./Forms/User.tsx";
import FileView from "./partial/FileView.tsx";
import DriverForm from "./Forms/Driver.tsx";
import UserPreferredLandingForm from "./Forms/UserPreferredLanding.tsx";
import RenderTrainingUserList from "./partial/TrainingUsers.tsx";
import DescriptionView from "./partial/Description.tsx";

const mapStateToProps = (state) => ({
  modal: state.modal.modal,
  childModal: state.modal.childModal,
});

const mapDispatchToProps = {
  dispatchHideModal: hideModal,
  dispatchHideChildModal: hideChildModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ModalProps = {} & ConnectedProps<typeof connector>;

function Modal(props: ModalProps) {
  const { dispatchHideModal, dispatchHideChildModal, isSuperAdmin, modal, childModal } =
    props;

  if (!modal) {
    return null;
  }

  const onCloseButtonClick = () => {
    dispatchHideModal();
  };

  const onChildCloseButtonClick = () => {
    dispatchHideChildModal();
  };

  const modalProps = {
    ...modal.formProps,
    isSuperAdmin: isSuperAdmin,
  }

  const childModalProps = {
    ...childModal?.formProps,
    isSuperAdmin: isSuperAdmin,
  }
  
  return (
    <MuiModal
      className="mui-modal-container"
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={true}
    >
      <Fade in={true}>
        <Box>
          <Grid container component={Paper} className="modal-wrapper">
            <Grid item className="modal-header">
              <Grid item>{modal.title ?? ""}</Grid>
              <Grid item>
                <Button onClick={onCloseButtonClick}>
                  <CloseIcon fontSize="inherit" /> 
                </Button>
              </Grid>
            </Grid>
            <Grid item className="modal-content">
              { modal.form && modal.form === "contact" && <ContactForm {...modalProps} /> }
              { modal.form && modal.form === "product" && <ProductForm {...modalProps} /> }
              { modal.form && modal.form === "label" && <LabelForm {...modalProps} /> }
              { modal.form && modal.form === "transport" && <TransportForm {...modalProps} /> }
              { modal.form && modal.form === "transporter" && <TransporterForm {...modalProps} /> }
              { modal.form && modal.form === "transportchecklist" && <TransportChecklistForm {...modalProps} /> }
              { modal.form && modal.form === "tenant" && <TenantForm {...modalProps } /> }
              { modal.form && modal.form === "adrtransport" && <ADRTransport {...modalProps } /> }
              { modal.form && modal.form === "packaging" && <PackagingForm {...modalProps } /> }
              { modal.form && modal.form === "filegroup" && <FileGroupForm {...modalProps } /> }
              { modal.form && modal.form === "site" && <SiteForm {...modalProps } /> }
              { modal.form && modal.form === "user" && <UserForm {...modalProps } /> }
              { modal.form && modal.form === "fileView" && <FileView {...modalProps } /> }
              { modal.form && modal.form === "training" && <TrainingForm {...modalProps } /> }
              { modal.form && modal.form === "inspection" && <InspectionForm {...modalProps } /> }
              { modal.form && modal.form === "preferredLanding" && <UserPreferredLandingForm {...modalProps } /> }
              { modal.form && modal.form === "trainingUserList" && <RenderTrainingUserList {...modalProps } /> }
              { modal.form && modal.form === "description" && <DescriptionView {...modalProps } /> }
            </Grid>
          </Grid>
          {childModal && (
            <>
              <MuiModal
                className="mui-modal-container"
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={true}
              >
                <Fade in={true}>
                  <Box>
                    <Grid container component={Paper} className="modal-wrapper">
                      <Grid item className="modal-header">
                        <Grid item>{childModal.title ?? ""}</Grid>
                        <Grid item>
                          <Button onClick={onChildCloseButtonClick}>
                            <CloseIcon fontSize="inherit" />
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid item className="modal-content">
                      { childModal.form && childModal.form === "contact" && <ContactForm {...childModalProps} /> }
                      { childModal.form && childModal.form === "product" && <ProductForm {...childModalProps} /> }
                      { childModal.form && childModal.form === "label" && <LabelForm {...childModalProps} /> }
                      { childModal.form && childModal.form === "transport" && <TransportForm {...childModalProps} /> }
                      { childModal.form && childModal.form === "transporter" && <TransporterForm {...childModalProps} /> }
                      { childModal.form && childModal.form === "transportchecklist" && <TransportChecklistForm {...childModalProps} /> }
                      { childModal.form && childModal.form === "tenant" && <TenantForm {...childModalProps } /> }
                      { childModal.form && childModal.form === "adrtransport" && <ADRTransport {...childModalProps } /> }
                      { childModal.form && childModal.form === "packaging" && <PackagingForm {...childModalProps } /> }
                      { childModal.form && childModal.form === "filegroup" && <FileGroupForm {...childModalProps } /> }
                      { childModal.form && childModal.form === "site" && <SiteForm {...childModalProps } /> }
                      { childModal.form && childModal.form === "user" && <UserForm {...childModalProps } /> }
                      { childModal.form && childModal.form === "fileView" && <FileView {...childModalProps } /> }
                      { childModal.form && childModal.form === "driver" && <DriverForm {...childModalProps } /> }
                      </Grid>
                    </Grid>
                  </Box>
                </Fade>
              </MuiModal>
            </>
          )}
        </Box>
      </Fade>
    </MuiModal>
  );
}

export default connector(Modal);
