import axios from "axios";
import { hideChildModal, hideModal } from "../data/modal.tsx";

/*****************/
/* INITIAL STATE */
/*****************/
const initialState = {
  loading: false,
  error: "",
  data: [],
  progress: {
    page: 0,
    pages: 0,
    total: 0,
    loaded: 0,
  },
};

/*********/
/* TYPES */
/*********/
const FETCH_INSPECTIONS = "FETCH_INSPECTIONS";
const FETCH_INSPECTIONS_SUCCESS = "FETCH_INSPECTIONS_SUCCESS";
const FETCH_INSPECTIONS_ERROR = "FETCH_INSPECTIONS_ERROR";
const PUT_INSPECTION = "PUT_INSPECTION";
const POST_INSPECTION = "POST_INSPECTION";
const DELETE_INSPECTION = "DELETE_INSPECTION";
const FETCH_NEXT_INSPECTIONS = "FETCH_NEXT_INSPECTIONS";
const FETCH_NEXT_INSPECTIONS_SUCCESS = "FETCH_NEXT_INSPECTIONS_SUCCESS";

/*******************/
/* ACTION CREATORS */
/*******************/
export const getInspections = () => async (dispatch) => {
  dispatch(loadInspections());

  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/inspections?page=1`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.data["hydra:member"].length === 0) {
    dispatch(setError("No inspections found"));
  } else {
    dispatch(
      setInspections(
        response.data["hydra:member"],
        parseInt(response.data["hydra:totalItems"], 10)
      )
    );
    if (
      parseInt(response.data["hydra:totalItems"], 10) >
      response.data["hydra:member"].length
    ) {
      dispatch(getNextInspections());
    }
  }
};

export const getNextInspections = () => async (dispatch, getState) => {
  const {
    inspections: {
      progress: { page, pages },
    },
  } = getState();
  const pageToLoad = page + 1;
  dispatch(loadNextInspections(pageToLoad));

  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/inspections?page=${pageToLoad}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  dispatch(setNextInspections(response.data["hydra:member"]));
  if (pageToLoad < pages) {
    dispatch(getNextInspections());
  }
};

export const updateInspection =
  (inspection) => async (dispatch, getState) => {
    dispatch(putInspection());
    const {
      inspections: {
        data,
        progress: { total },
      },
      modal: {childModal},
    } = getState();

    const response = await axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}/inspections/${inspection[
        "@id"
      ].replace("/api/inspections/", "")}`,
      headers: {
        "Content-Type": "application/ld+json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: inspection,
    });

    if (response.status === 200) {
      const resultData = data.map((inspection) => {
        if (inspection["@id"] === response.data["@id"]) {
          return response.data;
        }

        return inspection;
      });

      dispatch(setInspections(resultData, total));

      if (childModal) {
        dispatch(hideChildModal());
      } else {
        dispatch(hideModal());
      }
    } else {
      setError("Something went wrong updating");
    }
  };

export const createInspection = (inspection, callback, args) => async (dispatch, getState) => {
    dispatch(postInspection());
    const {
      inspections: {
        data,
        progress: { total },
      },
      modal: {childModal},
    } = getState();

    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/inspections`,
      headers: {
        "Content-Type": "application/ld+json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: inspection,
    });

    if (response.status === 201) {
      const resultData = [...data, ...[response.data]];

      dispatch(setInspections(resultData, total));
      
      if (childModal) {
        dispatch(hideChildModal());
        callback(args, response.data["@id"]);
      } else {
        dispatch(hideModal());
      }
    } else {
      setError("Something went wrong deleting");
    }
  };

export const removeInspection = (inspection) => async (dispatch, getState) => {
  dispatch(putInspection());
  const {
    inspections: {
      data,
      progress: { total },
    },
  } = getState();

const response = await axios({
    method: "delete",
    url: `${process.env.REACT_APP_API_URL}/inspections/${inspection[
      "@id"
    ].replace("/api/inspections/", "")}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    }
  });

  if (response.status === 204) {
    const resultData = data.filter((x) => x["@id"] !== inspection["@id"]);

    dispatch(setInspections(resultData, total));
    dispatch(hideModal());
  } else {
    setError("Something went wrong deleting");
  }
};

export const loadInspections = () => ({ type: FETCH_INSPECTIONS });

export const putInspection = () => ({ type: PUT_INSPECTION });

export const postInspection = () => ({ type: PUT_INSPECTION });

export const deleteInspection = () => ({ type: DELETE_INSPECTION });

export const setInspections = (inspections, total) => ({
  type: FETCH_INSPECTIONS_SUCCESS,
  payload: { inspections, total },
});

export const loadNextInspections = (page) => ({
  type: FETCH_NEXT_INSPECTIONS,
  payload: page,
});

export const setNextInspections = (inspections) => ({
  type: FETCH_NEXT_INSPECTIONS_SUCCESS,
  payload: inspections,
});

export const setError = (msg) => ({
  type: FETCH_INSPECTIONS_ERROR,
  payload: msg,
});

/***********/
/* REDUCER */
/***********/
const inspectionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_INSPECTIONS:
      return {
        ...state,
        loading: true,
        error: "",
        progress: {
          page: 1,
          pages: 0,
          loaded: 0,
          total: 0,
        },
      };
    case FETCH_INSPECTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        data: payload.inspections,
        progress: {
          ...state.progress,
          loaded: payload.inspections.length,
          pages: Math.ceil(payload.total / payload.inspections.length),
          total: payload.total,
        },
      };

    case PUT_INSPECTION:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case POST_INSPECTION:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case DELETE_INSPECTION:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_INSPECTIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case FETCH_NEXT_INSPECTIONS:
      return {
        ...state,
        error: "",
        progress: {
          ...state.progress,
          page: payload,
        },
      };
    case FETCH_NEXT_INSPECTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, ...payload],
        progress: {
          ...state.progress,
          loaded: state.progress.loaded + payload.length,
        },
      };
    default:
      return state;
  }
};

export default inspectionReducer;
