// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.mui-modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

div.mui-modal-container div.modal-wrapper {
    display: flex;
    flex-direction: column;
}

div.modal-wrapper div.modal-header {
    min-height: 50px;
    margin: 0 15px;
    border-bottom: 1px solid #DDDDDD;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.7);
}

div.modal-header a {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.87);
}

div.modal-wrapper div.modal-content {
    padding: 15px;
}

div.modal-wrapper div.modal-footer {
    min-height: 50px;
    padding: 10px 15px;
    border-top: 1px solid #DDDDDD;
    display: flex;
    justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/styles/modal.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,gCAAgC;IAChC,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,gBAAgB;IAChB,yBAAyB;AAC7B;;AAEA;IACI,qBAAqB;IACrB,0BAA0B;AAC9B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,6BAA6B;IAC7B,aAAa;IACb,8BAA8B;AAClC","sourcesContent":["div.mui-modal-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\ndiv.mui-modal-container div.modal-wrapper {\n    display: flex;\n    flex-direction: column;\n}\n\ndiv.modal-wrapper div.modal-header {\n    min-height: 50px;\n    margin: 0 15px;\n    border-bottom: 1px solid #DDDDDD;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    font-weight: 600;\n    color: rgba(0, 0, 0, 0.7);\n}\n\ndiv.modal-header a {\n    text-decoration: none;\n    color: rgba(0, 0, 0, 0.87);\n}\n\ndiv.modal-wrapper div.modal-content {\n    padding: 15px;\n}\n\ndiv.modal-wrapper div.modal-footer {\n    min-height: 50px;\n    padding: 10px 15px;\n    border-top: 1px solid #DDDDDD;\n    display: flex;\n    justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
