import React, {useEffect, } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCurrentUser } from "../data/user";

import {
  Box,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";

const RedirectionPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedData = useSelector((state) => ({
    user: state.user.data,
  }));

  useEffect(() => {
    dispatch(getCurrentUser());
  }, [dispatch]);

  const {
    user,
  } = selectedData;

  useEffect(() => {
    if (user && user['@id']) {
      navigate(user.preferredLanding ?? '/welcome')
    }

    if (!localStorage.getItem("token")) {
      window.location.href = '/'
    }
  }, [user]);

  return (
    <Grid container justifyContent='center' alignItems='center' height='60vh'>
      <Box sx={{ display: 'flex', alignItems:'center', gap: '10px' }}>
          <CircularProgress />
          <Typography variant='h6' className='form-fields-title'>
            Redirecting...
          </Typography>
      </Box>
    </Grid>
  )
};

export default RedirectionPage;
