import React, { useCallback, useState } from 'react';
import { Document, Page, pdfjs} from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useDispatch, useSelector } from "react-redux";
import {
  hideModal,
  hideChildModal,
} from "../../data/modal.tsx";

import {
    Box,
    Button,
    Divider,
    Grid,
    Typography,
} from "@mui/material";

import {
  Close as CloseIcon,
} from '@mui/icons-material';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

const FileView = ({file, showCloseButton = false}) => {
    const dispatch = useDispatch();

    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(file.contentUrl)[1];
    const options = {
        cMapUrl: '/cmaps/',
        standardFontDataUrl: '/standard_fonts/',
    };

    const [numPages, setNumPages] = useState<number>();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [searchText, setSearchText] = useState('');

    const selectedData = useSelector((state) => ({
      modal: state.modal
    }));

    const highlightPattern = (text, pattern) => {
        return text.replace(pattern, (value) => `<mark>${value}</mark>`);
    }

    const onChange = (event) => {
    setSearchText(event.target.value);
    }

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
        setNumPages(numPages);
        setPageNumber(pageNumber);
    }
    
    const textRenderer = useCallback(
        (textItem) => highlightPattern(textItem.str, searchText),
        [searchText]
    );
    
    const changePage = (offset) => {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }
    
    const previousPage = () => {
        changePage(-1);
    }
    
    const nextPage = () => {
        changePage(1);
    }
    
    const handleCloseForm = () => {
      if (selectedData.modal.childModal) {
        dispatch(hideChildModal());
      } else {
        dispatch(hideModal());
      }
    };

    return (
      <>
        <Grid container justifyContent='center'>
            <Grid item xs={12}  sx={{ maxWidth: `50vh !important`, overflow: 'auto' }}>
            { extension === 'pdf' ? (
                 <>
                 <Box sx={{ display: "flex", justifyContent: 'space-between', alignItems:'center' }}>
                   <Grid item>
                    <label htmlFor="search">Search:</label>
                    <input type="search" id="search" value={searchText} onChange={onChange} />
                   </Grid>
                   <Grid item>
                    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                      { numPages > 1 ? (
                        <>
                          <Button
                            type="button"
                            disabled={pageNumber <= 1}
                            onClick={previousPage}
                          >
                            Previous
                          </Button>
                          <Typography fontSize={14}>
                            Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                          </Typography>
                          <Button
                            type="button"
                            disabled={pageNumber >= numPages}
                            onClick={nextPage}
                          >
                            Next
                          </Button>
                        </>
                      ) : ''}
                    </Box>
                   </Grid>
                 </Box>
                 <hr/>
                 <Box sx={{ maxHeight: '900px', width: '100%', overflow: 'scroll' }}>
                   <Document file={file.contentUrl}  onLoadSuccess={onDocumentLoadSuccess} options={options} style={{ color: 'white', textAlign: 'center', margin: 30, overflow: "scroll", height: 700 }}>
                     <Page size="A4" pageNumber={pageNumber} customTextRenderer={textRenderer}/>
                   </Document>
                 </Box>
               </>
            ) : '' }
            { ['png', 'jpeg', 'jpg'].includes(extension) ? (
                <img src={`${ file.contentUrl }`} width='100%' alt="fileImage"/>
            ) : '' }
            </Grid>
        </Grid>
        { showCloseButton ? (
          <>
          <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
            <Divider orientation="horizontal" />
          </Grid>
          <Grid item xs={12} className="button-wrapper">
              <Button
                onClick={() => {handleCloseForm()}}
                variant="contained"
              >
                <CloseIcon fontSize="inherit" />
                &nbsp;Close
              </Button>
          </Grid>
          </>
        ) : ''}
      </>
    )
}

export default FileView
