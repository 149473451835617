import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { Controller } from "react-hook-form";
import { createFile } from "../../data/files.js";
import FileUploadProgressBar from "../partial/FileUploadProgress.tsx";
import i18next from '../../i18n.js';
import { showChildModal } from "../../data/modal.tsx";

import {
  Box,
  Grid,
  FormControl,
  List as MuiList,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

import {
  FileOpenSharp as FileOpenSharpIcon,
  UploadFileSharp as UploadFileSharpIcon,
  InsertDriveFileSharp as InsertDriveFileSharpIcon,
} from "@mui/icons-material";

export const MultipleFileField: any = ({
  name,
  control,
  register,
  setValue,
  existingFiles,
  fileState,
  setSelectedFile,
  showExistingFiles = false,
}) => {
  const dispatch = useDispatch();
  const [existingFilesData, setExistingFilesData] = useState(existingFiles);
  const [uploadedFiles, setUploadedFiles] = useState(0);
  
  const dropzone = useDropzone({
    onDrop: (files: File[]) => files && fileDropHandler(files),
  });

  const uploadHandler = async (file) => {
    return dispatch(createFile(file));
  };

  const fileDropHandler = async (files) => {
    const uploadPromises = files.map((file) => uploadHandler(file));

    setUploadedFiles(uploadPromises.length)

    Promise.all(uploadPromises).then((values: Array<any>) =>
      setValue(name, [...values, ...existingFilesData])
    );
  };

  const fileRemoveHandler = (element) => {
    element.preventDefault();
    const existingFileWrapper = element.target.closest(
      ".js-existing-file-element"
    );
    const fileId = existingFileWrapper.getAttribute("data-existing-file-id");
    const filteredFiles = existingFilesData.filter(
      (file) => file["@id"] !== fileId
    );
    setValue(name, filteredFiles);
    setExistingFilesData(filteredFiles);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={showExistingFiles ? 6 : 12} className="form-fields-container">
          <FormControl size={"small"} sx={{ width: "100%" }}>
            <Controller
              control={control}
              render={() => (
                <Grid
                  item
                  {...dropzone.getRootProps({ className: "dropzone" })}
                  sx={{
                    minHeight: "20vh",
                    height: "100%",
                    border: "3px dotted #DDDDDD",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center", 
                    flexDirection: 'column',
                    padding: 2,
                  }}
                >
                  <Box
                    component="input"
                    {...dropzone.getInputProps({
                      ...register(name),
                    })}
                  />
                  <Box sx={{ width: '100%' }}>
                    <Typography>{i18next.t("dropClickToUpload")}</Typography>
                  </Box>
                  <FileUploadProgressBar 
                    loading={ fileState && fileState.loading } 
                    error={ fileState && fileState.error } 
                    loaded={uploadedFiles}
                    total={ dropzone.acceptedFiles.length }
                  />
                </Grid>
              )}
              name={name}
              defaultValue={[]}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={showExistingFiles ? 6 : 12}>
          <MuiList>
            {dropzone.acceptedFiles && dropzone.acceptedFiles.length > 0 ? (
              <ListItem disabled>
                <ListItemIcon>
                  <UploadFileSharpIcon />
                </ListItemIcon>
                <ListItemText primary="Dropped files" />
              </ListItem>
            ) : (
              ""
            )}
            {dropzone.acceptedFiles &&
              dropzone.acceptedFiles.map((file, index) => (
                <ListItem disablePadding key={`uploaded-file-${index}`}>
                  <ListItemButton>
                    <ListItemIcon>
                      <FileOpenSharpIcon />
                    </ListItemIcon>
                    <ListItemText primary={`${file.name}`} />
                  </ListItemButton>
                </ListItem>
              ))}
          </MuiList>
          { showExistingFiles && existingFiles && existingFiles.length > 0 &&
            existingFiles.map((file:any, index:any) => (
              <ListItem 
                disablePadding 
                className={`js-existing-file-element`}
                data-existing-file-id={file['@id']}
                key={`file-view-existing-file-${index}`}
                >
                  <ListItemButton 
                       onClick={() => {
                        dispatch(
                          showChildModal({
                            title: i18next.t("fileView"),
                            description: file.description ?? '',
                            form: "fileView",
                            formProps: { 
                              file: file,
                              showCloseButton: true,
                            },
                          })
                        );
                      }}
                  >
                      <ListItemIcon>
                      <InsertDriveFileSharpIcon />
                      </ListItemIcon>
                      <ListItemText primary={`${ file.description }`} />
                  </ListItemButton>
                </ListItem>
              ))
            }
        </Grid>
      </Grid>
    </>
  );
};
