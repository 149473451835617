import React from "react";
import { useWatch } from "react-hook-form";

const categories = [
  {
    value: "0",
    label: "Category 0",
    multiplier: 10,
  },
  {
    value: "1",
    label: "Category 1",
    multiplier: 20,
  },
  {
    value: "2",
    label: "Category 2",
    multiplier: 3,
  },
  {
    value: "3",
    label: "Category 3",
    multiplier: 1,
  },
  {
    value: "4",
    label: "Category 4",
    multiplier: 0,
  },
];

export function findProductForId(productId, products) {
  return products.find((x) => x["@id"] === productId);
}

function calculateSubTotalForRow(value, products) {
  if (!value["product"]) {
    return 0;
  }

  let product = value.product;
  if (typeof value.product === "string") {
    product = findProductForId(value.product, products);
  }

  if (product === undefined || !product.category) {
    return 0;
  }

  let category = categories.find(({ value }) => value === product.category);

  if (category === undefined) {
    return 0;
  }

  return value["quantity"] * category.multiplier;
}

export function SubTotalCalculation({ control, products, index, name }) {
  const results = useWatch({ control, name: `${name}` });
  let row = results[index];

  if (!row) {
    return <p></p>;
  }

  let subtotalADRamount = calculateSubTotalForRow(row, products);

  return <p>{subtotalADRamount ? subtotalADRamount : ""}</p>;
}

export const calculateTotalQuantity = (transportProduct, index, setValue, products) => {
  if (!transportProduct["product"]) {
    return 0;
  }

  let product = transportProduct.product;
  if (typeof transportProduct.product === "string") {
    product = findProductForId(transportProduct.product, products);
  }

  let packagingMultiplier = product.packaging
    ? product.packaging.multiplier
    : 0;
  let packagingQuantity = transportProduct.packagingQuantity
    ? transportProduct.packagingQuantity
    : 0;

  let totalQuantity = packagingMultiplier * packagingQuantity;

  setValue(`${product.transportType}TransportProducts.${index}.quantity`, totalQuantity);
};

export default calculateSubTotalForRow;
