import axios from "axios";
import { hideChildModal, hideModal } from "../data/modal.tsx";
import { useJWTExpirationCheck } from "./authentication.js";

/*****************/
/* INITIAL STATE */
/*****************/
const initialState = {
  loading: false,
  error: "",
  data: [],
  progress: {
    page: 0,
    pages: 0,
    total: 0,
    loaded: 0,
  },
};

/*********/
/* TYPES */
/*********/
const FETCH_LABELS = "FETCH_LABELS";
const FETCH_LABELS_SUCCESS = "FETCH_LABELS_SUCCESS";
const FETCH_LABELS_ERROR = "FETCH_LABELS_ERROR";
const FETCH_NEXT_LABELS = "FETCH_NEXT_LABELS";
const FETCH_NEXT_LABELS_SUCCESS = "FETCH_NEXT_LABELS_SUCCESS";
const PUT_LABEL = "PUT_LABEL";
const POST_LABEL = "POST_LABEL";

/*******************/
/* ACTION CREATORS */
/*******************/
export const getLabels = () => async (dispatch, getState) => {
  dispatch(loadLabels());
  const {
    labels: { progress, data },
  } = getState();

  if (progress.loaded === 0 || data.length !== progress.loaded) {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/labels?page=1`,
      {
        headers: {
          "Content-Type": "application/ld+json; charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    const json = await response.json();
    useJWTExpirationCheck(json);

    if (json["hydra:member"].length === 0) {
      dispatch(setError("No labels found"));
    } else {
      dispatch(
        setLabels(json["hydra:member"], parseInt(json["hydra:totalItems"], 10))
      );
      if (
        parseInt(json["hydra:totalItems"], 10) > json["hydra:member"].length
      ) {
        dispatch(getNextLabels());
      }
    }
  }
};

export const getNextLabels = () => async (dispatch, getState) => {
  const {
    labels: {
      progress: { page, pages },
    },
  } = getState();
  const pageToLoad = page + 1;
  dispatch(loadNextLabels(pageToLoad));

  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/labels?page=${pageToLoad}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  dispatch(setNextLabels(response.data["hydra:member"]));
  if (pageToLoad < pages) {
    dispatch(getNextLabels());
  }
};

export const updateLabel = (label) => async (dispatch, getState) => {
  dispatch(putLabel());
  const {
    labels: {
      data,
      progress: { total },
    },
  } = getState();

  const response = await axios({
    method: "put",
    url: `${process.env.REACT_APP_API_URL}/labels/${label["@id"].replace(
      "/api/labels/",
      ""
    )}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: label,
  });

  if (response.status === 200) {
    const resultData = data.map((label) => {
      if (label["@id"] === response.data["@id"]) {
        return response.data;
      }

      return label;
    });

    dispatch(setLabels(resultData, total));
    dispatch(hideModal());
  } else {
    setError("Something went wrong updating");
  }
};

export const createLabel =
  (label, callback, args) => async (dispatch, getState) => {
    dispatch(postLabel());
    const {
      labels: {
        data,
        progress: { total },
      },
      modal: { childModal },
    } = getState();

    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/labels`,
      headers: {
        "Content-Type": "application/ld+json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: label,
    });

    if (response.status === 201) {
      const resultData = [...data, ...[response.data]];

      dispatch(setLabels(resultData, total));

      if (childModal) {
        dispatch(hideChildModal());
        callback(args, response.data["@id"]);
      } else {
        dispatch(hideModal());
      }
    } else {
      setError("Something went wrong deleting");
    }
  };

export const loadLabels = () => ({ type: FETCH_LABELS });

export const putLabel = () => ({ type: PUT_LABEL });

export const postLabel = () => ({ type: POST_LABEL });

export const setLabels = (labels, total) => ({
  type: FETCH_LABELS_SUCCESS,
  payload: { labels, total },
});

export const loadNextLabels = (page) => ({
  type: FETCH_NEXT_LABELS,
  payload: page,
});
export const setNextLabels = (labels) => ({
  type: FETCH_NEXT_LABELS_SUCCESS,
  payload: labels,
});

export const setError = (msg) => ({
  type: FETCH_LABELS_ERROR,
  payload: msg,
});

/***********/
/* REDUCER */
/***********/
const labelReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_LABELS:
      return {
        ...state,
        loading: true,
        error: "",
        progress: {
          page: 1,
          pages: 0,
          loaded: 0,
          total: 0,
        },
      };
    case FETCH_LABELS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        data: payload.labels,
        progress: {
          ...state.progress,
          loaded: payload.labels.length,
          pages: Math.ceil(payload.total / payload.labels.length),
          total: payload.total,
        },
      };

    case FETCH_LABELS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case FETCH_NEXT_LABELS:
      return {
        ...state,
        error: "",
        progress: {
          ...state.progress,
          page: payload,
        },
      };
    case FETCH_NEXT_LABELS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, ...payload],
        progress: {
          ...state.progress,
          loaded: state.progress.loaded + payload.length,
        },
      };
    case PUT_LABEL:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case POST_LABEL:
      return {
        ...state,
        loading: true,
        error: "",
      };
    default:
      return state;
  }
};

export default labelReducer;
