import axios from "axios";
import { hideChildModal, hideModal } from "../data/modal.tsx";

/*****************/
/* INITIAL STATE */
/*****************/
const initialState = {
  loading: false,
  error: "",
  data: [],
  progress: {
    page: 0,
    pages: 0,
    total: 0,
    loaded: 0,
  },
};

/*********/
/* TYPES */
/*********/
const FETCH_SITES = "FETCH_SITES";
const FETCH_SITES_SUCCESS = "FETCH_SITES_SUCCESS";
const FETCH_SITES_ERROR = "FETCH_SITES_ERROR";
const PUT_SITE = "PUT_SITE";
const POST_SITE = "POST_SITE";
const DELETE_SITE = "DELETE_SITE";
const FETCH_NEXT_SITES = "FETCH_NEXT_SITES";
const FETCH_NEXT_SITES_SUCCESS = "FETCH_NEXT_SITES_SUCCESS";

/*******************/
/* ACTION CREATORS */
/*******************/
export const getSites = () => async (dispatch) => {
  dispatch(loadSites());

  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/sites?page=1`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.data["hydra:member"].length === 0) {
    dispatch(setError("No sites found"));
  } else {
    dispatch(
      setSites(
        response.data["hydra:member"],
        parseInt(response.data["hydra:totalItems"], 10)
      )
    );
    if (
      parseInt(response.data["hydra:totalItems"], 10) >
      response.data["hydra:member"].length
    ) {
      dispatch(getNextSites());
    }
  }
};

export const getNextSites = () => async (dispatch, getState) => {
  const {
    sites: {
      progress: { page, pages },
    },
  } = getState();
  const pageToLoad = page + 1;
  dispatch(loadNextSites(pageToLoad));

  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/sites?page=${pageToLoad}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  dispatch(setNextSites(response.data["hydra:member"]));
  if (pageToLoad < pages) {
    dispatch(getNextSites());
  }
};

export const updateSite =
  (site) => async (dispatch, getState) => {
    dispatch(putSite());
    const {
      sites: {
        data,
        progress: { total },
      },
      modal: {childModal},
    } = getState();

    const response = await axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}/sites/${site[
        "@id"
      ].replace("/api/sites/", "")}`,
      headers: {
        "Content-Type": "application/ld+json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: site,
    });

    if (response.status === 200) {
      const resultData = data.map((site) => {
        if (site["@id"] === response.data["@id"]) {
          return response.data;
        }

        return site;
      });

      dispatch(setSites(resultData, total));

      if (childModal) {
        dispatch(hideChildModal());
      } else {
        dispatch(hideModal());
      }
    } else {
      setError("Something went wrong updating");
    }
  };

export const createSite = (site, callback, args) => async (dispatch, getState) => {
    dispatch(postSite());
    const {
      sites: {
        data,
        progress: { total },
      },
      modal: {childModal},
    } = getState();

    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/sites`,
      headers: {
        "Content-Type": "application/ld+json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: site,
    });

    if (response.status === 201) {
      const resultData = [...data, ...[response.data]];

      dispatch(setSites(resultData, total));
      
      if (childModal) {
        dispatch(hideChildModal());
        callback(args, response.data["@id"]);
      } else {
        dispatch(hideModal());
      }
    } else {
      setError("Something went wrong deleting");
    }
  };

export const removeSite = (site) => async (dispatch, getState) => {
  dispatch(putSite());
  const {
    sites: {
      data,
      progress: { total },
    },
  } = getState();

const response = await axios({
    method: "delete",
    url: `${process.env.REACT_APP_API_URL}/sites/${site[
      "@id"
    ].replace("/api/sites/", "")}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    }
  });

  if (response.status === 204) {
    const resultData = data.filter((x) => x["@id"] !== site["@id"]);

    dispatch(setSites(resultData, total));
    dispatch(hideModal());
  } else {
    setError("Something went wrong deleting");
  }
};

export const loadSites = () => ({ type: FETCH_SITES });

export const putSite = () => ({ type: PUT_SITE });

export const postSite = () => ({ type: PUT_SITE });

export const deleteSite = () => ({ type: DELETE_SITE });

export const setSites = (sites, total) => ({
  type: FETCH_SITES_SUCCESS,
  payload: { sites, total },
});

export const loadNextSites = (page) => ({
  type: FETCH_NEXT_SITES,
  payload: page,
});

export const setNextSites = (sites) => ({
  type: FETCH_NEXT_SITES_SUCCESS,
  payload: sites,
});

export const setError = (msg) => ({
  type: FETCH_SITES_ERROR,
  payload: msg,
});

/***********/
/* REDUCER */
/***********/
const siteReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_SITES:
      return {
        ...state,
        loading: true,
        error: "",
        progress: {
          page: 1,
          pages: 0,
          loaded: 0,
          total: 0,
        },
      };
    case FETCH_SITES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        data: payload.sites,
        progress: {
          ...state.progress,
          loaded: payload.sites.length,
          pages: Math.ceil(payload.total / payload.sites.length),
          total: payload.total,
        },
      };

    case PUT_SITE:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case POST_SITE:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case DELETE_SITE:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_SITES_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case FETCH_NEXT_SITES:
      return {
        ...state,
        error: "",
        progress: {
          ...state.progress,
          page: payload,
        },
      };
    case FETCH_NEXT_SITES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, ...payload],
        progress: {
          ...state.progress,
          loaded: state.progress.loaded + payload.length,
        },
      };
    default:
      return state;
  }
};

export default siteReducer;
