import axios from "axios";
import { hideModal } from "./modal.tsx";

/*****************/
/* INITIAL STATE */
/*****************/
const initialState = {
  loading: false,
  error: "",
  data: [],
  progress: {
    page: 0,
    pages: 0,
    total: 0,
    loaded: 0,
  },
};

/*********/
/* TYPES */
/*********/
const FETCH_TRAININGS = "FETCH_TRAININGS";
const FETCH_TRAININGS_SUCCESS = "FETCH_TRAININGS_SUCCESS";
const FETCH_TRAININGS_ERROR = "FETCH_TRAININGS_ERROR";
const PUT_TRAINING = "PUT_TRAINING";
const POST_TRAINING = "POST_TRAINING";
const DELETE_TRAINING = "DELETE_TRAINING";
const FETCH_NEXT_TRAININGS = "FETCH_NEXT_TRAININGS";
const FETCH_NEXT_TRAININGS_SUCCESS = "FETCH_NEXT_TRAININGS_SUCCESS";

/*******************/
/* ACTION CREATORS */
/*******************/
export const getTrainings = () => async (dispatch) => {
  dispatch(loadTrainings());

  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/trainings?page=1`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.data["hydra:member"].length === 0) {
    dispatch(setError("No trainings found"));
  } else {
    dispatch(
      setTrainings(
        response.data["hydra:member"],
        parseInt(response.data["hydra:totalItems"], 10)
      )
    );
    if (
      parseInt(response.data["hydra:totalItems"], 10) >
      response.data["hydra:member"].length
    ) {
      dispatch(getNextTrainings());
    }
  }
};

export const getNextTrainings = () => async (dispatch, getState) => {
  const {
    trainings: {
      progress: { page, pages },
    },
  } = getState();
  const pageToLoad = page + 1;
  dispatch(loadNextTrainings(pageToLoad));

  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/trainings?page=${pageToLoad}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  dispatch(setNextTrainings(response.data["hydra:member"]));
  if (pageToLoad < pages) {
    dispatch(getNextTrainings());
  }
};

export const updateTraining =
  (training) => async (dispatch, getState) => {
    dispatch(putTraining());
    const {
      trainings: {
        data,
        progress: { total },
      },
    } = getState();

    const response = await axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}/trainings/${training[
        "@id"
      ].replace("/api/trainings/", "")}`,
      headers: {
        "Content-Type": "application/ld+json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: training,
    });

    if (response.status === 200) {
      const resultData = data.map((training) => {
        if (training["@id"] === response.data["@id"]) {
          return response.data;
        }

        return training;
      });

      dispatch(setTrainings(resultData, total));
      dispatch(hideModal());
    } else {
      setError("Something went wrong updating");
    }
  };

export const createTraining = (training) => async (dispatch, getState) => {
    dispatch(postTraining());
    const {
      trainings: {
        data,
        progress: { total },
      },
    } = getState();

    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/trainings`,
      headers: {
        "Content-Type": "application/ld+json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: training,
    });

    if (response.status === 201) {
      const resultData = [...data, ...[response.data]];
  
      dispatch(setTrainings(resultData, total));
      dispatch(hideModal());
    } else {
      setError("Something went wrong deleting");
    }
  };

export const removeTraining= (training) => async (dispatch, getState) => {
  dispatch(putTraining());
  const {
    trainings: {
      data,
      progress: { total },
    },
  } = getState();

  const response = await axios({
    method: "delete",
    url: `${process.env.REACT_APP_API_URL}${training["@id"].replace("/api", "")}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    }
  });

  if (response.status === 204) {
    const resultData = data.filter((x) => x["@id"] !== training["@id"]);

    dispatch(setTrainings(resultData, total));
    dispatch(hideModal());
  } else {
    setError("Something went wrong deleting");
  }
};

export const loadTrainings = () => ({ type: FETCH_TRAININGS });

export const putTraining = () => ({ type: PUT_TRAINING });

export const postTraining = () => ({ type: PUT_TRAINING });

export const deleteTraining = () => ({ type: DELETE_TRAINING });

export const setTrainings = (trainings, total) => ({
  type: FETCH_TRAININGS_SUCCESS,
  payload: { trainings, total },
});

export const loadNextTrainings = (page) => ({
  type: FETCH_NEXT_TRAININGS,
  payload: page,
});
export const setNextTrainings = (trainings) => ({
  type: FETCH_NEXT_TRAININGS_SUCCESS,
  payload: trainings,
});

export const setError = (msg) => ({
  type: FETCH_TRAININGS_ERROR,
  payload: msg,
});

/***********/
/* REDUCER */
/***********/
const trainingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_TRAININGS:
      return {
        ...state,
        loading: true,
        error: "",
        progress: {
          page: 1,
          pages: 0,
          loaded: 0,
          total: 0,
        },
      };
    case FETCH_TRAININGS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        data: payload.trainings,
        progress: {
          ...state.progress,
          loaded: payload.trainings.length,
          pages: Math.ceil(payload.total / payload.trainings.length),
          total: payload.total,
        },
      };

    case PUT_TRAINING:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case POST_TRAINING:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case DELETE_TRAINING:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_TRAININGS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case FETCH_NEXT_TRAININGS:
      return {
        ...state,
        error: "",
        progress: {
          ...state.progress,
          page: payload,
        },
      };
    case FETCH_NEXT_TRAININGS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, ...payload],
        progress: {
          ...state.progress,
          loaded: state.progress.loaded + payload.length,
        },
      };
    default:
      return state;
  }
};
export default trainingReducer;
