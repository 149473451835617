import React from "react";

import { 
  Avatar,
  ListItem, 
  List as MUIList, 
  Stack,
  ListItemAvatar,
  ListItemText,
  Tooltip,
} from "@mui/material";

import {
  AirplanemodeActive as AirplanemodeActiveIcon,
  Security as SecurityIcon,
  WarningAmber as WarningAmberIcon,
  DirectionsBoat as DirectionsBoatIcon,
  Train as TrainIcon,
  LocalShipping as LocalShippingIcon
} from "@mui/icons-material";

export default function RenderTransportModality () {


  const InlandShippingIconSvg = (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="-95 -115 500 500" 
      preserveAspectRatio="none"
      >
        <g transform="translate(0.000000,235.000000) scale(0.100000,-0.100000)"
        fill="#FFFFFF" stroke="none">
        <path d="M930 1935 l0 -215 270 0 270 0 0 -185 0 -185 -290 0 -290 0 0 -201
        c0 -186 -1 -200 -17 -197 -16 3 -18 22 -21 201 l-2 197 -300 0 -300 0 -2 -197
        -3 -198 -92 -5 -93 -5 292 -433 292 -432 866 1 865 0 30 47 c17 26 111 166
        210 312 272 403 335 498 335 504 0 3 -34 6 -75 6 l-75 0 0 200 0 200 -305 0
        -305 0 0 -201 c0 -186 -1 -200 -17 -197 -16 3 -18 22 -21 201 l-2 197 -285 0
        -285 0 0 185 0 185 250 0 250 0 0 215 0 215 -575 0 -575 0 0 -215z"/>
        </g>
    </svg>
);

  return (
    <>
      <MUIList key={"label-list"} component={Stack} direction="row" gap='5px' sx={{ padding: 0 }}>
        <Tooltip title='Select this modality if the transport is only performed by truck using either colli, tank or container'>
          <ListItem
            sx={{ 
              display: "flex", 
              width: 'auto', 
              padding: '8px',
              minWidth: '180px',
              borderRadius: '5px',
            }}
            className='active-menu'
            key='modality-adr'
          >
            <ListItemAvatar>
              <Avatar>
                <LocalShippingIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="ADR"/>
          </ListItem>
        </Tooltip>
        <Tooltip title='This option isn’t available with your current subscription'>
          <ListItem
            sx={{ 
              display: "flex", 
              width: 'auto', 
              padding: '8px',
              minWidth: '180px',
              backgroundColor: '#DDDDDD',
              color: '#666666',
              borderRadius: '5px',
            }}
            key='modality-iata'
            disabled
          >
            <ListItemAvatar>
              <Avatar>
                <AirplanemodeActiveIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="IATA"/>
          </ListItem>
        </Tooltip>
        <Tooltip title='This option isn’t available with your current subscription'>
          <ListItem
            sx={{ 
              display: "flex", 
              width: 'auto', 
              padding: '8px',
              minWidth: '180px',
              backgroundColor: '#DDDDDD',
              color: '#666666',
              borderRadius: '5px',
            }}
            key='modality-adn'
            disabled
          >
            <ListItemAvatar>
              <Avatar>
                {InlandShippingIconSvg}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="ADN"/>
          </ListItem>
        </Tooltip>
        <Tooltip title='This option isn’t available with your current subscription'>
          <ListItem
            sx={{ 
              display: "flex", 
              width: 'auto', 
              padding: '8px',
              minWidth: '180px',
              backgroundColor: '#DDDDDD',
              color: '#666666',
              borderRadius: '5px',
            }}
            key='modality-imdg'
            disabled
          >
            <ListItemAvatar>
              <Avatar>
                <DirectionsBoatIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="IMDG"/>
          </ListItem>
        </Tooltip>
        <Tooltip title='This option isn’t available with your current subscription'>
          <ListItem
            sx={{ 
              display: "flex", 
              width: 'auto', 
              padding: '8px',
              minWidth: '180px',
              backgroundColor: '#DDDDDD',
              color: '#666666',
              borderRadius: '5px',
            }}
            key='modality-rid'
            disabled
          >
            <ListItemAvatar>
              <Avatar>
                <TrainIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="RID"/>
          </ListItem>
        </Tooltip>
      </MUIList>
    </>
  );
}