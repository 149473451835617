import React from "react";
import  { Link } from 'react-router-dom'

import { 
  MenuList,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';

import {
  List as ListIcon,
  NorthEast as NorthEastIcon,
  SouthWest as SouthWestIcon,
  StackedBarChart as StackedBarChartIcon
} from '@mui/icons-material';

const REPORT_TRANSPORT_INCOMING = 'transport_incoming'
const REPORT_TRANSPORT_OUTGOING = 'transport_outgoing'
const REPORT_ADN_INDEX_INCOMING = 'adn_index_incoming'
const REPORT_ADN_INDEX_OUTGOING = 'adn_index_outgoing'

export default function ReportsMenu({ activeMenu }) {
  return (
    <MenuList>
      <Link to='/reports/transport/incoming'>
        <MenuItem className={`${ activeMenu === REPORT_TRANSPORT_INCOMING ? 'active-menu' : '' }`}>
          <ListItemIcon>
            <SouthWestIcon fontSize="small" />
            <StackedBarChartIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Volume Incoming / Year</ListItemText>
        </MenuItem>
      </Link>
      <Link to='/reports/transport/outgoing'>
        <MenuItem className={`${ activeMenu === REPORT_TRANSPORT_OUTGOING ? 'active-menu' : '' }`}>
          <ListItemIcon>
            <NorthEastIcon fontSize="small" />
            <StackedBarChartIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Volume Outgoing / Year</ListItemText>
        </MenuItem>
      </Link>
      <Link to='/reports/adn-index/incoming'>
        <MenuItem className={`${ activeMenu === REPORT_ADN_INDEX_INCOMING ? 'active-menu' : '' }`}>
          <ListItemIcon>
            <SouthWestIcon fontSize="small" />
            <ListIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>ADR Index Incoming</ListItemText>
        </MenuItem>
      </Link>
      <Link to='/reports/adn-index/outgoing'>
        <MenuItem className={`${ activeMenu === REPORT_ADN_INDEX_OUTGOING ? 'active-menu' : '' }`}>
          <ListItemIcon>
            <NorthEastIcon fontSize="small" />
            <ListIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>ADR Index Outgoing</ListItemText>
        </MenuItem>
      </Link>
    </MenuList>
  );
}