import axios from "axios";
import { hideChildModal, hideModal } from "../data/modal.tsx";
import { addTransporterFilterValue } from "./filter.tsx";

/*****************/
/* INITIAL STATE */
/*****************/
const initialState = {
  loading: false,
  error: "",
  data: [],
  progress: {
    page: 0,
    pages: 0,
    total: 0,
    loaded: 0,
  },
};

/*********/
/* TYPES */
/*********/
const FETCH_TRANSPORTERS = "FETCH_TRANSPORTERS";
const FETCH_TRANSPORTERS_SUCCESS = "FETCH_TRANSPORTERS_SUCCESS";
const FETCH_TRANSPORTERS_ERROR = "FETCH_TRANSPORTERS_ERROR";
const PUT_TRANSPORTER = "PUT_TRANSPORTER";
const POST_TRANSPORTER = "POST_TRANSPORTER";
const DELETE_TRANSPORTER = "DELETE_TRANSPORTER";
const FETCH_NEXT_TRANSPORTERS = "FETCH_NEXT_TRANSPORTERS";
const FETCH_NEXT_TRANSPORTERS_SUCCESS = "FETCH_NEXT_TRANSPORTERS_SUCCESS";

/*******************/
/* ACTION CREATORS */
/*******************/
export const getTransporters = () => async (dispatch) => {
  dispatch(loadTransporters());

  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/transporters?page=1`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.data["hydra:member"].length === 0) {
    dispatch(setError("No transporters found"));
  } else {
    dispatch(
      setTransporters(
        response.data["hydra:member"],
        parseInt(response.data["hydra:totalItems"], 10)
      )
    );
    if (
      parseInt(response.data["hydra:totalItems"], 10) >
      response.data["hydra:member"].length
    ) {
      dispatch(getNextTransporters());
    }
  }
};

export const getNextTransporters = () => async (dispatch, getState) => {
  const {
    transporters: {
      progress: { page, pages },
    },
  } = getState();
  const pageToLoad = page + 1;
  dispatch(loadNextTransporters(pageToLoad));

  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/transporters?page=${pageToLoad}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  dispatch(setNextTransporters(response.data["hydra:member"]));
  if (pageToLoad < pages) {
    dispatch(getNextTransporters());
  }
};

export const updateTransporter =
  (transporter) => async (dispatch, getState) => {
    dispatch(putTransporter());
    const {
      transporters: {
        data,
        progress: { total },
      },
      modal: { childModal },
    } = getState();

    const response = await axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}/transporters/${transporter[
        "@id"
      ].replace("/api/transporters/", "")}`,
      headers: {
        "Content-Type": "application/ld+json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: transporter,
    });

    if (response.status === 200) {
      const resultData = data.map((transporter) => {
        if (transporter["@id"] === response.data["@id"]) {
          return response.data;
        }

        return transporter;
      });

      dispatch(setTransporters(resultData, total));

      if (childModal) {
        dispatch(hideChildModal());
      } else {
        dispatch(hideModal());
      }
    } else {
      setError("Something went wrong updating");
    }
  };

export const createTransporter =
  (transporter, callback) => async (dispatch, getState) => {
    dispatch(postTransporter());
    const {
      transporters: {
        data,
        progress: { total },
      },
      modal: { childModal },
    } = getState();

    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/transporters`,
      headers: {
        "Content-Type": "application/ld+json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: transporter,
    });

    if (response.status === 201) {
      const resultData = [...data, ...[response.data]];

      dispatch(setTransporters(resultData, total));

      if (callback) {
        callback("transporter", response.data["@id"]);
      }

      if (childModal) {
        dispatch(addTransporterFilterValue(response.data));
        dispatch(hideChildModal());
      } else {
        dispatch(hideModal());
      }
    } else {
      setError("Something went wrong deleting");
    }
  };

export const removeTransporter =
  (transporter) => async (dispatch, getState) => {
    dispatch(putTransporter());
    const {
      transporters: {
        data,
        progress: { total },
      },
    } = getState();

    const response = await axios({
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}${transporter["@id"].replace(
        "/api",
        ""
      )}`,
      headers: {
        "Content-Type": "application/ld+json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (response.status === 204) {
      const resultData = data.filter((x) => x["@id"] !== transporter["@id"]);

      dispatch(setTransporters(resultData, total));
      dispatch(hideModal());
    } else {
      setError("Something went wrong deleting");
    }
  };

export const loadTransporters = () => ({ type: FETCH_TRANSPORTERS });

export const putTransporter = () => ({ type: PUT_TRANSPORTER });

export const postTransporter = () => ({ type: PUT_TRANSPORTER });

export const deleteTransporter = () => ({ type: DELETE_TRANSPORTER });

export const setTransporters = (transporters, total) => ({
  type: FETCH_TRANSPORTERS_SUCCESS,
  payload: { transporters, total },
});

export const loadNextTransporters = (page) => ({
  type: FETCH_NEXT_TRANSPORTERS,
  payload: page,
});
export const setNextTransporters = (transporters) => ({
  type: FETCH_NEXT_TRANSPORTERS_SUCCESS,
  payload: transporters,
});

export const setError = (msg) => ({
  type: FETCH_TRANSPORTERS_ERROR,
  payload: msg,
});

/***********/
/* REDUCER */
/***********/
const transporterReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_TRANSPORTERS:
      return {
        ...state,
        loading: true,
        error: "",
        progress: {
          page: 1,
          pages: 0,
          loaded: 0,
          total: 0,
        },
      };
    case FETCH_TRANSPORTERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        data: payload.transporters,
        progress: {
          ...state.progress,
          loaded: payload.transporters.length,
          pages: Math.ceil(payload.total / payload.transporters.length),
          total: payload.total,
        },
      };

    case PUT_TRANSPORTER:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case POST_TRANSPORTER:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case DELETE_TRANSPORTER:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_TRANSPORTERS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case FETCH_NEXT_TRANSPORTERS:
      return {
        ...state,
        error: "",
        progress: {
          ...state.progress,
          page: payload,
        },
      };
    case FETCH_NEXT_TRANSPORTERS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, ...payload],
        progress: {
          ...state.progress,
          loaded: state.progress.loaded + payload.length,
        },
      };
    default:
      return state;
  }
};
export default transporterReducer;
