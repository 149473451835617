import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { showModal } from "../../data/modal.tsx";
import RenderClassificationList from "../partial/ProductClassificationList.tsx";
import ProductView from "./ProductView.tsx";
import i18next from '../../i18n.js';
import { capitalizeFirstLetter, fileIconHandler, hasFile } from "../Helpers.tsx";


import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridToolbar,
} from "@mui/x-data-grid";  

import {
    Grid,
    IconButton,
    Paper,
} from "@mui/material";

import {
    Edit as EditIcon,
    InsertDriveFileSharp as InsertDriveFileSharpIcon,
} from "@mui/icons-material";

export default function RenderProductList ({products, rows, classifications, packagings, editable, isLoading}) {
  const dispatch = useDispatch();

  const selectedData = useSelector((state) => ({
    userLanguage: state.currentUser.language,
  }));

  const renderActions = (params: GridRenderCellParams<any, number>) => {
    let editproduct = products.find(
      (product) => product["@id"] === params.value
    );

    return (
      <>
      <IconButton
        onClick={() => {
          dispatch(
            showModal({
              title: i18next.t("editProduct"),
              description: "Edit product",
              form: "product",
              formProps: { product: editproduct, classifications: classifications, packagings: packagings },
            })
          );
        }}
      >
        <EditIcon size="small" />
      </IconButton>
      </>
    );
  };

  const renderClassifications = (params: GridRenderCellParams<any>) => {    
    return <RenderClassificationList classifications={ params.value } />
  }

  const renderFile = (params: GridRenderCellParams<any, number>) => {
    if (!params.value) {
      return <></>
    }

    const sdsFiles = params.value.fileGroup;

    return (
      <IconButton
        onClick={() => {
          dispatch(
            showModal({
              title: i18next.t("sdsFiles"),
              description: "SDS documents",
              form: "filegroup",
              formProps: { 
                object: params.value,
              },
            })
          );
        }}
      >
        <InsertDriveFileSharpIcon 
          size="small" 
          color={ fileIconHandler(sdsFiles) }
          opacity={ hasFile(sdsFiles) ? 1 : 0.4 }
        />
      </IconButton>
    );
  }

  const renderName = (params: GridRenderCellParams<any, number>) => {
    return params.value[`name${ capitalizeFirstLetter(selectedData.userLanguage) }`] ?? '';
  }

  const formatProductDescription = (product) => {
    return product[`fullDescription${ capitalizeFirstLetter(selectedData.userLanguage) }`] ?? '';
  }

  const renderDescription = (params: GridRenderCellParams<any, number>) => {
    return formatProductDescription(params.value);
  }

  let columns: GridColDef[] = [
    {
      field: "active",
      headerName: i18next.t("active"),
      minWidth: 100,
      editable: editable,
      type: "boolean",
    },
    {
      field: "transporType",
      headerName: i18next.t("type"),
      minWidth: 100,
    },
    { 
      field: "name", 
      headerName: i18next.t("name"), 
      minWidth: 300, 
      renderCell: renderName 
    },
    { 
      field: "description", 
      headerName: i18next.t("description"), 
      minWidth: 250, 
      renderCell: renderDescription 
    },
    {
      field: "unNr",
      headerName: i18next.t("unNr"),
      minWidth: 100,
    },
    {
      field: "classifications",
      headerName: i18next.t("classification"),
      align: 'center',
      headerAlign: 'center',
      minWidth: 250,
      flex: 1,
      renderCell: renderClassifications,
    },
    {
      field: "category",
      headerName: i18next.t("category"),
      minWidth: 100,
      align: "center",
    },
    {
      field: "sdsFiles",
      headerName: i18next.t("sdsFiles"),
      renderCell: renderFile,
      minWidth: 80,
      align: "center",
    },
    {
      field: "id",
      headerName: "",
      minWidth: 50,
      renderCell: renderActions,
      flex: 1,
      align: "right",
    },
  ];

  return (
        <Grid item xs={12} sm={12} component={Paper}>
            <Grid item sx={{ height: "80vh" }}>
                <DataGrid
                    hideFooter={true}
                    columns={columns}
                    sx={{ marginBottom: "25px" }}
                    rows={rows}
                    getRowHeight={() => 'auto'}
                    disableColumnSelector
                    disableDensitySelector
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        },
                    }}
                    columnVisibilityModel={{
                        active: editable,
                        id: editable,
                    }}
                    loading={isLoading}
                />
            </Grid>
        </Grid>
    );
}