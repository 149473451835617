import React from "react";
import { Grid, Tooltip } from "@mui/material";

export default function RenderClassification ({products}) {
  if (products === undefined || !products) {
    return <></>;
  }

  let productClassifications = [];

  products.map((product: any) => {
    productClassifications = [...productClassifications, ...product.classifications];
  })
  
  const classifications = productClassifications.reduce((objectArray, classification) => {
      if (!objectArray.some(object => object['@id'] === classification['@id'])) {
        objectArray.push(classification);
      }
      return objectArray;
  }, []);

  return (
    <Grid item sx={{ display: 'flex', gap: '5px', }} >
    { classifications.map((classification) => (
      <Grid item key={`classification-${classification['@id']}`} sx={{ alignItems: 'center' }}>
        <Tooltip title={ `${ classification.type }: ${ classification.class } - ${classification.usage}`}>
          <img
              src={`/img/classification/${ classification.pictogram }.png`}
              alt={ `${ classification.type }: ${ classification.class }`}
              loading="lazy"
              height={65}
              width={65}
            />
        </Tooltip>
      </Grid>
    )) }
  </Grid>
  );
}