import React, { useEffect }  from 'react';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';

import {
    Cancel as CancelIcon,
    CheckCircle as CheckCircleIcon,
} from '@mui/icons-material';

import { 
    Box, 
    Fade,
    Typography,
} from '@mui/material';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <>
            <Fade in={props.value !== 100 ? true : false}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ width: '100%', mr: 1 }}>
                        <LinearProgress variant="determinate" {...props} color={ props.value && props.value === 100 ? `success` : 'inherit' } />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                    <Typography
                        variant="body2"
                        sx={{ color: 'text.secondary' }}
                    >{`${Math.round(props.value)}%`}</Typography>
                    </Box>
                </Box>
            </Fade>
        
        <Fade in={ props.value && props.value === 100 } timeout={1000}>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <CheckCircleIcon color='success'/>&nbsp;File(s) uploaded.  
            </Box>
        </Fade>
        </>
    );
  }

export default function FileUploadProgressBar({ loading, error, loaded = 0, total = 0, singleFile = false }) {
  const [progress, setProgress] = React.useState(0);

  useEffect(() => {
    let loadedFiles = singleFile ? 1 : loaded;

    setProgress(total > 0 ? Math.round((loadedFiles * 100) / total) : 0);
  }, [loaded]);

  if (!loading && total === 0) {
    return <></>;
  }

  if (error && error.length > 0) {
    return (
        <Fade in={ true } timeout={1000}>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <CancelIcon color='error'/>&nbsp;{`You encountered an error when uploading file(s).`}
            </Box>
        </Fade>
    )
  }

  return (
    <Box sx={{ width: '100%' }}>
        <LinearProgressWithLabel 
            value={progress} 
        />
    </Box>
  );
}