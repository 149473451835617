import React from "react";
import {  ListItem, List as MUIList, Stack } from "@mui/material";
import { RenderAvatarChip } from "../Helpers.tsx";

export default function RenderClassificationList ({classifications}) {
  if (classifications === undefined || !classifications) {
    return <></>;
  }
  
  return (
    <>
      <MUIList key={"classification-list"} component={Stack} direction="column" sx={{ padding: 0 }}>
        {classifications.map((classification: any) => (
          <ListItem
            sx={{ display: "flex", width: 'auto', padding: '4px' }}
            key={ classifications["@id"] }
          >
            { RenderAvatarChip(
              `${ classification.type }: ${ classification.class }`,
              `/img/classification/${ classification.pictogram }.png`
            ) }
          </ListItem>
        ))}
      </MUIList>
    </>
  );
}