import React, { useState } from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Logo from './Logo.tsx';

import {
  TextField,
  Button,
  Box,
  createTheme,
  ThemeProvider,
  CssBaseline,
  Container,
} from "@mui/material";

const Login = () => {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const submitHandler = (e:any) => {
    e.preventDefault();
    if (email === "" || password === "") {
      return null;
    }
    loginUser(email, password);
  };

  const loginUser = (email:string, password:string) => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/login`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        email: email,
        password: password,
      },
    })
      .then((res:any) => {
        const webToken = res.data.token;
        localStorage.setItem("token", webToken);
        navigate('/redirection')
      })
      .catch(() => console.log("rejected"));
  };

  return (
    <ThemeProvider theme={createTheme()}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: "25vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Logo fillColor='#708293' size='65px' fontSize="2.7rem" fontColor='#436F9C' />
          <Box
            component="form"
            onSubmit={submitHandler}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="emailInput"
              onChange={(e:any) => {
                setEmail(e.target.value);
              }}
              autoComplete="email"
              autoFocus
            />

            <TextField
              margin="normal"
              required
              fullWidth
              label="Password"
              id="password"
              autoComplete="current-password"
              onChange={(e:any) => {
                setPassword(e.target.value);
              }}
              name="password"
              type="password"
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};
export default Login;