import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import ProductSDS from './ProductSDS.tsx';
import RenderClassificationByProduct from './ClassificationByProduct.tsx';
import { DEFAULT_LANGUAGES, getProductDiscriptionByLanguages } from '../Helpers.tsx';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Typography,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

export default function ProductView({ product, documentLanguages = DEFAULT_LANGUAGES, fontSize}:any) {
  const dispatch = useDispatch();
  
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!product) {
    return <></>;
  }

  return (
    <>
      <Typography onClick={handleClickOpen}>
        { getProductDiscriptionByLanguages(product, documentLanguages, fontSize) }
      </Typography>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="product-dialog-title"
        aria-describedby="product-dialog-description"
      >
        <DialogContent>
          <Grid item xs={12} sm={12} sx={{ display:'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <CloseIcon onClick={handleClose} fontSize="5px" color='primary'/>
          </Grid>
          <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
              <Divider orientation="horizontal" />
          </Grid>
          <Grid container gap={1} justifyContent='flex-start' flexWrap='nowrap'>
              <Grid item sx={{ display:'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100px !important' }}>
                <Typography fontWeight={700} fontSize='20px'>
                    {`UN ${product.unNr ?? ''}`}
                </Typography>
              </Grid>
              <Grid item>
                { getProductDiscriptionByLanguages(product, DEFAULT_LANGUAGES, '12px') }
              </Grid>
          </Grid>
          <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
              <Divider orientation="horizontal" />
          </Grid>
          <RenderClassificationByProduct classifications={product.classifications} fontSize='12px'/>
          <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
              <Divider orientation="horizontal" />
          </Grid>
          <ProductSDS product={ product }/>
          <Grid item xs={12} sm={12} marginTop={1}>
              <Divider orientation="horizontal" />
          </Grid>
        </DialogContent>
        <DialogActions sx={{ display:'flex', justifyContent:'space-between' }}>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
