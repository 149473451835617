import React from "react";
import { useFieldArray } from "react-hook-form";

import { IconButton, Grid } from "@mui/material";
import { Close as CloseIcon, Add as AddIcon } from "@mui/icons-material";
import { InputTextField } from "../FormFields/RenderInputTextField.tsx";
import { DateField } from "../FormFields/RenderDateField.tsx";

const Drivers = ({
  name,
  control,
  register,
}: any) => {
  const { fields, append, remove } = useFieldArray({
    name: name,
    control,
  });

  return (
    <>
      <Grid sx={{ maxHeight: '20vh', overflow: 'auto', paddingTop: '10px' }}>
      {fields.map((field, index) => {
        return (
          <section key={field.id}>
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={3} sx={{ marginBottom: "5px" }}>
                <InputTextField
                  name="firstName"
                  label="Firstname"
                  control={control}
                  {...register(`${name}.${index}.firstName`, {})}
                />
              </Grid>
              <Grid item xs={3} sx={{ marginBottom: "5px" }}>
                <InputTextField
                  name="lastName"
                  label="Lastname"
                  control={control}
                  {...register(`${name}.${index}.lastName`, {})}
                />
              </Grid>

              <Grid item xs={3} className="form-field-datetime">
                <DateField
                    name="certificateExpirationDate"
                    label="Certificate Expiration"
                    control={control}
                    required
                    {...register(`${name}.${index}.certificateExpirationDate`, {})}
                />
              </Grid>

              <Grid item xs={2}>
               
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  type="button"
                  color="error"
                  size="small"
                  onClick={() => remove(index)}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </section>
        );
      })}
      </Grid>
      <IconButton
        type="button"
        color="info"
        size="small"
        onClick={() => {
          append({
            product: "",
            quantity: 0,
          });
        }}
      >
        <AddIcon />
      </IconButton>
    </>
  );
};

export default Drivers;
