import axios from "axios";
import { useJWTExpirationCheck } from "./authentication.js";

/*****************/
/* INITIAL STATE */
/*****************/
const initialState = {
  loading: false,
  error: "",
  data: null,
};

/*********/
/* TYPES */
/*********/
const FETCH_INCOMING_TRANSPORT = "FETCH_INCOMING_TRANSPORT";
const FETCH_INCOMING_TRANSPORT_SUCCESS = "FETCH_INCOMING_TRANSPORT_SUCCESS";
const FETCH_INCOMING_TRANSPORT_ERROR = "FETCH_INCOMING_TRANSPORT_ERROR";

/*******************/
/* ACTION CREATORS */
/*******************/
export const getIncomingTransport = (id) => async (dispatch) => {
  dispatch(loadTransport());

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/incoming_transports/${id}`,
    {
      headers: {
        "Content-Type": "application/ld+json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  const json = await response.json();
  useJWTExpirationCheck(json);

  if (json["hydra:member"].length === 0) {
    dispatch(setError("No transport found"));
  } else {
    dispatch(setTransport(json["hydra:member"]));
  }
};

export const loadTransport = () => ({ type: FETCH_INCOMING_TRANSPORT });

export const setTransport = (transports, query) => ({
  type: FETCH_INCOMING_TRANSPORT_SUCCESS,
  payload: { transports, query },
});

export const setError = (msg) => ({
  type: FETCH_INCOMING_TRANSPORT_ERROR,
  payload: msg,
});

/***********/
/* REDUCER */
/***********/
const incomingTransportReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_INCOMING_TRANSPORT:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_INCOMING_TRANSPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        data: payload.transport,
      };
    case FETCH_INCOMING_TRANSPORT_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
export default incomingTransportReducer;
