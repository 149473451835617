import axios from "axios";
import { hideModal } from "./modal.tsx";
import { useJWTExpirationCheck } from "./authentication.js";

/*****************/
/* INITIAL STATE */
/*****************/
const initialState = {
  loading: false,
  error: "",
  data: [],
  progress: {
    page: 0,
    pages: 0,
    total: 0,
    loaded: 0,
  },
};

/*********/
/* TYPES */
/*********/
const FETCH_USERS = "FETCH_USERS";
const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
const FETCH_USERS_ERROR = "FETCH_USERS_ERROR";
const PUT_USER = "PUT_USER";
const POST_USER = "POST_USER";
const DELETE_USER = "DELETE_USER";
const FETCH_NEXT_USERS = "FETCH_NEXT_USERS";
const FETCH_NEXT_USERS_SUCCESS = "FETCH_NEXT_USERS_SUCCESS";

/*******************/
/* ACTION CREATORS */
/*******************/
export const getUsers = (tenant) => async (dispatch) => {
  dispatch(loadUsers());

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/users?page=1${
      tenant ? `&tenant=${tenant["@id"]}` : ""
    }`,
    {
      headers: {
        "Content-Type": "application/ld+json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  const json = await response.json();
  useJWTExpirationCheck(json);

  if (json["hydra:member"].length === 0) {
    dispatch(setError("No users found"));
  } else {
    dispatch(
      setUsers(json["hydra:member"], parseInt(json["hydra:totalItems"], 10))
    );
    if (parseInt(json["hydra:totalItems"], 10) > json["hydra:member"].length) {
      dispatch(getNextUsers());
    }
  }
};

export const getNextUsers = () => async (dispatch, getState) => {
  const {
    users: {
      progress: { page, pages },
    },
  } = getState();
  const pageToLoad = page + 1;
  dispatch(loadNextUsers(pageToLoad));

  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/users?page=${pageToLoad}
    }`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  dispatch(setNextUsers(response.data["hydra:member"]));
  if (pageToLoad < pages) {
    dispatch(getNextUsers());
  }
};

export const updateUser = (user) => async (dispatch, getState) => {
  dispatch(putUser());
  const {
    users: {
      data,
      progress: { total },
    },
  } = getState();

  const response = await axios({
    method: "put",
    url: `${process.env.REACT_APP_API_URL}/users/${user["@id"].replace(
      "/api/users/",
      ""
    )}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: user,
  });

  if (response.status === 200) {
    const resultData = data.map((user) => {
      if (user["@id"] === response.data["@id"]) {
        return response.data;
      }

      return user;
    });

    dispatch(setUsers(resultData, total));
    dispatch(hideModal());
  } else {
    setError("Something went wrong updating");
  }
};

export const createUser = (user) => async (dispatch, getState) => {
  dispatch(postUser());
  const {
    users: {
      data,
      progress: { total },
    },
  } = getState();

  const response = await axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/users`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: user,
  });

  if (response.status === 201) {
    const resultData = [...data, ...[response.data]];

    dispatch(setUsers(resultData, total));
    dispatch(hideModal());
  } else {
    setError("Something went wrong deleting");
  }
};

export const removeUser = (user) => async (dispatch, getState) => {
  dispatch(putUser());
  const {
    users: {
      data,
      progress: { total },
    },
  } = getState();

  const response = await axios({
    method: "delete",
    url: `${process.env.REACT_APP_API_URL}${user["@id"].replace("/api", "")}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.status === 204) {
    const resultData = data.filter((x) => x["@id"] !== user["@id"]);

    dispatch(setUsers(resultData, total));
    dispatch(hideModal());
  } else {
    setError("Something went wrong deleting");
  }
};

export const loadUsers = () => ({ type: FETCH_USERS });

export const putUser = () => ({ type: PUT_USER });

export const postUser = () => ({ type: PUT_USER });

export const deleteUser = () => ({ type: DELETE_USER });

export const setUsers = (users, total) => ({
  type: FETCH_USERS_SUCCESS,
  payload: { users, total },
});

export const loadNextUsers = (page) => ({
  type: FETCH_NEXT_USERS,
  payload: page,
});
export const setNextUsers = (users) => ({
  type: FETCH_NEXT_USERS_SUCCESS,
  payload: users,
});

export const setError = (msg) => ({
  type: FETCH_USERS_ERROR,
  payload: msg,
});

/***********/
/* REDUCER */
/***********/
const usersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_USERS:
      return {
        ...state,
        loading: true,
        error: "",
        progress: {
          page: 1,
          pages: 0,
          loaded: 0,
          total: 0,
        },
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        data: payload.users,
        progress: {
          ...state.progress,
          loaded: payload.users.length,
          pages: Math.ceil(payload.total / payload.users.length),
          total: payload.total,
        },
      };

    case PUT_USER:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case POST_USER:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case DELETE_USER:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_USERS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case FETCH_NEXT_USERS:
      return {
        ...state,
        error: "",
        progress: {
          ...state.progress,
          page: payload,
        },
      };
    case FETCH_NEXT_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, ...payload],
        progress: {
          ...state.progress,
          loaded: state.progress.loaded + payload.length,
        },
      };
    default:
      return state;
  }
};
export default usersReducer;
