import * as React from 'react';
import { useForm, SubmitHandler } from "react-hook-form"
import { InputTextField } from '../FormFields/RenderInputTextField.tsx';
import { NumberField } from '../FormFields/RenderNumberField.tsx';
import { hideModal, hideChildModal } from "../../data/modal.tsx";
import { useDispatch, useSelector } from "react-redux";
import { createPackaging, updatePackaging } from '../../data/packaging.js';
import i18next from '../../i18n.js';

import {
    Button,
    Grid,
} from "@mui/material";

import {
    Close as CloseIcon,
    Save as SaveIcon,
} from '@mui/icons-material';


const PackagingForm = ({object, callback, args}) => {
    const dispatch = useDispatch();
    
    const selectedData = useSelector((state) => ({
        modal: state.modal,
        filter: state.filter,
    }));

    const { handleSubmit, control, formState } = useForm({
        defaultValues: object,
    });
    
    const { isSubmitting } = formState;

    const onSubmit: SubmitHandler = (data) => {
        let formData = data;

        if (selectedData.filter && selectedData.filter.tenant) {
            formData = {
                ...data,
                tenant: selectedData.filter.tenant['@id'],
            }
        } 

        if (object) {
            dispatch(updatePackaging(formData));
        } else {
            dispatch(createPackaging(formData, callback, args));
        }
    };

    const handleCloseForm = () => {
        if (selectedData.modal.childModal) {
          dispatch(hideChildModal());
        } else {
            dispatch(hideModal());
        }
    };
        
    return (
        <form onSubmit={ handleSubmit(onSubmit) }>
            <Grid container xs={12}>
                <Grid item xs={12} sm={12}>
                    <Grid item xs={12} className='form-fields-container'>
                        <Grid item xs={12} sm={3}>
                            <InputTextField
                                name='nameEn'
                                label={`${i18next.t("name")} EN`}
                                control={ control }
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <InputTextField
                                name='nameNl'
                                label={`${i18next.t("name")} NL`}
                                control={ control }
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <InputTextField
                                name='nameFr'
                                label={`${i18next.t("name")} FR`}
                                control={ control }
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <InputTextField
                                name='nameDe'
                                label={`${i18next.t("name")} DE`}
                                control={ control }
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <NumberField
                                name='multiplier'
                                label={i18next.t("multiplier")}
                                control={ control }
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className='button-wrapper'>
                <Button 
                    onClick={() => {handleCloseForm()}}
                    disabled={ isSubmitting }
                    variant='contained'
                >
                    <CloseIcon fontSize="inherit" />&nbsp;{i18next.t("close")}
                </Button>
                    <Button 
                        type='submit'
                        disabled={ isSubmitting }
                        variant='contained'
                    >
                        <SaveIcon fontSize="inherit" />&nbsp;{i18next.t("save")}
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

export default PackagingForm;