import React from "react";
import { useDispatch } from "react-redux";
import { showModal } from "../../data/modal.tsx";
import { fileIconHandler, findObjectsByIds, formatDateToString, hasFile, isValidCertificate, RenderSites } from "../Helpers.tsx";
import i18next from '../../i18n.js';

import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridToolbar,
} from "@mui/x-data-grid";  

import {
    Grid,
    IconButton,
    Paper,
    ListItem, 
    Chip, 
    List as MUIList, 
    Stack, 
} from "@mui/material";

import {
  Edit as EditIcon,
  InsertDriveFileSharp as InsertDriveFileSharpIcon,
} from "@mui/icons-material";

export default function RenderUserList ({users, rows, sites, editable, isLoading}) {
  const dispatch = useDispatch();

  const renderFileGroup = (params: GridRenderCellParams<any, number>) => {
    let user = users.find(
      (user) => user === params.value
    );

    if (!user) {
      return <></>
    }

    const certificates = user.fileGroup;

    return (
      <IconButton
        onClick={() => {
          dispatch(
            showModal({
              title: i18next.t("userCertificates"),
              description: "User training certificates",
              form: "filegroup",
              formProps: { 
                object: user,
                viewOnly: !editable,
              },
            })
          );
        }}
      >
        <InsertDriveFileSharpIcon 
          size="small" 
          color={ fileIconHandler(certificates) }
          opacity={ hasFile(certificates) ? 1 : 0.4 }
        />
      </IconButton>
    );
  }

  const RenderTrainings = (trainingUsers) => {
    if (trainingUsers === undefined || !trainingUsers) {
      return <></>;
    }
  
    return (
      <>
        <MUIList key={"site-list"} component={Stack} direction="column" sx={{ padding: 0 }}>
          {trainingUsers.map((trainingUser: any) => (
            <ListItem
              sx={{ display: "flex", width: 'auto', padding: '4px' }}
              key={ `training-list-${trainingUser["@id"]}`  }
              onClick={() => {
                dispatch(
                  showModal({
                    title: `${ trainingUser.training.title ?? '' } ${ trainingUser.training.documentDate ? `(${formatDateToString(trainingUser.training.documentDate)})` : '' }`,
                    description: 'User training',
                    form: "filegroup",
                    formProps: { 
                      object: trainingUser,
                      viewOnly: true,
                    },
                  })
                );
              }}
            >
              <Chip 
                size="small"
                color={`${ trainingUser.training && trainingUser.training.status && trainingUser.training.status === 'planned' ? 'default' : trainingUser.certificateExpirationDate && isValidCertificate(trainingUser.certificateExpirationDate, new Date()) ? 'success' : 'error'}`}
                label={`${trainingUser.training?.documentDate ? `${formatDateToString(trainingUser.training?.documentDate)}` : '' } ${trainingUser.certificateExpirationDate ? `(${formatDateToString(trainingUser.certificateExpirationDate)})` : '' }`}
              />
            </ListItem>
          ))}
        </MUIList>
      </>
    );
  }

  const renderActions = (params: GridRenderCellParams<any, number>) => {
    let user = users.find(
      (user) => user["@id"] === params.value
    );

    return (
      <IconButton
        onClick={() => {
          dispatch(
            showModal({
              title: i18next.t("editUser"),
              description: "Edit a user",
              form: "user",
              formProps: { user: user },
            })
          );
        }}
      >
        <EditIcon size="small" />
      </IconButton>
    );
  }

  const renderSites = (
    params: GridRenderCellParams<any | undefined>
  ) => {
    if (!params.value) {
      return '';
    }
    
    return RenderSites(findObjectsByIds(sites, params.value));
  };

  const renderTrainings = (
    params: GridRenderCellParams<any | undefined>
  ) => {
    if (!params.value) {
      return '';
    }
    
    return RenderTrainings(params.value);
  };

  let columns: GridColDef[] = [
    {
      field: "active",
      headerName: i18next.t("active"),
      minWidth: 150,
      type: "boolean",
    },
    { field: "name", headerName: i18next.t("name"), minWidth: 200 },
    {
      field: "email",
      headerName: i18next.t("email"),
      minWidth: 250,
    },
    {
      field: "sites",
      headerName: i18next.t("sites"),
      renderCell: renderSites,
      flex: 1,
      minWidth: 200,
      align: "center",
    },
    {
      field: "trainings",
      headerName: i18next.t("trainingCertificates"),
      renderCell: renderTrainings,
      flex: 1,
      minWidth: 450,
      align: "center",
    },
    {
      field: "certificates",
      headerName: i18next.t("certificates"),
      renderCell: renderFileGroup,
      flex: 1,
      align: "center",
      headerAlign: 'center',
    },
    {
      field: "id",
      headerName: "",
      minWidth: 50,
      renderCell: renderActions,
      editable: false,
      flex: 1,
      align: "right",
    },
  ];

  return (
        <Grid item xs={12} sm={12} component={Paper}>
            <Grid item sx={{ height: "80vh" }}>
              <DataGrid
                hideFooter={true}
                columns={columns}
                sx={{ marginBottom: "25px" }}
                rows={rows}
                getRowHeight={() => 'auto'}
                disableColumnSelector
                disableDensitySelector
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
                columnVisibilityModel={{
                    active: editable,
                    id: editable,
                }}
                loading={isLoading}
              />
            </Grid>
        </Grid>
    );
}