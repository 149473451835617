import React from "react";
import { useDispatch } from "react-redux";
import { showModal } from "../../data/modal.tsx";
import { fileIconHandler, hasFile } from "../Helpers.tsx";
import i18next from '../../i18n.js';

import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridToolbar,
} from "@mui/x-data-grid";

import {
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";

import {
  Edit as EditIcon,
  InsertDriveFileSharp as InsertDriveFileSharpIcon,
  RecentActors as RecentActorsIcon,
} from "@mui/icons-material";

const RenderTrainingList = ({rows, trainings, users, editable=false}) => {
  const dispatch = useDispatch();

  const renderActions = (params: GridRenderCellParams<any, number>) => {
    let training = trainings.find(
      (training) => training["@id"] === params.value
    );

    return (
      <IconButton
        onClick={() => {
          dispatch(
            showModal({
              title: i18next.t("editTraining"),
              description: "Edit a training",
              form: "training",
              formProps: { 
                training: training,
                users: users,
              },
            })
          );
        }}
      >
        <EditIcon size="small" />
      </IconButton>
    );
  };

  const renderFileGroup = (params: GridRenderCellParams<any, number>) => {
    let training = trainings.find(
      (training) => training === params.value
    );

    if (!training) {
      return <></>
    }

    const trainingCertificates = training.fileGroup;

    return (
      <IconButton
        onClick={() => {
          dispatch(
            showModal({
              title: i18next.t("trainingDocument"),
              description: "Training documents",
              form: "filegroup",
              formProps: { 
                object: training,
              },
            })
          );
        }}
      >
        <InsertDriveFileSharpIcon 
          size="small" 
          color={ fileIconHandler(trainingCertificates) }
          opacity={ hasFile(trainingCertificates) ? 1 : 0.4 }
        />
      </IconButton>
    );
  }

  const hasTrainingUsers = (training) => {
    return training.trainingUsers && training.trainingUsers.length > 0;
  }

  const renderTrainingUsers = (params: GridRenderCellParams<any, number>) => {
    let training = trainings.find(
      (training) => training === params.value
    );

    if (!training) {
      return <></>
    }

    return (
      <IconButton
        onClick={() => {
          dispatch(
            showModal({
              title: i18next.t("certificates"),
              description: "Training users",
              form: "trainingUserList",
              formProps: { 
                training: training,
              },
            })
          );
        }}
      >
        <Typography>{ training.trainingUsers?.length > 0 ? training.trainingUsers.length : '' }</Typography>
        <RecentActorsIcon 
          size="small" 
          color={ hasTrainingUsers(training) ? '' : 'disabled' }
          opacity={ hasTrainingUsers(training) ? 1 : 0.4 }
        />
      </IconButton>
    );
  }

  let columns: GridColDef[] = [
    { field: "status", headerName: i18next.t("status"), minWidth: 100 },
    { field: "title", headerName: i18next.t("title"), minWidth: 300 },
    { field: "certificate", headerName: i18next.t("certificateReceived"), minWidth: 200 },
    { field: "documentDate", headerName: i18next.t("date"), minWidth: 200 },
    {
      field: "trainingUsers",
      headerName: i18next.t("userCertificates"),
      minWidth: 150,
      renderCell: renderTrainingUsers,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "document",
      headerName: i18next.t("trainingDocument"),
      minWidth: 150,
      renderCell: renderFileGroup,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "id",
      headerName: "",
      minWidth: 50,
      renderCell: renderActions,
      flex: 1,
      align: "right",
    },
  ];

  return (
    <Grid item xs={12} sm={12} component={Paper}>
        <Grid item sx={{ height: "80vh" }}>
            <DataGrid
                hideFooter={true}
                columns={columns}
                sx={{ marginBottom: "25px" }}
                rows={rows}
                getRowHeight={() => 'auto'}
                disableColumnSelector
                disableDensitySelector
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
                columnVisibilityModel={{
                    id: editable,
                    document: editable,
                }}
                loading={trainings.loading}
            />
        </Grid>
    </Grid>
  );
};

export default RenderTrainingList;
