import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../Layout.tsx";
import { showModal } from "../../../data/modal.tsx";
import { getProducts } from "../../../data/product.js";
import { getClassifications } from "../../../data/classifications.js";
import { getPackagings } from "../../../data/packaging.js";
import TenantFilter from "../../partial/TenantFilter.tsx";
import { capitalizeFirstLetter } from "../../Helpers.tsx";
import ActiveFilter from "../../partial/ActiveFilter.tsx";
import { selectFilteredPackagings, selectFilteredProducts } from "../../../data/selectors/filterSelector.js";
import RenderProductList from "../../partial/Products.tsx";
import i18next from '../../../i18n.js';

import {
  GridRowsProp,
} from "@mui/x-data-grid";

import {
  Button,
  Grid,
  Paper,
  Typography,
} from "@mui/material";

const ProductManage = () => {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);

  const selectedData = useSelector((state) => ({
    classifications: state.classifications,
    filter: state.filter,
    products: state.products,
  }));

  const filteredProducts = useSelector(selectFilteredProducts);
  const filteredPackagings = useSelector(selectFilteredPackagings);

  useEffect(() => {
    dispatch(getProducts());
    dispatch(getPackagings());
    dispatch(getClassifications());
  }, [dispatch]);

  const { classifications, filter, products } = selectedData;

  useEffect(() => {
    setRows(formatDataGridData(filteredProducts));
  }, [filteredProducts]);

  const formatDataGridData: any = (products): GridRowsProp[] => {
    let formattedProducts: GridRowsProp[] = [];

    products.map((product) => {
      formattedProducts.push({
        id: product["@id"],
        active: product.active,
        name: product,
        classification: product.classification,
        unNr: product.unNr ? `UN${product.unNr}` : '',
        classifications: product.classifications,
        transporType: product.transportType ?  capitalizeFirstLetter(product.transportType) : '',
        category: product.category,
        sdsFiles: product,
        description: product,
      });
    });

    return formattedProducts;
  };

  return (
    <Layout>
      <Grid container>
      <Grid item xs={12} sm={2} padding={1}>
        <Grid item component={ Paper }  sx={{ height: '100%' }}>
            <ActiveFilter/>
            <TenantFilter/>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={10} padding={1}>
        <Grid item  sx={{ height: 'auto' }}>
            <Grid
              className="settings-page-header"
              item
              xs={12}
              sm={12}
              padding={1}
            >
              <Grid item>
                <Typography variant="h6" gutterBottom>
                  {i18next.t("products")}
                </Typography>
              </Grid>
              <Grid item>
                { filter.active ? (
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      dispatch(
                        showModal({
                          title: filter.tenant ? i18next.t("addProductToTenant", {tenant: filter.tenant.name}) : i18next.t("addProduct"),
                          description: "Creates a new product",
                          form: "product",
                          formProps: { 
                            classifications: classifications.data, 
                            packagings: filteredPackagings,
                            tenant: filter.tenant,
                          },
                        })
                      );
                    }}
                  >
                    {i18next.t("addProduct")}
                  </Button>
                ) : ''}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} component={Paper}>
              <RenderProductList
                products={filteredProducts}
                packagings={filteredPackagings}
                classifications={classifications.data}
                rows={rows}
                editable={true}
                isLoading={products.loading}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default ProductManage;
