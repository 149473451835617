import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../Layout.tsx";
import { showModal } from "../../../data/modal.tsx";
import TenantFilter from "../../partial/TenantFilter.tsx";
import { getUsers } from "../../../data/users.js";
import { getSites } from "../../../data/site.js";
import { getTrainings } from "../../../data/training.js";
import { selectFilteredSites, selectFilteredUsers, selectFilteredTrainings } from "../../../data/selectors/filterSelector.js";
import ActiveFilter from "../../partial/ActiveFilter.tsx";
import RenderUserList from "../../partial/Users.tsx";
import i18next from '../../../i18n.js';

import {
  GridRowsProp,
} from "@mui/x-data-grid";

import {
  Button,
  Grid,
  Paper,
  Typography,
} from "@mui/material";

const UserManage = () => {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);

  const selectedData = useSelector((state) => ({
    filter: state.filter,
    users: state.users,
  }));

  const filteredUsers = useSelector(selectFilteredUsers);
  const filteredSites = useSelector(selectFilteredSites);
  const filteredTrainings = useSelector(selectFilteredTrainings);

  const { filter, users } = selectedData;

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getSites());
    dispatch(getTrainings());
  }, [dispatch]);

  useEffect(() => {
    setRows(formatDataGridData(filteredUsers));
  }, [filteredUsers]);

  const formatDataGridData: any = (users): GridRowsProp[] => {
    let formattedUsers: GridRowsProp[] = [];

    users.map((user) => {
      formattedUsers.push({
        id: user["@id"],
        name: `${ user.lastName ?? '' } ${ user.firstName ?? '' }`,
        email: user.email ?? '',
        active: user.isActive,
        sites: user.userSites,
        certificates: user,
        trainings: user.trainingUsers,
      });
    });

    return formattedUsers;
  };

  return (
    <Layout>
      <Grid container>
      <Grid item xs={12} sm={2} padding={1}>
        <Grid item component={ Paper }  sx={{ height: '100%' }}>
            <ActiveFilter/>
            <TenantFilter/>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={10} padding={1}>
        <Grid item  sx={{ height: 'auto' }}>
            <Grid
              className="settings-page-header"
              item
              xs={12}
              sm={12}
              padding={1}
            >
              <Grid item>
                <Typography variant="h6" gutterBottom>
                  {i18next.t("users")}
                </Typography>
              </Grid>
              <Grid item>
                { filter.active ? (
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      dispatch(
                        showModal({
                          title: `${ filter && filter.tenant ? `${i18next.t("addUserToTenant", {tenant: filter.tenant.name})}`: i18next.t("addUser")}`,
                          description: "Creates a new user",
                          form: "user",
                        })
                      );
                    }}
                  >
                    {i18next.t("addUser")}
                  </Button>
                ) : ''}
              </Grid>
            </Grid>
              <RenderUserList
                users={filteredUsers}
                sites={filteredSites}
                trainings={filteredTrainings}
                rows={rows}
                editable={true}
                isLoading={users.loading}
              />
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default UserManage;
