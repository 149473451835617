/*****************/
/* INITIAL STATE */
/*****************/
type ModalState = {
  modal: ModalProperties | null | undefined;
  childModal: ModalProperties | null | undefined;
};

const initialState: ModalState = {
  modal: null,
  childModal: null,
};

/*********/
/* TYPES */
/*********/
interface ModalProperties {
  title?: string;
  description?: string;
  form?: string;
  formProps?: any;
}

enum ModalActionTypes {
  SHOW_MODAL = "SHOW_MODAL",
  HIDE_MODAL = "HIDE_MODAL",
  SHOW_CHILD_MODAL = "SHOW_CHILD_MODAL",
  HIDE_CHILD_MODAL = "HIDE_CHILD_MODAL",
}

export interface ModalAction {
  type: ModalActionTypes;
  payload?: ModalProperties;
}

/*******************/
/* ACTION CREATORS */
/*******************/
export function showModal(payload: ModalProperties): ModalAction {
  return {
    type: ModalActionTypes.SHOW_MODAL,
    payload,
  };
}

export function hideModal(): ModalAction {
  return {
    type: ModalActionTypes.HIDE_MODAL,
  };
}

export function showChildModal(payload: ModalProperties): ModalAction {
  return {
    type: ModalActionTypes.SHOW_CHILD_MODAL,
    payload,
  };
}

export function hideChildModal(): ModalAction {
  return {
    type: ModalActionTypes.HIDE_CHILD_MODAL,
  };
}

/***********/
/* REDUCER */
/***********/
function modalReducer(state = initialState, action: ModalAction): ModalState {
  switch (action.type) {
    case ModalActionTypes.SHOW_MODAL:
      return {
        ...state,
        modal: action.payload,
      };
    case ModalActionTypes.HIDE_MODAL:
      return {
        ...state,
        modal: null,
      };
    case ModalActionTypes.SHOW_CHILD_MODAL:
      return {
        ...state,
        childModal: action.payload,
      };
    case ModalActionTypes.HIDE_CHILD_MODAL:
      return {
        ...state,
        childModal: null,
      };
    default:
      return state;
  }
}
export default modalReducer;
