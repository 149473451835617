import React from "react";
import dayjs from "dayjs";
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore.js';
dayjs.extend(isSameOrBefore)

import { 
  Avatar, 
  ListItem, 
  Chip, 
  List as MUIList, 
  Stack, 
  Grid, 
  Typography 
} from "@mui/material";

export const DEFAULT_LANGUAGES = ['en', 'nl', 'fr', 'de'];

export const thousandSeparator = (number:number|undefined, withDecimals:boolean = false) => {
  if (number === undefined) {
      return '';
  }

  number = Math.round(number * 100) / 100;

  if (withDecimals) {
      return  number.toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ").replace('.',',');
  }

  return number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ").replace('.',',');
}

export const findObjectById = (objectArray: any, objectId: string) => {
    if (!objectArray || objectArray.length === 0) {
        return undefined;
    }

    return objectArray.find(
      (object: any) => object && object["@id"] && object["@id"] === objectId
    );
};

export const findObjectsByIds = (objectArray: any, objectIds: any) => {
    if (!objectArray || objectArray.length === 0) {
        return undefined;
    }

    return objectArray.filter(
      (object: any) => object && object["@id"] && objectIds.includes(object["@id"])
    );
};

export const formateDate = (date: Date) => {
    return date ? dayjs(date).format("DD/MM/YYYY") : "";
};

export const colorCodeIsLight = (color: string) => {
  const hex = color.replace('#', '');
  const c_r = parseInt(hex.substring(0, 0 + 2), 16);
  const c_g = parseInt(hex.substring(2, 2 + 2), 16);
  const c_b = parseInt(hex.substring(4, 4 + 2), 16);
  const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;

  return brightness > 155;
}

export const textColorHandler = (color: string) => {
  if (!color) {
    return '#6C757D';
  }
  
  return colorCodeIsLight(color) ? '#6C757D' : '#FFFFFF';
}

export const LabelColorFormatter = (color:string|undefined): string => {
    const defaultColor = '#2196f3'
  
    if (color === undefined) {
      return defaultColor;
    }
    
    color = color.replace(/\s/g, '')
    color = color.replace(/\#/g, '')
    color = `#${color.toUpperCase()}`
    
    if (!(/^#([0-9a-f]{3}){1,2}$/i).test(color)) {
      return defaultColor;
    }
  
    return color;
};

export const formatDateToString = (date: Date|undefined, format = "DD/MM/YYYY") => {
  return date ? dayjs(date).format(format) : "";
};

export const formatAddressToString = (address:any) => {
  if (!address) {
    return '';
  }

  return (
    <>
      { `${ address.number ?? '' } ${ address.street ?? '' }, ${ address.postalCode ?? '' } ${ address.city ?? '' }` },&nbsp; 
      { `${ address.country ?? '' }` }
    </>
  );
};

export const RenderLabelChip = (label: string, color: string) => {
  return <Chip size="small" label={label} sx={{ backgroundColor: `${ color } !important`, color: `${ textColorHandler(color) } !important` }} />
}

export const RenderLabels = (labels) => {
  if (labels === undefined || !labels) {
    return <></>;
  }
  
  return (
    <>
      <MUIList key={"label-list"} component={Stack} direction="row" sx={{ padding: 0 }}>
        {labels.map((label: any) => (
          <ListItem
            sx={{ display: "flex", width: 'auto', padding: '4px' }}
            key={ `label-list-${label["@id"]}`  }
          >
            { RenderLabelChip(label.name, label.color) }
          </ListItem>
        ))}
      </MUIList>
    </>
  );
}

export const RenderSites = (sites) => {
  if (sites === undefined || !sites) {
    return <></>;
  }

  return (
    <>
      <MUIList key={"site-list"} component={Stack} direction="column" sx={{ padding: 0 }}>
        {sites.map((site: any) => (
          <ListItem
            sx={{ display: "flex", width: 'auto', padding: '4px' }}
            key={ `site-list-${site["@id"]}`  }
          >
            
            <Chip size="small" label={ site.name } />
          </ListItem>
        ))}
      </MUIList>
    </>
  );
}

export const RenderAvatarChip = (label: string, imagesrc: string) => {
  return (
    <Chip
      avatar={<Avatar alt={ label } src={ imagesrc } />}
      label={ label }
      variant="outlined"
    />
  )
}

export const containerUidFormatter = (uid) => {
  const letters = uid.substring(0,4)
  const numbers = uid.substring(4,uid.length)
  return {
    letters: letters,
    numbers: numbers,
  }
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getProductDiscriptionByLanguages = (product:any, languages, fontSize='10px') => {

  if (!product) {
    return <></>;
  }
  
  let productByLanguages = languages.map((language) => {
      if (product[`fullDescription${capitalizeFirstLetter(language)}`] === undefined) {
        return `(${language.toUpperCase()})`;
      }

      return `(${language.toUpperCase()}) ${ product[`fullDescription${capitalizeFirstLetter(language)}`] }`;

  })

  return (
    <Grid item>
      { productByLanguages && productByLanguages.map((product) => (
        <Typography fontSize={fontSize}>{ product }</Typography>
      ))}
    </Grid>
  );
}

export const getPackagingByLanguages = (packaging:any, languages) => {

  let packagingByLanguages = languages.map((language) => {
      if (packaging[`name${capitalizeFirstLetter(language)}`] === undefined) {
        return `(${language.toUpperCase()})`;
      }

      return `(${language.toUpperCase()}) ${ packaging[`name${capitalizeFirstLetter(language)}`] }`;

  })

  return (
    <Grid item>
      { packagingByLanguages && packagingByLanguages.map((packaging) => (
        <Typography fontSize='10px'>{packaging}</Typography>
      ))}
    </Grid>
  );
}

export const hasFile = (fileGroup) => {
  return fileGroup && fileGroup.files && fileGroup.files.length > 0;
}

export const fileIconHandler = (fileGroup) => {
  return hasFile(fileGroup) ? '' : 'disabled';
}

export const isValidCertificate = (date, date2) => {
  return dayjs(date2).isSameOrBefore(date);
}
