import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { showModal } from "../../data/modal.tsx";
import i18next from '../../i18n.js';
import { fileIconHandler, hasFile } from "../Helpers.tsx";


import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridToolbar,
} from "@mui/x-data-grid";  

import {
    Grid,
    IconButton,
    Paper,
    Typography,
} from "@mui/material";

import {
  Edit as EditIcon,
  InsertDriveFileSharp as InsertDriveFileSharpIcon,
  RecentActors as RecentActorsIcon,
  Description as DescriptionIcon,
} from "@mui/icons-material";

export default function RenderInspectionList ({inspections, rows, editable, isLoading}) {
  const dispatch = useDispatch();

  const selectedData = useSelector((state) => ({
    userLanguage: state.currentUser.language,
  }));

  const renderActions = (params: GridRenderCellParams<any, number>) => {
    let editinspection = inspections.find(
      (inspection) => inspection["@id"] === params.value
    );

    return (
      <>
      <IconButton
        onClick={() => {
          dispatch(
            showModal({
              title: i18next.t("editInspection"),
              description: "Edit inspection",
              form: "inspection",
              formProps: { inspection: editinspection,  },
            })
          );
        }}
      >
        <EditIcon size="small" />
      </IconButton>
      </>
    );
  };

  const renderFile = (params: GridRenderCellParams<any, number>) => {
    if (!params.value) {
      return <></>
    }

    const files = params.value.fileGroup;

    return (
      <IconButton
        onClick={() => {
          dispatch(
            showModal({
              title: i18next.t("documents"),
              description: "documents",
              form: "filegroup",
              formProps: { 
                object: params.value,
                viewOnly: !editable,
              },
            })
          );
        }}
      >
        <InsertDriveFileSharpIcon 
          size="small" 
          color={ fileIconHandler(files) }
          opacity={ hasFile(files) ? 1 : 0.4 }
        />
      </IconButton>
    );
  }

  const hasInspectionUsers = (inspection) => {
    return inspection.inspectionUsers && inspection.inspectionUsers.length > 0;
  }

  const renderInspectionUsers = (params: GridRenderCellParams<any, number>) => {
    let inspection = inspections.find(
      (inspection) => inspection === params.value
    );

    if (!inspection) {
      return <></>
    }

    return (
      <IconButton
        onClick={() => '#'}
      >
        <Typography>{ inspection.inspectionUsers?.length > 0 ? inspection.inspectionUsers.length : '' }</Typography>
        <RecentActorsIcon 
          size="small" 
          color={ hasInspectionUsers(inspection) ? '' : 'disabled' }
          opacity={ hasInspectionUsers(inspection) ? 1 : 0.4 }
        />
      </IconButton>
    );
  }

  const renderDescription = (params: GridRenderCellParams<any, number>) => {
    return (
      <IconButton
        onClick={() => {
          dispatch(
            showModal({
              title: `${ i18next.t("description") } - ${ params.value.title }`,
              description: "Inspection description",
              form: "description",
              formProps: { 
                description: params.value.description ?? '',
              },
            })
          );
        }}
      >
        <DescriptionIcon 
          size="small" 
          color={ params.value.description ? '' : 'disabled' }
          opacity={ params.value.description ? 1 : 0.4 }
        />
      </IconButton>
    )
  }

  let columns: GridColDef[] = [
    {
      field: "status",
      headerName: i18next.t("status"),
      minWidth: 100,
    },
    {
      field: "documentDate",
      headerName: i18next.t("date"),
      minWidth: 100,
    },
    { 
      field: "title", 
      headerName: i18next.t("title"), 
      minWidth: 300, 
    },
    { 
      field: "description", 
      headerName: i18next.t("description"), 
      minWidth: 80,
      renderCell: renderDescription,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "inspectionUsers",
      headerName: i18next.t("users"),
      minWidth: 80,
      renderCell: renderInspectionUsers,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "files",
      headerName: i18next.t("files"),
      renderCell: renderFile,
      minWidth: 80,
      align: "center",
    },
    {
      field: "id",
      headerName: "",
      minWidth: 50,
      renderCell: renderActions,
      flex: 1,
      align: "right",
    },
  ];

  return (
        <Grid item xs={12} sm={12} component={Paper}>
            <Grid item sx={{ height: "80vh" }}>
                <DataGrid
                    hideFooter={true}
                    columns={columns}
                    sx={{ marginBottom: "25px" }}
                    rows={rows}
                    getRowHeight={() => 'auto'}
                    disableColumnSelector
                    disableDensitySelector
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        },
                    }}
                    columnVisibilityModel={{
                        id: editable,
                        files: editable,
                    }}
                    loading={isLoading}
                />
            </Grid>
        </Grid>
    );
}