import axios from "axios";
import { hideChildModal, hideModal } from "../data/modal.tsx";

/*****************/
/* INITIAL STATE */
/*****************/
const initialState = {
  loading: false,
  error: "",
  data: [],
  progress: {
    page: 0,
    pages: 0,
    total: 0,
    loaded: 0,
  },
};

/*********/
/* TYPES */
/*********/
const FETCH_CONTACTS = "FETCH_CONTACTS";
const FETCH_CONTACTS_SUCCESS = "FETCH_CONTACTS_SUCCESS";
const FETCH_CONTACTS_ERROR = "FETCH_CONTACTS_ERROR";
const FETCH_NEXT_CONTACTS = "FETCH_NEXT_CONTACTS";
const FETCH_NEXT_CONTACTS_SUCCESS = "FETCH_NEXT_CONTACTS_SUCCESS";
const PUT_CONTACT = "PUT_CONTACT";
const POST_CONTACT = "POST_CONTACT";

/*******************/
/* ACTION CREATORS */
/*******************/
export const getContacts = () => async (dispatch, getState) => {
  dispatch(loadContacts());
  const {
    contacts: { progress, data },
  } = getState();

  if (progress.loaded === 0 || data.length !== progress.loaded) {
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/contacts?page=1`,
      headers: {
        "Content-Type": "application/ld+json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (response.data["hydra:member"].length === 0) {
      dispatch(setError("No contacts found"));
    } else {
      dispatch(
        setContacts(
          response.data["hydra:member"],
          parseInt(response.data["hydra:totalItems"], 10)
        )
      );
      if (
        parseInt(response.data["hydra:totalItems"], 10) >
        response.data["hydra:member"].length
      ) {
        dispatch(getNextContacts());
      }
    }
  }
};

export const getNextContacts = () => async (dispatch, getState) => {
  const {
    contacts: {
      progress: { page, pages },
    },
  } = getState();
  const pageToLoad = page + 1;
  dispatch(loadNextContacts(pageToLoad));

  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/contacts?page=${pageToLoad}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  dispatch(setNextContacts(response.data["hydra:member"]));
  if (pageToLoad < pages) {
    dispatch(getNextContacts());
  }
};

export const updateContact = (contact) => async (dispatch, getState) => {
  dispatch(putContact());
  const {
    contacts: {
      data,
      progress: { total },
    },
  } = getState();

  const response = await axios({
    method: "put",
    url: `${process.env.REACT_APP_API_URL}/contacts/${contact["@id"].replace(
      "/api/contacts/",
      ""
    )}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: contact,
  });

  if (response.status === 200) {
    const resultData = data.map((contact) => {
      if (contact["@id"] === response.data["@id"]) {
        return response.data;
      }

      return contact;
    });

    dispatch(setContacts(resultData, total));
    dispatch(hideModal());
  } else {
    setError("Something went wrong updating");
  }
};

export const createContact =
  (contact, callback, args) => async (dispatch, getState) => {
    dispatch(postContact());
    const {
      contacts: {
        data,
        progress: { total },
      },
      modal: {childModal},
    } = getState();

    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/contacts`,
      headers: {
        "Content-Type": "application/ld+json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: contact,
    });

    if (response.status === 201) {
      const resultData = [...data, ...[response.data]];

      dispatch(setContacts(resultData, total));

      if (childModal) {
        dispatch(hideChildModal());
        callback(args, response.data["@id"]);
      } else {
        dispatch(hideModal());
      }
    } else {
      setError("Something went wrong deleting");
    }
  };

export const loadContacts = () => ({ type: FETCH_CONTACTS });

export const putContact = () => ({ type: PUT_CONTACT });

export const postContact = () => ({ type: POST_CONTACT });

export const setContacts = (contacts, total) => ({
  type: FETCH_CONTACTS_SUCCESS,
  payload: { contacts, total },
});

export const loadNextContacts = (page) => ({
  type: FETCH_NEXT_CONTACTS,
  payload: page,
});
export const setNextContacts = (contacts) => ({
  type: FETCH_NEXT_CONTACTS_SUCCESS,
  payload: contacts,
});

export const setError = (msg) => ({
  type: FETCH_CONTACTS_ERROR,
  payload: msg,
});

/***********/
/* REDUCER */
/***********/
const contactReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CONTACTS:
      return {
        ...state,
        loading: true,
        error: "",
        progress: {
          page: 1,
          pages: 0,
          loaded: 0,
          total: 0,
        },
      };
    case FETCH_CONTACTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        data: payload.contacts,
        progress: {
          ...state.progress,
          loaded: payload.contacts.length,
          pages: Math.ceil(payload.total / payload.contacts.length),
          total: payload.total,
        },
      };

    case FETCH_CONTACTS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case FETCH_NEXT_CONTACTS:
      return {
        ...state,
        error: "",
        progress: {
          ...state.progress,
          page: payload,
        },
      };
    case FETCH_NEXT_CONTACTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, ...payload],
        progress: {
          ...state.progress,
          loaded: state.progress.loaded + payload.length,
        },
      };
    case PUT_CONTACT:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case POST_CONTACT:
      return {
        ...state,
        loading: true,
        error: "",
      };
    default:
      return state;
  }
};

export default contactReducer;
