import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../Layout.tsx";
import { getContacts } from "../../../data/contacts.js";
import { showModal } from "../../../data/modal.tsx";
import Modal from "../../Modal.tsx";
import SettingsMenu from "../Menu.tsx";
import TenantFilter from "../../partial/TenantFilter.tsx";
import { selectFilteredContacts } from "../../../data/selectors/filterSelector.js";
import i18next from '../../../i18n.js';

import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Typography,
} from "@mui/material";

import { 
  Edit as EditIcon,
} from "@mui/icons-material";

const ContactManage = () => {
  const dispatch = useDispatch();
  const [superAdmin, setSuperAdmin] = useState(false);

  const filteredContacts = useSelector(selectFilteredContacts);

  const selectedData = useSelector((state) => ({
    filter: state.filter,
    currentUser: state.currentUser?.data,
  }));

  const { currentUser, filter } = selectedData;

  useEffect(() => {
    dispatch(getContacts());

    if (currentUser && currentUser.roles && currentUser.roles.includes("ROLE_SUPER_ADMIN")) {
      setSuperAdmin(true)
    }
  }, [dispatch, currentUser]);

  return (
    <Layout>
    <Modal/>
    <Grid container>
      <Grid item xs={12} sm={2} padding={1}>
        <Grid item component={ Paper } sx={{ height: 'auto', maxHeight: '20vh' }}>
          <SettingsMenu activeMenu={ 'contact' }/>
        </Grid>
        { superAdmin ? (
          <Grid item component={ Paper } marginTop={2} sx={{ height: 'auto', maxHeight: '60vh' }}>
            <TenantFilter/>
          </Grid>
        ) : ''}
      </Grid>
      <Grid item xs={12} sm={10} padding={1}>
        <Grid item component={ Paper }  sx={{ height: 'auto' }}>
          <Grid className='settings-page-header' item xs={12} sm={12} padding={1}>
            <Grid item>
              <Typography variant="h6" gutterBottom>
                {i18next.t("contacts")}
              </Typography>
            </Grid>
            <Grid item>
              <Button 
                  variant='contained'
                  size='small'
                  onClick={() => {
                    dispatch(
                      showModal({
                        title: filter.tenant ? i18next.t("addContactToTenant", {tenant: filter.tenant.name}) : i18next.t("addContact"),
                        description: "Creates a new contact",
                        form: "contact",
                      })
                    );
                  }}
                >
                {i18next.t("addContact")}
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} padding={1}>
          <Grid item sx={{ height: '80vh' }}>
            <TableContainer>
              <Table aria-label="terminal table" stickyHeader>
                <TableHead>
                    <TableRow>
                      <TableCell>
                        {i18next.t("name")}
                      </TableCell>
                      <TableCell align='center'>
                        {i18next.t("phoneNumber")}
                      </TableCell>
                      <TableCell align='center'>
                        {i18next.t("email")}
                      </TableCell>
                      <TableCell>
                        {i18next.t("address")}
                      </TableCell>
                      <TableCell align='right'></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                      { filteredContacts.length !== 0 && filteredContacts.map((contact: Contact) => (
                        <TableRow className='js-collection-item' data-object-id={ contact['@id'] }>
                          <TableCell>{ contact.name  }</TableCell>
                          <TableCell align='center'>{ contact.phoneNumber }</TableCell>
                          <TableCell align='center'>{ contact.email }</TableCell>
                          <TableCell>
                            { contact?.address !== undefined ? (
                                <>
                                  { `${ contact.address.number ?? '' } ${ contact.address.street ?? '' }, ${ contact.address.postalCode ?? '' } ${ contact.address.city ?? '' }` }<br />
                                  { `${ contact.address.country ?? '' }` }
                                </>
                            ) : '' }   
                          </TableCell>
                          <TableCell align='right'>
                            <IconButton onClick={() => {
                              dispatch(
                                showModal({
                                  title: i18next.t("editContact"),
                                  description: "Edits a contact",
                                  form: "contact",
                                  formProps: { contact: contact },
                                })
                              );
                            }}>
                              <EditIcon size='small'/> 
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    </Layout>
  );
};

export default ContactManage;
