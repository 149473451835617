import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enJSON from './locale/en.json';
import frJSON from './locale/fr.json';
import nlJSON from './locale/nl.json';
import deJSON from './locale/de.json';

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    compatibilityJSON: 'v4',
    resources: {
        en: { ...enJSON },
        fr: { ...frJSON },
        nl: { ...nlJSON },
        de: { ...deJSON },
    },
    interpolation: {
      escapeValue: false,
    },
  });


export default i18n;