import * as React from 'react';
import { useForm, SubmitHandler } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux";
import { InputTextField } from '../FormFields/RenderInputTextField.tsx';
import { createContact, updateContact } from '../../data/contacts.js';
import i18next from '../../i18n.js';
import { SelectField } from '../FormFields/RenderSelectField.tsx';
import { GetLanguageOptions } from './SelectOptionsFormatter.tsx';

import {
    hideModal,
    hideChildModal,
  } from "../../data/modal.tsx";

import {
    Button,
    Divider,
    Grid,
    Typography,
} from "@mui/material";

import {
    Close as CloseIcon,
    Save as SaveIcon,
} from '@mui/icons-material';

const ContactForm = ({ contact, callback, args }) => {    
    const dispatch = useDispatch();

    const selectedData = useSelector((state) => ({
        modal: state.modal,
        filter: state.filter
    }));

    const { handleSubmit, control, formState } = useForm({
        defaultValues: contact,
    });
    
    const { isSubmitting } = formState;

    const onSubmit: SubmitHandler = (data) => {
        let formData = data;

        if (selectedData.filter.tenant !== undefined) {
            formData = {
              ...data,
              tenant: selectedData.filter.tenant['@id'],
            }
        } 

        if (contact) {
            dispatch(updateContact(formData));
        } else {
            dispatch(createContact(formData, callback, args));
        }
    };

    const handleCloseForm = () => {
        if (selectedData.modal.childModal) {
            dispatch(hideChildModal());
        } else {
            dispatch(hideModal());
        }
    };
        
    return (
        <form onSubmit={ handleSubmit(onSubmit) }>
            <Grid container xs={12}>
                <Grid item xs={12} sm={12}>
                    <Grid item xs={12}>
                        <Typography variant='h6' className='form-fields-title'>
                            {i18next.t("contactInformation")}
                        </Typography>
                        <Grid item xs={12} className='form-fields-container'>
                            <Grid item xs={12} sm={3}>
                                <InputTextField
                                    name='name'
                                    label={i18next.t("name")}
                                    control={ control }
                                    required
                                />
                            </Grid>
                            <Grid item  xs={12} sm={3}>
                                <InputTextField
                                    name='phoneNumber'
                                    label={i18next.t("phoneNumber")}
                                    control={ control }
                                />
                            </Grid> 
                            <Grid item  xs={12} sm={3}>
                                <InputTextField
                                    name='email'
                                    label={i18next.t("email")}
                                    control={ control }
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <SelectField
                                    name='defaultLanguage'
                                    label={ i18next.t("defaultLanguage") }
                                    control={ control }
                                    options={ GetLanguageOptions() }
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
                        <Divider orientation="horizontal" />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Typography variant='h6' className='form-fields-title'>
                            {i18next.t("address")}
                        </Typography>
                        <Grid item xs={12} className='form-fields-container'>
                            <Grid item xs={12} sm={2}>
                                <InputTextField
                                    name='address.number'
                                    label={i18next.t("number")}
                                    control={ control }
                                />
                            </Grid> 
                            <Grid item xs={12} sm={4}>
                                <InputTextField
                                    name='address.street'
                                    label={i18next.t("street")}
                                    control={ control }
                                />
                            </Grid> 
                            <Grid item xs={12} sm={2}>
                                <InputTextField
                                    name='address.postalCode'
                                    label={i18next.t("postalCode")}
                                    control={ control }
                                />
                            </Grid> 
                            <Grid item xs={12} sm={2}>
                                <InputTextField
                                    name='address.city'
                                    label={i18next.t("city")}
                                    control={ control }
                                />
                            </Grid> 
                            <Grid item xs={12} sm={2}>
                                <InputTextField
                                    name='address.country'
                                    label={i18next.t("country")}
                                    control={ control }
                                />
                            </Grid> 
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className='button-wrapper'>
                        <Grid item>
                            <Button 
                                onClick={() => {handleCloseForm()}}
                                disabled={ isSubmitting }
                                variant='contained'
                            >
                                <CloseIcon fontSize="inherit" />&nbsp;{i18next.t("close")}
                            </Button>
                        </Grid>
                        <Button 
                            type='submit'
                            disabled={ isSubmitting }
                            variant='contained'
                        >
                            <SaveIcon fontSize="inherit" />&nbsp;{i18next.t("save")}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}

export default ContactForm;