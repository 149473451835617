import React from 'react';

import { 
  SvgIcon,
} from '@mui/material';

const TRANSPORT_MEDIUM_ICON = {
  colli: <svg 
          xmlns="http://www.w3.org/2000/svg" 
          viewBox="0 0 500 500" 
          preserveAspectRatio="none"
          >
             <g>
                <path d="M412.08,115.326c-0.006-0.322-0.027-0.643-0.064-0.961c-0.011-0.1-0.02-0.201-0.035-0.3
                  c-0.057-0.388-0.131-0.773-0.232-1.151c-0.013-0.05-0.032-0.097-0.046-0.146c-0.094-0.33-0.206-0.654-0.333-0.973
                  c-0.041-0.102-0.085-0.203-0.129-0.304c-0.126-0.289-0.266-0.571-0.42-0.848c-0.039-0.069-0.073-0.141-0.113-0.209
                  c-0.203-0.346-0.426-0.682-0.672-1.004c-0.019-0.025-0.042-0.049-0.061-0.074c-0.222-0.285-0.463-0.558-0.718-0.82
                  c-0.07-0.072-0.143-0.142-0.215-0.212c-0.225-0.217-0.461-0.424-0.709-0.622c-0.077-0.062-0.15-0.126-0.229-0.185
                  c-0.311-0.234-0.634-0.457-0.979-0.657L226.034,1.359c-3.111-1.813-6.956-1.813-10.067,0l-181.092,105.5
                  c-0.345,0.201-0.668,0.423-0.979,0.657c-0.079,0.059-0.152,0.124-0.229,0.185c-0.248,0.198-0.484,0.405-0.709,0.622
                  c-0.073,0.07-0.145,0.14-0.215,0.212c-0.255,0.262-0.496,0.535-0.718,0.82c-0.02,0.025-0.042,0.049-0.061,0.074
                  c-0.246,0.322-0.468,0.658-0.672,1.004c-0.04,0.068-0.075,0.14-0.113,0.209c-0.154,0.277-0.294,0.559-0.42,0.848
                  c-0.044,0.101-0.088,0.202-0.129,0.304c-0.127,0.319-0.239,0.643-0.333,0.973c-0.014,0.049-0.033,0.097-0.046,0.146
                  c-0.101,0.378-0.175,0.763-0.232,1.151c-0.014,0.099-0.023,0.2-0.035,0.3c-0.037,0.319-0.058,0.639-0.064,0.961
                  c-0.001,0.058-0.012,0.115-0.012,0.174v211c0,3.559,1.891,6.849,4.966,8.641l181.092,105.5c0.029,0.017,0.059,0.027,0.088,0.043
                  c0.357,0.204,0.725,0.391,1.108,0.55c0.004,0.002,0.009,0.003,0.014,0.005c0.362,0.15,0.736,0.273,1.118,0.38
                  c0.097,0.027,0.193,0.051,0.291,0.075c0.299,0.074,0.603,0.134,0.912,0.181c0.103,0.016,0.205,0.035,0.308,0.047
                  c0.393,0.047,0.79,0.078,1.195,0.078s0.803-0.031,1.195-0.078c0.103-0.012,0.205-0.031,0.308-0.047
                  c0.309-0.047,0.613-0.107,0.912-0.181c0.097-0.024,0.194-0.047,0.291-0.075c0.382-0.107,0.756-0.23,1.118-0.38
                  c0.004-0.002,0.009-0.003,0.014-0.005c0.383-0.159,0.751-0.346,1.108-0.55c0.029-0.016,0.059-0.027,0.088-0.043l181.092-105.5
                  c3.075-1.792,4.966-5.082,4.966-8.641v-211C412.092,115.441,412.081,115.385,412.08,115.326z M221,209.427l-70.68-41.177
                  l161.226-93.927l70.681,41.177L221,209.427z M221,21.573l70.68,41.177l-161.226,93.927L59.774,115.5L221,21.573z M392.092,320.752
                  L231,414.601V374c0-5.523-4.477-10-10-10s-10,4.477-10,10v40.601L49.908,320.752V132.899l75.626,44.058
                  c0.005,0.003,0.011,0.006,0.016,0.01L211,226.747V334c0,5.523,4.477,10,10,10s10-4.477,10-10V226.747l161.092-93.848V320.752z"/>
                <path d="M284.613,247.88c1.858,3.189,5.208,4.968,8.65,4.968c1.709,0,3.441-0.438,5.024-1.361l36.584-21.313
                  c4.772-2.78,6.387-8.902,3.607-13.674c-2.78-4.772-8.903-6.386-13.674-3.607l-36.584,21.313
                  C283.448,236.986,281.833,243.108,284.613,247.88z"/>
              </g>
          </svg>,
  tank: <svg 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 500 450" 
        preserveAspectRatio="none"
        >
          <path d="M462.684,249.345l-20.579-68.598C439.1,170.73,430.054,164,419.596,164H375.5c-4.142,0-7.5,3.357-7.5,7.5V204h-25.111
          c-1.819-31.185-27.756-56-59.389-56H255v-16.5c0-4.143-3.358-7.5-7.5-7.5h-40c-4.142,0-7.5,3.357-7.5,7.5V148H59.5
          C26.691,148,0,174.691,0,207.5c0,28.874,20.677,53.001,48,58.374V276H23.5c-4.142,0-7.5,3.357-7.5,7.5s3.358,7.5,7.5,7.5H32v16.5
          c0,4.143,3.358,7.5,7.5,7.5h41.409c3.374,13.759,15.806,24,30.591,24c9.607,0,18.217-4.328,24-11.131
          c5.783,6.803,14.393,11.131,24,11.131c14.785,0,27.216-10.241,30.591-24h75.855c2.647,4.765,7.728,8,13.555,8h40
          c5.827,0,10.908-3.235,13.555-8h19.855c3.374,13.759,15.806,24,30.591,24s27.216-10.241,30.591-24H455.5c4.142,0,7.5-3.357,7.5-7.5
          v-56C463,250.771,462.894,250.044,462.684,249.345z M445.42,244H423.5c-9.098,0-16.5-7.402-16.5-16.5V203h26.12L445.42,244z
          M352.909,300H335v-8.5c0-0.169-0.02-0.333-0.025-0.5h21.707C354.984,293.749,353.7,296.778,352.909,300z M368,276h-73v-10.126
          c23.6-4.641,42.233-23.274,46.874-46.874H368V276z M328,207.5c0,24.537-19.962,44.5-44.5,44.5H279v-89h4.5
          C308.038,163,328,182.963,328,207.5z M79,252v-89h185v89H79z M215,139h25v9h-25V139z M15,207.5c0-24.537,19.962-44.5,44.5-44.5H64
          v89h-4.5C34.962,252,15,232.037,15,207.5z M63,267h217v9H63V267z M264,291.5v8.5h-73.909c-0.79-3.222-2.074-6.251-3.772-9h77.707
          C264.02,291.167,264,291.331,264,291.5z M47,291h37.681c-1.698,2.749-2.982,5.778-3.772,9H47V291z M111.5,324
          c-9.098,0-16.5-7.402-16.5-16.5s7.402-16.5,16.5-16.5s16.5,7.402,16.5,16.5S120.598,324,111.5,324z M159.5,324
          c-9.098,0-16.5-7.402-16.5-16.5s7.402-16.5,16.5-16.5s16.5,7.402,16.5,16.5S168.598,324,159.5,324z M319.5,308h-40
          c-0.276,0-0.5-0.225-0.5-0.5v-16c0-0.275,0.224-0.5,0.5-0.5h40c0.276,0,0.5,0.225,0.5,0.5v16C320,307.775,319.776,308,319.5,308z
          M383.5,324c-9.098,0-16.5-7.402-16.5-16.5s7.402-16.5,16.5-16.5s16.5,7.402,16.5,16.5S392.598,324,383.5,324z M414.091,300
          c-3.374-13.759-15.806-24-30.591-24c-0.168,0-0.333,0.01-0.5,0.013V179h36.596c3.783,0,7.055,2.435,8.142,6.058L428.62,188H399.5
          c-4.142,0-7.5,3.357-7.5,7.5v32c0,17.369,14.131,31.5,31.5,31.5H448v9h-8.5c-4.142,0-7.5,3.357-7.5,7.5s3.358,7.5,7.5,7.5h8.5v17
          H414.091z"/>
        </svg>,
  container: <svg 
              xmlns="http://www.w3.org/2000/svg" 
              viewBox="0 0 550 550" 
              preserveAspectRatio="none"
              >
                <g transform="translate(1 1)">
                  <g>
                    <g>
                      <path d="M229.4,280.6c-5.12,0-8.533,3.413-8.533,8.533V459.8c0,5.12,3.413,8.533,8.533,8.533s8.533-3.413,8.533-8.533V289.133
                        C237.933,284.013,234.52,280.6,229.4,280.6z"/>
                      <path d="M178.2,280.6c-5.12,0-8.533,3.413-8.533,8.533V459.8c0,5.12,3.413,8.533,8.533,8.533s8.533-3.413,8.533-8.533V289.133
                        C186.733,284.013,183.32,280.6,178.2,280.6z"/>
                      <path d="M127,280.6c-5.12,0-8.533,3.413-8.533,8.533V459.8c0,5.12,3.413,8.533,8.533,8.533s8.533-3.413,8.533-8.533V289.133
                        C135.533,284.013,132.12,280.6,127,280.6z"/>
                      <path d="M75.8,280.6c-5.12,0-8.533,3.413-8.533,8.533V459.8c0,5.12,3.413,8.533,8.533,8.533s8.533-3.413,8.533-8.533V289.133
                        C84.333,284.013,80.92,280.6,75.8,280.6z"/>
                      <path d="M434.2,280.6c-5.12,0-8.533,3.413-8.533,8.533V459.8c0,5.12,3.413,8.533,8.533,8.533s8.533-3.413,8.533-8.533V289.133
                        C442.733,284.013,439.32,280.6,434.2,280.6z"/>
                      <path d="M383,280.6c-5.12,0-8.533,3.413-8.533,8.533V459.8c0,5.12,3.413,8.533,8.533,8.533s8.533-3.413,8.533-8.533V289.133
                        C391.533,284.013,388.12,280.6,383,280.6z"/>
                      <path d="M331.8,280.6c-5.12,0-8.533,3.413-8.533,8.533V459.8c0,5.12,3.413,8.533,8.533,8.533c5.12,0,8.533-3.413,8.533-8.533
                        V289.133C340.333,284.013,336.92,280.6,331.8,280.6z"/>
                      <path d="M280.6,280.6c-5.12,0-8.533,3.413-8.533,8.533V459.8c0,5.12,3.413,8.533,8.533,8.533s8.533-3.413,8.533-8.533V289.133
                        C289.133,284.013,285.72,280.6,280.6,280.6z"/>
                      <path d="M499.907,286.573C506.733,279.747,511,270.36,511,260.12v-13.653c0-5.12-3.413-8.533-8.533-8.533h-23.04l-158.72-63.147
                        c-4.267-1.707-9.387,0.853-11.093,5.12s0.853,9.387,5.12,11.093l117.968,46.933H80.323l146.756-54.354
                        c7.434,12.211,20.85,20.221,36.455,20.221c23.893,0,42.667-18.773,42.667-42.667c0-20.48-14.507-37.547-34.133-41.813v-10.212
                        c19.681-3.86,34.133-20.878,34.133-41.841V7.533c0-5.12-3.413-8.533-8.533-8.533s-8.533,3.413-8.533,8.533V32.93
                        c-7.09-5.25-15.924-8.33-25.6-8.33s-18.51,3.08-25.6,8.33V7.533c0-5.12-3.413-8.533-8.533-8.533s-8.533,3.413-8.533,8.533v59.733
                        c0,20.963,14.452,37.981,34.133,41.841V127c0,5.12,3.413,8.533,8.533,8.533c14.507,0,25.6,11.093,25.6,25.6
                        c0,14.507-11.093,25.6-25.6,25.6c-8.208,0-15.322-3.554-19.953-9.266l19.822-7.341l5.251,2.101
                        c1.707,0.853,2.56,0.853,3.413,0.853c3.413,0,6.827-1.707,7.68-5.973c1.707-4.267-0.853-9.387-5.12-11.093l-5.853-2.341
                        c-1.514-1.04-3.317-1.628-5.125-1.597c-0.037-0.002-0.075,0-0.112-0.001c-0.04,0.001-0.08-0.001-0.12,0.001
                        c-1.697-0.029-3.387,0.489-4.841,1.413l-20.643,7.646c0-5.12-3.413-8.533-8.533-8.533s-8.533,3.413-8.533,8.533
                        c0,2.097,0.153,4.152,0.433,6.161L30.573,237.933H7.533c-5.12,0-8.533,3.413-8.533,8.533v13.653
                        c0,10.24,4.267,19.627,11.093,26.453c3.413,3.413,5.973,9.387,5.973,14.507v146.773c0,5.973-2.56,10.24-5.973,14.507
                        C3.267,469.187-1,478.573-1,488.813v13.653c0,5.12,3.413,8.533,8.533,8.533h494.933c5.12,0,8.533-3.413,8.533-8.533v-13.653
                        c0-10.24-4.267-19.627-11.093-26.453c-4.267-4.267-5.973-9.387-5.973-14.507V301.08
                        C493.933,295.107,496.493,290.84,499.907,286.573z M237.933,67.267c0-14.507,11.093-25.6,25.6-25.6
                        c14.507,0,25.6,11.093,25.6,25.6s-11.093,25.6-25.6,25.6C249.027,92.867,237.933,81.773,237.933,67.267z M487.96,474.307
                        c4.267,4.267,5.973,9.387,5.973,14.507v5.12H16.067v-5.12c0-5.973,2.56-10.24,5.973-14.507
                        c6.827-6.827,11.093-16.213,11.093-26.453V301.08c0-9.387-4.267-19.627-11.093-26.453c-3.413-4.267-5.973-9.387-5.973-14.507V255
                        h477.867v5.12c0,5.12-2.56,10.24-5.973,14.507c-6.827,6.827-11.093,16.213-11.093,26.453v146.773
                        C476.867,458.093,481.133,467.48,487.96,474.307z"/>
                    </g>
                  </g>
                </g>            
              </svg>,
}

export default function TransportMediumIcon({ type, fillColor='#666666', size = '30px' }) {
    return (
      <SvgIcon sx={{ height: size, width: size, fill: (fillColor) }}>
          { TRANSPORT_MEDIUM_ICON[type]}
      </SvgIcon>
    );
  }