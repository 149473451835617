import "../../styles/form.css";
import React, { useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { CheckboxField } from "../FormFields/RenderCheckboxField.tsx";
import { InputTextField } from "../FormFields/RenderInputTextField.tsx";
import { InputTextareaField } from "../FormFields/RenderInputTextareaField.tsx";
import { SelectField } from "../FormFields/RenderSelectField.tsx";
import { NumberField } from "../FormFields/RenderNumberField.tsx";
import { SelectMultipleField } from "../FormFields/RenderSelectMultipleField.tsx";
import { GetClassificationOptions } from "./SelectOptionsFormatter.tsx";
import { SingleFileField } from "../FormFields/RenderSingleFileField.tsx";
import DeleteButton from "../partial/DeleteButton.tsx";
import i18next from '../../i18n.js';
import { SelectCrudField } from "../FormFields/RenderSelectCrudField.tsx";
import { capitalizeFirstLetter } from "../Helpers.tsx";

import {
  hideModal,
  hideChildModal,
} from "../../data/modal.tsx";

import {
  createProduct,
  updateProduct,
  removeProduct,
} from "../../data/product";

import { Button, Divider, Grid, Typography } from "@mui/material";

import {
  Close as CloseIcon,
  Save as SaveIcon,
} from "@mui/icons-material";
import { selectFilteredPackagings } from "../../data/selectors/filterSelector.js";
import { getPackagings } from "../../data/packaging.js";
import { MultipleFileField } from "../FormFields/RenderMultipleFileField.tsx";

const ProductForm = ({ product, classifications, tenant, callback}) => {
  const dispatch = useDispatch();

  const filteredPackagings = useSelector(selectFilteredPackagings);

  useEffect(() => {
    dispatch(getPackagings());
  }, [dispatch]);

  const selectedData = useSelector((state) => ({
    modal: state.modal,
    language: state.currentUser?.language
  }));

  const { handleSubmit, control, formState, register, setValue, } = useForm({
    defaultValues: product,
  });

  const { isSubmitting } = formState;

  const onSubmit: SubmitHandler = (data) => {
    let formData = data;

    if (tenant !== undefined) {
        formData = {
          ...data,
          tenant: tenant['@id'],
        }
    } 

    if (product) {
      dispatch(updateProduct(formData));
    } else {
      dispatch(createProduct(formData, callback));
    }
  };

  const transportCategoryOptions = () => {
    return [
      {
        value: "0",
        label: i18next.t("transportCategory_0"),
      },
      {
        value: "1",
        label: i18next.t("transportCategory_1"),
      },
      {
        value: "2",
        label: i18next.t("transportCategory_2"),
      },
      {
        value: "3",
        label: i18next.t("transportCategory_3"),
      },
      {
        value: "4",
        label: i18next.t("transportCategory_4"),
      },
    ];
  };

  const handleCloseForm = () => {
    if (selectedData.modal.childModal) {
      dispatch(hideChildModal());
    } else {
      dispatch(hideModal());
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container maxWidth="80vh">
        <Grid item xs={12} sm={12}>
          <Grid item xs={12}>
            <Grid item xs={12} className="form-fields-container">
              <Grid item xs={12} sm={3}>
                <CheckboxField
                  name="active"
                  label="is Active"
                  control={control}
                  defaultValue={true}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <SelectField
                  name="transportType"
                  label="Transport type"
                  control={control}
                  options={[
                    {
                      value: "incoming",
                      label: "Incoming",
                    },
                    {
                      value: "outgoing",
                      label: "Outgoing",
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
            <Divider orientation="horizontal" />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" className="form-fields-title">
              {i18next.t("name")}
            </Typography>
            <Grid item xs={12} className="form-fields-container">
              <Grid item xs={12} sm={3}>
                <InputTextField
                  name="nameNl"
                  label="Name NL"
                  control={control}
                  required={true}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputTextField
                  name="nameEn"
                  label="Name EN"
                  control={control}
                  required={true}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputTextField
                  name="nameDe"
                  label="Name DE"
                  control={control}
                  required={true}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputTextField
                  name="nameFr"
                  label="Name FR"
                  control={control}
                  required={true}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
            <Divider orientation="horizontal" />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" className="form-fields-title">
              {i18next.t("description")}
            </Typography>
            <Grid item xs={12} className="form-fields-container">
              <Grid item xs={12} sm={3}>
                <InputTextField
                  name="descriptionNl"
                  label={ `${i18next.t("description")} NL`}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputTextField
                  name="descriptionEn"
                  label={ `${i18next.t("description")} EN`}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputTextField
                  name="descriptionDe"
                  label={ `${i18next.t("description")} DE`}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputTextField
                  name="descriptionFr"
                  label={ `${i18next.t("description")} FR`}
                  control={control}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className="form-fields-container">
              <Grid item xs={12} sm={3}>
                <InputTextareaField
                  name="fullDescriptionNl"
                  label={ `${i18next.t("fullDescription")} NL`}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputTextareaField
                  name="fullDescriptionEn"
                  label={ `${i18next.t("fullDescription")} EN`}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputTextareaField
                  name="fullDescriptionDe"
                  label={ `${i18next.t("fullDescription")} DE`}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputTextareaField
                  name="fullDescriptionFr"
                  label={ `${i18next.t("fullDescription")} FR`}
                  control={control}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
            <Divider orientation="horizontal" />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" className="form-fields-title">
              {i18next.t("classification")}
            </Typography>
            <Grid item xs={12} className="form-fields-container">
              <Grid item xs={12} sm={12}>
                <SelectMultipleField
                  name="classifications"
                  label={i18next.t("classification")}
                  control={control}
                  options={GetClassificationOptions(classifications)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
            <Divider orientation="horizontal" />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" className="form-fields-title">
              {i18next.t("packaging")}
            </Typography>
            <Grid item xs={12} className="form-fields-container">
              <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  xs={12}
                  sm={8}
                >
                  <SelectCrudField
                    name="packaging"
                    label={i18next.t("packaging")}
                    control={control}
                    data={filteredPackagings}
                    optionLabel={[`${ selectedData.language ? `name${capitalizeFirstLetter(selectedData.language )}` : 'nameEn'}`]}
                    createModalProps={{
                      title: i18next.t("addPackaging"),
                      description: "Quick add packaging",
                      form: "packaging",
                      formProps: {
                        callback: setValue,
                        args: "packaging",
                      },
                    }}
                    editModalProps={{
                      title: i18next.t("editPackaging"),
                      description: "Quick edit a packaging",
                      form: "packaging",
                    }}
                  />
                </Grid>
              <Grid item xs={12} sm={4}>
                <InputTextField name="unit" label={i18next.t("unit")} control={control} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
            <Divider orientation="horizontal" />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" className="form-fields-title">
              {i18next.t("otherInformation")}
            </Typography>
            <Grid item xs={12} className="form-fields-container">
              <Grid item xs={12} sm={3}>
                <SelectField
                  name="category"
                  label={i18next.t("transportCategory")}
                  control={control}
                  options={transportCategoryOptions()}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <NumberField
                  name="unNr"
                  label={i18next.t("productUnNumber")}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputTextField
                  name="adrLabel"
                  label={i18next.t("AdrImdgLabel")}
                  control={control}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <InputTextField
                  name="tunnelRestrictionCode"
                  label={i18next.t("tunnelRestrictionCode")}
                  control={control}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className="form-fields-container">
              <Grid item xs={12} sm={3}>
                <InputTextField name="ge" label="GE" control={control} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
            <Divider orientation="horizontal" />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" className="form-fields-title">
              {i18next.t("sdsFiles")}
            </Typography>
            <Grid item xs={12} className="form-fields-container">
                <MultipleFileField
                    register={register}
                    setValue={setValue}
                    control={control}
                    name="fileGroup.files"
                    existingFiles={product && product.fileGroup ? product.fileGroup.files : []}
                    showExistingFiles={true}
                />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="button-wrapper">
          <Grid container spacing={1}>
            <Grid item>
              <Button
                onClick={() => {handleCloseForm()}}
                disabled={isSubmitting}
                variant="contained"
              >
                <CloseIcon fontSize="inherit" />
                &nbsp;{i18next.t("close")}
              </Button>
            </Grid>
            {product && (
              <Grid item>
                <DeleteButton>
                  <Button
                    onClick={() => {
                      dispatch(removeProduct(product));
                    }}
                    disabled={isSubmitting}
                    color="error"
                  >
                    {i18next.t("delete")}
                  </Button>
                </DeleteButton>
              </Grid>
            )}
          </Grid>
          <Button type="submit" disabled={isSubmitting} variant="contained">
            <SaveIcon fontSize="inherit" />
            &nbsp;{i18next.t("save")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ProductForm;
