import axios from "axios";
import { hideChildModal, hideModal } from "../data/modal.tsx";

/*****************/
/* INITIAL STATE */
/*****************/
const initialState = {
  loading: false,
  error: "",
  data: [],
  progress: {
    page: 0,
    pages: 0,
    total: 0,
    loaded: 0,
  },
};

/*********/
/* TYPES */
/*********/
const FETCH_PRODUCTS = "FETCH_PRODUCTS";
const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
const FETCH_PRODUCTS_ERROR = "FETCH_PRODUCTS_ERROR";
const PUT_PRODUCT = "PUT_PRODUCT";
const POST_PRODUCT = "POST_PRODUCT";
const DELETE_PRODUCT = "DELETE_PRODUCT";
const FETCH_NEXT_PRODUCTS = "FETCH_NEXT_PRODUCTS";
const FETCH_NEXT_PRODUCTS_SUCCESS = "FETCH_NEXT_PRODUCTS_SUCCESS";

/*******************/
/* ACTION CREATORS */
/*******************/
export const getProducts = (tenant, transportType) => async (dispatch) => {
  dispatch(loadProducts());

  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/products?page=1${ tenant ?
      `&tenant=${tenant['@id']}`
    : ''}
    ${ transportType ?
      `&transportType=${ transportType }`
    : ''}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.data["hydra:member"].length === 0) {
    dispatch(setError("No products found"));
  } else {
    dispatch(
      setProducts(
        response.data["hydra:member"],
        parseInt(response.data["hydra:totalItems"], 10)
      )
    );
    if (
      parseInt(response.data["hydra:totalItems"], 10) >
      response.data["hydra:member"].length
    ) {
      dispatch(getNextProducts(tenant));
    }
  }
};

export const getNextProducts = (tenant) => async (dispatch, getState) => {
  const {
    products: {
      progress: { page, pages },
    },
  } = getState();
  const pageToLoad = page + 1;
  dispatch(loadNextProducts(pageToLoad));

  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/products?page=${pageToLoad}${ tenant ?
      `&tenant=${tenant['@id']}`
    : ''}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  dispatch(setNextProducts(response.data["hydra:member"]));
  if (pageToLoad < pages) {
    dispatch(getNextProducts());
  }
};

export const updateProduct =
  (product) => async (dispatch, getState) => {
    dispatch(putProduct());
    const {
      products: {
        data,
        progress: { total },
      },
    } = getState();

    const response = await axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}/products/${product[
        "@id"
      ].replace("/api/products/", "")}`,
      headers: {
        "Content-Type": "application/ld+json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: product,
    });

    if (response.status === 200) {
      const resultData = data.map((product) => {
        if (product["@id"] === response.data["@id"]) {
          return response.data;
        }

        return product;
      });

      dispatch(setProducts(resultData, total));
      dispatch(hideModal());
    } else {
      setError("Something went wrong updating");
    }
  };

export const createProduct = (product, callback) => async (dispatch, getState) => {
    dispatch(postProduct());
    const {
      products: {
        data,
        progress: { total },
      },
      modal: { childModal },
    } = getState();

    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/products`,
      headers: {
        "Content-Type": "application/ld+json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: product,
    });

    if (response.status === 201) {
      const resultData = [...data, ...[response.data]];
  
      dispatch(setProducts(resultData, total));
      
      if (childModal) {
        dispatch(hideChildModal());
        callback("product", response.data["@id"]);
      } else {
        dispatch(hideModal());
      }
    } else {
      setError("Something went wrong deleting");
    }
  };

export const removeProduct = (product) => async (dispatch, getState) => {
  dispatch(putProduct());
  const {
    products: {
      data,
      progress: { total },
    },
  } = getState();

  const response = await axios({
    method: "delete",
    url: `${process.env.REACT_APP_API_URL}/products/${product[
      "@id"
    ].replace("/api/products/", "")}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    }
  });

  if (response.status === 204) {
    const resultData = data.filter((x) => x["@id"] !== product["@id"]);

    dispatch(setProducts(resultData, total));
    dispatch(hideModal());
  } else {
    setError("Something went wrong deleting");
  }
};

export const loadProducts = () => ({ type: FETCH_PRODUCTS });

export const putProduct = () => ({ type: PUT_PRODUCT });

export const postProduct = () => ({ type: PUT_PRODUCT });

export const deleteProduct = () => ({ type: DELETE_PRODUCT });

export const setProducts = (products, total) => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: { products, total },
});

export const loadNextProducts = (page) => ({
  type: FETCH_NEXT_PRODUCTS,
  payload: page,
});
export const setNextProducts = (products) => ({
  type: FETCH_NEXT_PRODUCTS_SUCCESS,
  payload: products,
});

export const setError = (msg) => ({
  type: FETCH_PRODUCTS_ERROR,
  payload: msg,
});

/***********/
/* REDUCER */
/***********/
const productReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_PRODUCTS:
      return {
        ...state,
        loading: true,
        error: "",
        progress: {
          page: 1,
          pages: 0,
          loaded: 0,
          total: 0,
        },
      };
    case FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        data: payload.products,
        progress: {
          ...state.progress,
          loaded: payload.products.length,
          pages: Math.ceil(payload.total / payload.products.length),
          total: payload.total,
        },
      };

    case PUT_PRODUCT:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case POST_PRODUCT:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case DELETE_PRODUCT:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_PRODUCTS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case FETCH_NEXT_PRODUCTS:
      return {
        ...state,
        error: "",
        progress: {
          ...state.progress,
          page: payload,
        },
      };
    case FETCH_NEXT_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, ...payload],
        progress: {
          ...state.progress,
          loaded: state.progress.loaded + payload.length,
        },
      };
    default:
      return state;
  }
};
export default productReducer;
