/*****************/
/* INITIAL STATE */
/*****************/
const initialState = {
  data: undefined,
  language: undefined,
};

/*********/
/* TYPES */
/*********/
interface FilterProperties {
  data?: Array<string>;
}

enum FilterActionTypes {
  ADD_CURRENT_USER = "ADD_CURRENT_USER",
  ADD_CURRENT_USER_LANGUAGE = "ADD_CURRENT_USER_LANGUAGE",
  REMOVE_CURRENT_USER = "REMOVE_CURRENT_USER",
  REMOVE_CURRENT_USER_LANGUAGE = "REMOVE_CURRENT_USER_LANGUAGE",
}

export interface FilterAction {
  type: FilterActionTypes;
  payload?: FilterProperties;
}

/*******************/
/* ACTION CREATORS */
/*******************/
export function addCurrentUser(payload): FilterAction {
  return {
    type: FilterActionTypes.ADD_CURRENT_USER,
    payload,
  };
}

export function removeCurrentUser(payload): FilterAction {
  return {
    type: FilterActionTypes.REMOVE_CURRENT_USER,
    payload,
  };
}

export function addCurrentUserLanguage(payload): FilterAction {
  return {
    type: FilterActionTypes.ADD_CURRENT_USER_LANGUAGE,
    payload,
  };
}

export function removeCurrentUserLanguage(payload): FilterAction {
  return {
    type: FilterActionTypes.REMOVE_CURRENT_USER_LANGUAGE,
    payload,
  };
}

/***********/
/* REDUCER */
/***********/
function currentUserReducer(state = initialState, action: FilterAction) {
  switch (action.type) {
    case FilterActionTypes.ADD_CURRENT_USER:
      return {
        ...state,
        data: action.payload,
    };
    case FilterActionTypes.REMOVE_CURRENT_USER:
      return {
        ...state,
        data: state.data !== action.payload ? action.payload : undefined,
    };
    case FilterActionTypes.ADD_CURRENT_USER_LANGUAGE:
      return {
        ...state,
        language: action.payload,
    };
    case FilterActionTypes.REMOVE_CURRENT_USER_LANGUAGE:
      return {
        ...state,
        language: state.language !== action.payload ? action.payload : undefined,
    };
    default:
      return state;
  }
}

export default currentUserReducer;
