import React, {useEffect} from 'react';
import { useForm, SubmitHandler } from "react-hook-form"
import { InputTextField } from '../FormFields/RenderInputTextField.tsx';
import { InputPasswordField } from '../FormFields/RenderInputPasswordField.tsx';
import { hideModal } from "../../data/modal.tsx";
import { useDispatch, useSelector } from "react-redux";
import { CheckboxField } from '../FormFields/RenderCheckboxField.tsx';
import { GetLanguageOptions } from './SelectOptionsFormatter.tsx';
import { SelectField } from '../FormFields/RenderSelectField.tsx';
import i18next from '../../i18n.js';
import { SelectCrudField } from '../FormFields/RenderSelectCrudField.tsx';
import { selectFilteredSites, selectFilteredTrainings } from '../../data/selectors/filterSelector.js';

import {
    createUser,
    updateUser,
  } from "../../data/users.js";

import {
    Button,
    Divider,
    Grid,
    Typography,
} from "@mui/material";

import {
    Close as CloseIcon,
    Save as SaveIcon,
} from '@mui/icons-material';
import { getSites } from '../../data/site.js';
import { getTrainings } from '../../data/training.js';

const UserForm = ({user}) => {
    const dispatch = useDispatch();
    const filteredSites = useSelector(selectFilteredSites);

    useEffect(() => {
        dispatch(getSites());
        dispatch(getTrainings());
    }, [dispatch]);

    const selectedData = useSelector((state) => ({
        filter: state.filter,
    }));

    const { handleSubmit, control, formState, setValue } = useForm({
        defaultValues: user,
    });

    const { isSubmitting } = formState;

    const onSubmit: SubmitHandler = (data) => {
        let formData = data;
    
        if (selectedData.filter && selectedData.filter.tenant) {
            formData = {
                ...data,
                tenant: selectedData.filter.tenant['@id'],
            }
        }

        if (user) {
            dispatch(updateUser(formData));
        } else {
            dispatch(createUser(formData));
        }
    };
        
    return (
        <form onSubmit={ handleSubmit(onSubmit) }>
            <Grid container xs={12}>
                <Grid item xs={12} sm={12}>
                    <Grid item xs={12} className='form-fields-container'>
                        <Grid item xs={12} sm={4}>
                            <CheckboxField
                                name="isActive"
                                label={ i18next.t("active") }
                                control={control}
                                defaultValue={true}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className='form-fields-container'>
                        <Grid item xs={12} sm={4}>
                            <InputTextField
                                name='firstName'
                                label={ i18next.t("firstName") }
                                control={ control }
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <InputTextField
                                name='lastName'
                                label={ i18next.t("lastName") }
                                control={ control }
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <InputTextField
                                name='email'
                                label='Email'
                                control={ control }
                                required
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
                        <Divider orientation="horizontal" />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid item xs={12} className="form-fields-container">
                            <Grid item xs={12} sm={6}>
                                <InputPasswordField
                                    name='plainPassword'
                                    label={ i18next.t("password") }
                                    control={ control }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SelectField
                                    name='defaultLanguage'
                                    label={ i18next.t("defaultLanguage") }
                                    control={ control }
                                    options={ GetLanguageOptions() }
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h6" className="form-fields-title">
                            { i18next.t("sites") }
                        </Typography>
                        <Grid item xs={12} className="form-fields-container">
                            <SelectCrudField
                                name="userSites"
                                label={i18next.t("sites")}
                                control={control}
                                data={filteredSites}
                                multiple={true}
                                optionLabel={['name']}
                                createModalProps={{
                                    title: i18next.t("addSite"),
                                    description: "Quick add site",
                                    form: "site",
                                    formProps: {
                                        callback: setValue,
                                        args: "userSites",
                                    },
                                }}
                                editModalProps={{
                                    title: i18next.t("editSite"),
                                    description: "Quick edit a site",
                                    form: "site",
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className='button-wrapper'>
                <Button 
                    onClick={() => {dispatch(hideModal())}}
                    disabled={ isSubmitting }
                    variant='contained'
                >
                    <CloseIcon fontSize="inherit" />&nbsp;Close
                </Button>
                    <Button 
                        type='submit'
                        disabled={ isSubmitting }
                        variant='contained'
                    >
                        <SaveIcon fontSize="inherit" />&nbsp;Save
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

export default UserForm;