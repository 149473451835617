import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../../data/user.js";
import { formatAddressToString } from "../Helpers.tsx";

import {
  Grid,
  Typography,
} from "@mui/material";

const ReportHeader = ({ title = '', subTitle = '' }) => {
  const dispatch = useDispatch();
  const selectedData = useSelector((state) => ({
    user: state.currentUser.data,
  }));

  const {
    user
  } = selectedData;
  
  const currentUser = user
  const tenant =  currentUser.tenant ?? undefined;
  const logo = tenant && tenant.logo ? tenant.logo?.contentUrl : undefined;

  return (
    <>
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px",
        }}
      >
        <Grid item width="20%">
          { logo ? (
            <img src={`${ logo }`} height='80px' alt="TenantLogo"/>
          ) : ''}
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          { title ? (<Typography variant="h5">{title}</Typography>) : ''}
          { subTitle ? (<Typography variant="h6">{subTitle}</Typography>) : ''}
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="left"
          width="20%"
        >
          <Typography>{tenant?.name ?? ''}</Typography>
          <Typography>{formatAddressToString(tenant?.address) }</Typography>
        </Grid>
      </Grid>
      <hr></hr>
    </>
  );
};

export default ReportHeader;
