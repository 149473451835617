import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import { createFile } from "../../data/files.js";
import FileUploadProgressBar from "../partial/FileUploadProgress.tsx";
import i18next from '../../i18n.js';

import {
  Box,
  Grid,
  FormControl,
  List as MuiList,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

import {
  Upload as UploadIcon,
  CloudUpload as CloudUploadIcon,
} from "@mui/icons-material";
import { showChildModal } from "../../data/modal.tsx";

export const MultipleFileCollectionField: any = ({
  name,
  control,
  register,
  setValue,
  existingFiles,
  object,
}) => {
  const dispatch = useDispatch();
  const [existingFilesData, setExistingFilesData] = useState(existingFiles);
  const [uploadedFiles, setUploadedFiles] = useState(0);

  const dropzone = useDropzone({
    onDrop: (files: File[]) => files && fileDropHandler(files),
  });

  const uploadHandler = async (file) => {
    return dispatch(createFile(file));
  };

  const fileDropHandler = async (files) => {
    const uploadPromises = files.map((file) => uploadHandler(file));

    setUploadedFiles(uploadPromises.length)

    Promise.all(uploadPromises).then((values: Array<any>) =>
      setValue(name, [...values, ...existingFilesData])
    );
  };

  const fileRemoveHandler = (element) => {
    element.preventDefault();
    const existingFileWrapper = element.target.closest(
      ".js-existing-file-element"
    );
    const fileId = existingFileWrapper.getAttribute("data-existing-file-id");
    const filteredFiles = existingFilesData.filter(
      (file) => file["@id"] !== fileId
    );
    setValue(name, filteredFiles);
    setExistingFilesData(filteredFiles);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={7} className="form-fields-container">
          <FormControl size={"small"} sx={{ width: "100%" }}>
            <Controller
              control={control}
              render={() => (
                <Grid
                  item
                  {...dropzone.getRootProps({ className: "dropzone" })}
                  sx={{
                    maxHeight: "40px",
                    height: "100%",
                    border: "3px dotted #DDDDDD",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center", 
                    flexDirection: 'column',
                    padding: 1,
                  }}
                >
                  <Box
                    component="input"
                    {...dropzone.getInputProps({
                      ...register(name),
                    })}
                  />
                  <Box sx={{ width: '100%' }}>
                    <Typography>Upload</Typography>
                  </Box>
                  {/* <FileUploadProgressBar 
                    loading={ fileState && fileState.loading } 
                    error={ fileState && fileState.error } 
                    loaded={uploadedFiles}
                    total={ dropzone.acceptedFiles.length }
                  /> */}
                </Grid>
              )}
              name={name}
              defaultValue={[]}
            />
          </FormControl>
        </Grid>
        <Grid item xs={5}
          sx={{
            maxHeight: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center", 
            flexDirection: 'row',
            padding: 1,
            flexWrap: 'nowrap',
            alignContent: 'flex-start',
            gap:'5px'
          }}
        >
        {dropzone.acceptedFiles && dropzone.acceptedFiles.length > 0 ? (
            <>
              {dropzone.acceptedFiles.length}
              <UploadIcon size='small' />
            </>
        ) : (
          ""
        )}
        {existingFiles && existingFiles.length > 0 ? (
            <Box
              sx={{
                maxHeight: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center", 
                flexDirection: 'row',
                padding: 1,
                flexWrap: 'nowrap',
                alignContent: 'flex-start',
                gap:'5px'
              }}
              onClick={() => {
                dispatch(
                  showChildModal({
                    title: i18next.t("certificates"),
                    description: "Certificates",
                    form: "filegroup",
                    formProps: { 
                      object: object,
                      viewOnly: true,
                    },
                  })
                );
              }}
            >
              <Typography fontWeight={700}>{existingFiles.length}</Typography>
              <CloudUploadIcon size='small' color='success'/>
            </Box>
        ) : (
          ""
        )}
        </Grid>
      </Grid>
    </>
  );
};
