import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../Layout.tsx";
import { getInspections } from "../../data/inspection.js";
import { selectFilteredInspections } from "../../data/selectors/filterSelector.js";
import i18next from '../../i18n.js';

import {
  GridRowsProp,
} from "@mui/x-data-grid";

import {
  Button,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import RenderInspectionList from "../partial/Inspections.tsx";
import { formatDateToString } from "../Helpers.tsx";
import { getUsers } from "../../data/users.js";

const InspectionList = () => {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);

  const filteredInspections = useSelector(selectFilteredInspections);

  const selectedData = useSelector((state) => ({
    inspections: state.inspections,
  }));

  useEffect(() => {
    dispatch(getInspections());
  }, [dispatch]);

  const { inspections } = selectedData;

  useEffect(() => {
    setRows(formatDataGridData(filteredInspections));
  }, [filteredInspections]);

  const formatDataGridData: any = (inspections): GridRowsProp[] => {
    let formattedProducts: GridRowsProp[] = [];
    inspections.map((inspection) => {
      formattedProducts.push({
        id: inspection["@id"],
        title: inspection.title ?? '',
        status: inspection.status ? i18next.t(inspection.status) : '',
        documentDate: formatDateToString(inspection.documentDate),
        inspectionUsers: inspection,
        files: inspection,
        description: inspection,
      });
    });

    return formattedProducts;
  };

  return (
    <Layout>
      <Grid container>
      <Grid item xs={12} sm={12} padding={1}>
        <Grid item  sx={{ height: 'auto' }}>
            <Grid
              className="settings-page-header"
              item
              xs={12}
              sm={12}
              padding={1}
            >
              <Grid item>
                <Typography variant="h6" gutterBottom>
                  {i18next.t("inspection")}
                </Typography>
              </Grid>
              <Grid item></Grid>
            </Grid>
            <Grid item xs={12} sm={12} component={Paper}>
              <RenderInspectionList
                inspections={filteredInspections}
                rows={rows}
                editable={false}
                isLoading={inspections.loading}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default InspectionList;
