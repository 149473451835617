import React, {useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { fileIconHandler, findObjectById, formatDateToString, hasFile, isValidCertificate } from "../Helpers.tsx";
import { showChildModal } from "../../data/modal.tsx";
import i18next from '../../i18n.js';
import { selectFilteredUsers } from "../../data/selectors/filterSelector";
import { getUsers } from "../../data/users";

import {
  IconButton,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import {
  InsertDriveFileSharp as InsertDriveFileSharpIcon,
} from "@mui/icons-material";

export default function RenderTrainingUserList ({training}) {
  if (training === undefined || !training) {
    return <></>;
  }

  const dispatch = useDispatch();
  const filteredUsers = useSelector(selectFilteredUsers);

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  return (
    <>
      <Grid container padding={0}>
        <TableContainer>
          <Table aria-label="ADR Index table" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>User</TableCell>
                <TableCell align='center'>Training Date</TableCell>
                <TableCell align='center'>Certificate Expiration</TableCell>
                <TableCell align='center'>Certificate</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { training && training.trainingUsers.length > 0 &&  training.trainingUsers.map((trainingUser) => (
                <TableRow>
                  <TableCell>{ `${ findObjectById(filteredUsers, trainingUser.user) ? `${ findObjectById(filteredUsers, trainingUser.user).lastName } ${findObjectById(filteredUsers, trainingUser.user).firstName}`: ''}`}</TableCell>
                  <TableCell align='center'>{ formatDateToString(training.documentDate) }</TableCell>
                  <TableCell align='center'>
                    <Typography 
                      color={`${ trainingUser.training && trainingUser.training.status && trainingUser.training.status === 'planned' ? 'default' : trainingUser.certificateExpirationDate && isValidCertificate(trainingUser.certificateExpirationDate, new Date()) ? 'success' : 'error'}`}
                    >
                      { formatDateToString(trainingUser.certificateExpirationDate) }
                    </Typography>
                  </TableCell>
                  <TableCell align='center'>
                    <IconButton
                      onClick={() => {
                        dispatch(
                          showChildModal({
                            title: i18next.t("trainingDocument"),
                            description: "Training documents",
                            form: "filegroup",
                            formProps: { 
                              object: trainingUser,
                              viewOnly: true,
                            },
                          })
                        );
                      }}
                    >
                      <InsertDriveFileSharpIcon 
                        size="small" 
                        color={ fileIconHandler(trainingUser.fileGroup) }
                        opacity={ hasFile(trainingUser.fileGroup) ? 1 : 0.4 }
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
}