import * as React from 'react';
import dayjs from 'dayjs';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Controller } from "react-hook-form";

export const DateField = ({ name, label, control, required = false }: any) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nl-be">
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <DatePicker 
            format='DD/MM/YYYY'
            label={label} 
            onChange={onChange} 
            required={required}
            value={value ? dayjs(value) : undefined} 
           />
        )}
      />
    </LocalizationProvider>
  );
};