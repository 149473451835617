
import React from "react";

import Layout from "../Layout.tsx";

import {
  Grid,
} from "@mui/material";

const Incidents = () => {


  return (
    <Layout>
      <Grid container>
        Incidents
      </Grid>
    </Layout>
  );
};

export default Incidents;
