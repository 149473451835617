import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formateDate } from "../Helpers.tsx";
import ReportHeader from "./ReportHeader.tsx";

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { getIncomingTransports } from "../../data/incomingTransports.js";
import { getOutgoingTransports } from "../../data/outgoingTransports.js";

const categoryMultipliers = [
  {
    value: "0",
    multiplier: 10,
  },
  {
    value: "1",
    multiplier: 20,
  },
  {
    value: "2",
    multiplier: 3,
  },
  {
    value: "3",
    multiplier: 1,
  },
  {
    value: "4",
    multiplier: 0,
  },
];

const ADRIndex = ({ year, incoming, title }) => {
  const dispatch = useDispatch();

  const selectedData = useSelector((state) => ({
    incomingTransports: state.incomingTransports,
    outgoingTransports: state.outgoingTransports,
  }));

  useEffect(() => {
    dispatch(getIncomingTransports());
    dispatch(getOutgoingTransports());
  }, [dispatch]);

  const { incomingTransports, outgoingTransports } = selectedData;
  const transports = incoming ? incomingTransports : outgoingTransports

  const calculationTransportADRPoints = (products) => {
      let totalPoints = 0;

      if (products && products.length === 0) {
        return totalPoints;
      }

      products && products.map((transportProduct) => {
        let quantity = transportProduct.quantity ?? 0;
        let category = categoryMultipliers.find(({ value }) => value === transportProduct.product?.category);

        totalPoints += quantity * (category !== undefined ? category.multiplier : 0)
      });

      return totalPoints;
  }

  return (
    <>
      <ReportHeader title={ `${title} ADR INDEX ${year}` }/>
      <Grid container padding={1}>
        <TableContainer>
          <Table aria-label="ADR Index table" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Document Nr.</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>{ incoming ? 'From' : 'To' }</TableCell>
                <TableCell>Transporter</TableCell>
                <TableCell align='right'>ADN Points</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { !transports.loading && !transports.error &&  transports.data.length > 0 &&  transports.data.map((transport) => (
                <TableRow>
                  <TableCell>{ transport.number ?? ''}</TableCell>
                  <TableCell>{ formateDate(transport.documentDate) }</TableCell>
                  <TableCell>{ incoming ? transport?.fromContact?.name : transport?.toContact?.name }</TableCell>
                  <TableCell>{ transport.transporter?.name }</TableCell>
                  <TableCell align='right'>{ calculationTransportADRPoints(incoming ? transport.incomingTransportProducts : transport.outgoingTransportProducts) }</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};

export default ADRIndex;
