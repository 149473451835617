import React from 'react';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useDispatch, useSelector } from "react-redux";
import i18next from '../../i18n.js';

import {
  hideModal,
  hideChildModal,
} from "../../data/modal.tsx";

import {
  Alert,
  Button,
  Divider,
  Grid,
} from "@mui/material";

import {
  Close as CloseIcon,
} from '@mui/icons-material';


const DescriptionView = ({description}) => {
    const dispatch = useDispatch();

    const selectedData = useSelector((state) => ({
      modal: state.modal
    }));
    
    const handleCloseForm = () => {
      if (selectedData.modal.childModal) {
        dispatch(hideChildModal());
      } else {
        dispatch(hideModal());
      }
    };

    return (
      <>
        <Grid container justifyContent='center'>
          <Grid item xs={12}  sx={{ padding: 0 }}>
              { description ? (
                <Grid dangerouslySetInnerHTML={{ __html: description }} sx={{ padding: 0 }} />
              ) : (
                <Alert severity="warning">{ i18next.t("noDescription") }</Alert>
              )}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} marginTop={1} marginBottom={1}>
            <Divider orientation="horizontal" />
          </Grid>
          <Grid item xs={12} className="button-wrapper">
              <Button
                onClick={() => {handleCloseForm()}}
                variant="contained"
              >
                <CloseIcon fontSize="inherit" />
                &nbsp;{ i18next.t("close") }
              </Button>
          </Grid>
      </>
    )
}

export default DescriptionView
