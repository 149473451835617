import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../Layout.tsx";
import { showModal } from "../../../data/modal.tsx";
import { getInspections } from "../../../data/inspection.js";
import TenantFilter from "../../partial/TenantFilter.tsx";
import { selectFilteredInspections, selectFilteredUsers } from "../../../data/selectors/filterSelector.js";
import i18next from '../../../i18n.js';

import {
  GridRowsProp,
} from "@mui/x-data-grid";

import {
  Button,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import RenderInspectionList from "../../partial/Inspections.tsx";
import { formatDateToString } from "../../Helpers.tsx";
import { getUsers } from "../../../data/users.js";

const InspectionManage = () => {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);

  const filteredInspections = useSelector(selectFilteredInspections);
  const filteredUsers = useSelector(selectFilteredUsers);

  const selectedData = useSelector((state) => ({
    filter: state.filter,
    inspections: state.inspections,
  }));


  useEffect(() => {
    dispatch(getUsers());
    dispatch(getInspections());
  }, [dispatch]);

  const { filter, inspections } = selectedData;

  useEffect(() => {
    setRows(formatDataGridData(filteredInspections));
  }, [filteredInspections]);

  const formatDataGridData: any = (inspections): GridRowsProp[] => {
    let formattedProducts: GridRowsProp[] = [];

    inspections.map((inspection) => {
      formattedProducts.push({
        id: inspection["@id"],
        title: inspection.title ?? '',
        status: inspection.status ? i18next.t(inspection.status) : '',
        documentDate: formatDateToString(inspection.documentDate),
        inspectionUsers: inspection,
        files: inspection,
        description: inspection,
      });
    });

    return formattedProducts;
  };

  return (
    <Layout>
      <Grid container>
      <Grid item xs={12} sm={2} padding={1}>
        <Grid item component={ Paper }  sx={{ height: '100%' }}>
            <TenantFilter/>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={10} padding={1}>
        <Grid item  sx={{ height: 'auto' }}>
            <Grid
              className="settings-page-header"
              item
              xs={12}
              sm={12}
              padding={1}
            >
              <Grid item>
                <Typography variant="h6" gutterBottom>
                  {i18next.t("inspection")}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    dispatch(
                      showModal({
                        title: filter.tenant ? i18next.t("addInspectionToTenant", {tenant: filter.tenant.name}) : i18next.t("addInspection"),
                        description: "Creates a new inspection",
                        form: "inspection",
                        formProps: { 
                          tenant: filter.tenant,
                        },
                      })
                    );
                  }}
                >
                  {i18next.t("addInspection")}
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} component={Paper}>
              <RenderInspectionList
                inspections={filteredInspections}
                rows={rows}
                editable={true}
                isLoading={inspections.loading}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default InspectionManage;
