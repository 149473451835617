import React from "react";
import TransportMediumIcon from "../partial/TransportMediumIcon.tsx";
import { isValidCertificate } from "../Helpers.tsx";
import i18next from '../../i18n.js';

import {  
  Divider,
  Grid, 
  Tooltip,
} from "@mui/material";

import {
  Verified as VerifiedIcon,
  Warning as WarningIcon,
} from '@mui/icons-material';

export const GetClassificationOptions = (classifications) => {
    const classificationOptions = [];
  
    if (!classifications || classifications.length === 0) {
        return classificationOptions
    }
  
    classifications.map((classification) => { 
        classificationOptions.push({
        value: classification['@id'],
        label: `${ classification.type }: ${ classification.class }`,
        imagesrc: `/img/classification/${ classification.pictogram }.png`,
      })
    })
  
    return classificationOptions;
}

export const GetPackagingOptions = (packagings) => {
    const packagingOptions = [];
  
    if (!packagings || packagings.length === 0) {
        return packagingOptions
    }
  
    packagings.map((packaging) => { 
        packagingOptions.push({
        value: packaging['@id'],
        label: packaging.nameEn,
      })
    })
  
    return packagingOptions;
}

export const GetSiteOptions = (sites) => {
    const siteOptions = [];
  
    if (!sites || sites.length === 0) {
        return siteOptions
    }
  
    sites.map((site) => { 
      siteOptions.push({
        value: site['@id'],
        label: site.name,
      })
    })
  
    return siteOptions;
}

export const GetTrainingptions = (trainings) => {
    const trainingOptions = [];
  
    if (!trainings || trainings.length === 0) {
        return trainingOptions
    }
  
    trainings.map((training) => { 
      trainingOptions.push({
        value: training['@id'],
        label: training.title,
      })
    })
  
    return trainingOptions;
}

export const GetUserOptions = (users) => {
    const userOptions = [];
  
    if (!users || users.length === 0) {
        return userOptions
    }
  
    users.map((user) => { 
      userOptions.push({
        value: user['@id'],
        label: `${ user.lastName ?? '' } ${ user.firstName ?? '' }`,
      })
    })
  
    return userOptions;
}

export const GetTransportMediumOptions = () => {
    return [
        {
          value: "colli",
          label: i18next.t("colli"),
          content: (
            <Grid item className='transport-medium-content'></Grid>
          ),
          icon: <TransportMediumIcon type='colli' size='35px'/>,
        },
        {
          value: "tank",
          label: i18next.t("tankTransport"),
          content: (
            <Grid item className='transport-medium-content'>
              xxx
              <Divider/>
              xxxx
            </Grid>
          ),
          icon: <TransportMediumIcon type='tank' size='60px'/>,
        },
        {
          value: "container",
          label: i18next.t("container"),
          content: (
            <Grid item className='transport-medium-content'></Grid>
          ),
          icon: <TransportMediumIcon type='container' size='35px'/>,
        },
      ];
}

export const GetLanguageOptions = () => {
    return [
        {
          value: "en",
          label: "English",
        },
        {
          value: "nl",
          label: "Nederlands",
        },
        {
          value: "fr",
          label: "Français",
        },
        {
          value: "de",
          label: "Deutsch",
        },
      ];
}

const getDriverCertificateWarning = (certificateExpirationDate, documentDate) => {
  if (!documentDate) {
    return <></>;
  }

  if (!certificateExpirationDate || !isValidCertificate(certificateExpirationDate, documentDate)) {
    return (
      <Tooltip title={i18next.t("invalidDriverCertificate")}>
        <WarningIcon size="small" color='warning' />
      </Tooltip>
    )
  }

  return (
    <Tooltip title={i18next.t("validDriverCertificate")}>
      <VerifiedIcon size="small" color='success' />
    </Tooltip>
  );
}

export const GetDriverOptions = (drivers, documentDate) => {
  const driverOptions = [];

  if (!drivers || drivers.length === 0) {
      return driverOptions
  }

  drivers.map((driver) => { 
    driverOptions.push({
      value: driver['@id'],
      label: `${ driver.lastName ?? '' } ${ driver.firstName ?? '' }`,
      content: getDriverCertificateWarning(driver?.certificateExpirationDate, documentDate),
    })
  })

  return driverOptions;
}

export const trainingStatusOptions = () => {
  return [
    {
      value: "planned",
      label: i18next.t("planned"),
    },
    {
      value: "cancelled",
      label: i18next.t("cancelled"),
    },
    {
      value: "executed",
      label: i18next.t("executed"),
    },
    {
      value: "expired",
      label: i18next.t("expired"),
    },
  ];
};
    
export const inspectionStatusOptions = () => {
  return [
    {
      value: "planned",
      label: i18next.t("planned"),
    },
    {
      value: "cancelled",
      label: i18next.t("cancelled"),
    },
    {
      value: "done",
      label: i18next.t("executed"),
    },
  ];
};