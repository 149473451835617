import * as React from 'react';
import { useForm, SubmitHandler } from "react-hook-form"
import { useDispatch } from "react-redux";
import { updateUser } from '../../data/users.js';
import {
    hideModal,
  } from "../../data/modal.tsx";

import {
    Button,
    Grid,
    Typography,
} from "@mui/material";

import {
    Close as CloseIcon,
    Save as SaveIcon,
} from '@mui/icons-material';

const UserPreferredLandingForm = ({ object, pathname }) => {    
    const dispatch = useDispatch();

    const { handleSubmit, formState } = useForm({
        defaultValues: object,
    });

    const { isSubmitting } = formState;

    const onSubmit: SubmitHandler = (data) => {
        let formData = data;

        if (pathname) {
            formData = {
                ...formData,
                preferredLanding: pathname
            }

            dispatch(updateUser(formData));
            window.location.href = pathname
        }
    };
        
    return (
        <form onSubmit={ handleSubmit(onSubmit) }>
            <Grid container xs={12}>
                <Grid item xs={12} sm={12}>
                    <Grid item xs={12}>
                        <Typography variant='h6' className='form-fields-title'>
                            Set this page as your preferred landing.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} className='button-wrapper'>
                        <Grid item>
                            <Button 
                                onClick={() => { dispatch(hideModal()) }}
                                disabled={ isSubmitting }
                                variant='contained'
                            >
                                <CloseIcon fontSize="inherit" />&nbsp;Close
                            </Button>
                        </Grid>
                        <Button 
                            type='submit'
                            disabled={ isSubmitting }
                            variant='contained'
                        >
                            <SaveIcon fontSize="inherit" />&nbsp;Apply
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}

export default UserPreferredLandingForm;