import React from "react";
import { Grid, Typography } from "@mui/material";

export default function RenderClassificationByProduct ({classifications, fontSize='inherit'}) {
  if (classifications === undefined || !classifications) {
    return <></>;
  }

  return (
    <>
    { classifications.map((classification) => (
      <Grid key={`classification-${classification['@id']}`} container gap={2} flexWrap='nowrap' flexDirection='row' justifyContent='flex-start'>
        <Grid item sx={{ display:'flex', justifyContent: 'flex-start', alignItems: 'center', width: '65px !important'  }}>
          <img
              src={`/img/classification/${ classification.pictogram }.png`}
              alt={ `${ classification.type }: ${ classification.class }`}
              loading="lazy"
              height={65}
              width={65}
            />
        </Grid>
        <Grid item paddingLeft={0}>
          <Typography fontSize='inherit' fontWeight={700}>
            { classification.type } - { classification.class }
          </Typography>
          <Typography fontSize={fontSize}>
            { classification.usage }
          </Typography>
        </Grid>
      </Grid>
    )) }
  </>
  );
}