import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getTenants } from "../../data/tenant.js";
import { addTenantFilterValue, removeTenantFilterValue } from '../../data/filter.tsx';

import {
    Avatar,
    List,
    ListItem,
    ListItemText,
    ListItemButton,
    ListItemAvatar,
    Typography,
} from "@mui/material";


const TenantFilter = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getTenants());
    }, [dispatch]);

    const selectedData = useSelector((state) => ({
        tenants: state.tenants,
        filter: state.filter,
    }));

    const {
        tenants,
        filter,
    } = selectedData;

    useEffect(() => {
        if (filter.tenant === undefined && tenants && tenants.data.length > 0) {
            dispatch(addTenantFilterValue(tenants.data[0]));
        }
    }, [tenants, filter]);

    const tenantFilterHandler = (tenant) => {
        if (!filter.tenant) {
          dispatch(addTenantFilterValue(tenant));
        } else {
          dispatch(removeTenantFilterValue(tenant));
        }
    };

    const tenantInformation = (tenant: any) => {
        return (
            <ListItem 
                key={`tenant-filter-${tenant['@id']}`}
                className={ filter.tenant && filter.tenant['@id'] === tenant['@id'] ? 'active-menu' : ''}
                alignItems="flex-start"
                onClick={() => tenantFilterHandler(tenant)}
                disablePadding
            >
                <ListItemButton>
                <ListItemAvatar>
                    <Avatar 
                        alt={`${tenant.name} Logo`} 
                        src={tenant.logo ? tenant.logo.contentUrl : ''}  
                        sx={{ height: '30px', width: '30px', justifyContent: "center", display: "flex" }}
                    />
                </ListItemAvatar>
                <ListItemText
                    disableTypography
                    primary={<Typography sx={{ fontWeight: 700 }}>{tenant.name}</Typography>}
                />
                </ListItemButton>
            </ListItem>
        )
    }
   
    return (
        <List>
            { tenants && tenants.data.map((tenant:any) => (
                tenantInformation(tenant)
            ))}
        </List>
    )
}

export default TenantFilter
