import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../Layout.tsx";
import { getTrainings } from "../../data/training.js";
import { selectFilteredTrainings, selectFilteredUsers } from "../../data/selectors/filterSelector.js";
import { formatDateToString } from "../Helpers.tsx";
import i18next from '../../i18n.js';
import { getUsers } from "../../data/users.js";
import { addActiveFilterValue } from "../../data/filter.tsx";
import RenderTrainingList from "../partial/Trainings.tsx";

import {
  GridRowsProp,
} from "@mui/x-data-grid";

import {
  Grid,
  Typography,
} from "@mui/material";

const TrainingList = () => {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);

  const filteredTrainings = useSelector(selectFilteredTrainings);
  const filteredUsers = useSelector(selectFilteredUsers);

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getTrainings());
    dispatch(addActiveFilterValue(true));
  }, [dispatch]);

  useEffect(() => {
    setRows(formatDataGridData(filteredTrainings));
  }, [filteredTrainings]);

  const formatDataGridData: any = (trainings): GridRowsProp[] => {
    let formattedSites: GridRowsProp[] = [];

    trainings.map((training) => {
      formattedSites.push({
        id: training["@id"],
        status: training.status ? i18next.t(training.status) : '',
        title: training.title,
        certificate: training.certificateReceived,
        document: training,
        documentDate: formatDateToString(training.documentDate),
        trainingUsers: training,
      });
    });

    return formattedSites;
  };

  return (
    <Layout>
      <Grid container>
        <Grid item xs={12} sm={12} padding={1}>
          <Grid item  sx={{ height: 'auto' }}>
            <Grid
              className="settings-page-header"
              item
              xs={12}
              sm={12}
              padding={1}
            >
              <Grid item>
                <Typography variant="h6" gutterBottom>
                  {i18next.t("trainings")}
                </Typography>
              </Grid>
            </Grid>
            <RenderTrainingList trainings={filteredTrainings} users={filteredUsers} rows={rows} editable={false}/>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default TrainingList;
