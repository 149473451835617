import axios from "axios";
import { hideChildModal, hideModal } from "./modal.tsx";
import { useJWTExpirationCheck } from "./authentication.js";

/*****************/
/* INITIAL STATE */
/*****************/
const initialState = {
  loading: false,
  error: "",
  data: [],
  query: { total: null },
};

/*********/
/* TYPES */
/*********/
const FETCH_INCOMING_TRANSPORTS = "FETCH_INCOMING_TRANSPORTS";
const FETCH_INCOMING_TRANSPORTS_SUCCESS = "FETCH_INCOMING_TRANSPORTS_SUCCESS";
const FETCH_INCOMING_TRANSPORTS_ERROR = "FETCH_INCOMING_TRANSPORTS_ERROR";
const PUT_INCOMING_TRANSPORTS = "PUT_INCOMING_TRANSPORTS";
const POST_INCOMING_TRANSPORTS = "POST_INCOMING_TRANSPORTS";
const DELETE_INCOMING_TRANSPORTS = "DELETE_INCOMING_TRANSPORTS";
const FETCH_NEXT_INCOMING_TRANSPORTS = "FETCH_NEXT_INCOMING_TRANSPORTS";
const FETCH_NEXT_INCOMING_TRANSPORTS_SUCCESS =
  "FETCH_NEXT_INCOMING_TRANSPORTS_SUCCESS";
const UPDATE_INCOMING_TRANSPORTS = "UPDATE_INCOMING_TRANSPORTS";

const cache = {};

async function fetchMultiRel(rels, headers) {
  if (rels === undefined) {
    return;
  }

  const dataRels = rels.map(async (rel) => {
    return await fetchRel(rel, headers);
  });

  return Promise.all(dataRels).then((values) => {
    return values;
  });
}

async function fetchRel(rel, headers) {
  // Prevent fetching twice the same relation
  if (rel === undefined) {
    return;
  }

  if (cache[rel]) {
    return cache[rel];
  }

  // use a Promise to wait for pushed relation in the local cache
  let res;
  cache[rel] = new Promise((resolve) => {
    res = resolve;
  });

  const resp = await fetch(`${process.env.REACT_APP_URL}${rel}`, {
    ...headers,
  });
  const json = await resp.json();
  res(json);
  return json;
}

/*******************/
/* ACTION CREATORS */
/*******************/
export const getIncomingTransports = () => async (dispatch, getState) => {
  const {
    incomingTransports: { query, data },
  } = getState();

  if (data.length !== query.total) {
    const headers = {
      headers: {
        "Content-Type": "application/ld+json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    dispatch(loadTransports());

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/incoming_transports?page=1&order[number]desc`,
      {
        headers: {
          "Content-Type": "application/ld+json; charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Preload: `"/hydra:member/*/tenant", "/hydra:member/*/labels/*", "/hydra:member/incomingTransportProducts/*"`,
        },
      }
    );

    const json = await response.json();
    useJWTExpirationCheck(json);

    if (json["hydra:member"].length === 0) {
      dispatch(setError("No transports found"));
    } else {
      let updatedObjects = json["hydra:member"].slice();

      dispatch(
        setTransports(json["hydra:member"], {
          total: json["hydra:totalItems"],
        })
      );

      const objects = await updatedObjects.map(async (id) => {
        let object = structuredClone(id);
        object.tenant = await fetchRel(id.tenant, headers);
        object.labels = await fetchMultiRel(id.labels, headers);
        object.incomingTransportProducts = await fetchMultiRel(
          id.incomingTransportProducts,
          headers
        );

        return object;
      });

      Promise.all(objects).then((values) => {
        dispatch(updateTransports(values));
      });

      if (
        json &&
        json["hydra:view"] &&
        json["hydra:view"] !== undefined &&
        json["hydra:view"]["hydra:next"] &&
        json["hydra:view"]["hydra:next"] !== undefined
      ) {
        dispatch(getNextTransports(json["hydra:view"]["hydra:next"]));
      }
    }
  }
};

export const getNextTransports = (nextUrl) => async (dispatch, getState) => {
  const headers = {
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const response = await fetch(`${process.env.REACT_APP_URL}${nextUrl}`, {
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      Preload: `"/hydra:member/*/tenant", "/hydra:member/*/labels/*", "/hydra:member/incomingTransportProducts/*"`,
    },
  });

  const json = await response.json();

  if (json["hydra:member"].length === 0) {
    dispatch(setError("No transports found"));
  } else {
    let updatedObjects = json["hydra:member"].slice();

    dispatch(setNextTransports(json["hydra:member"]));

    const objects = await updatedObjects.map(async (id) => {
      let object = structuredClone(id);
      object.tenant = await fetchRel(id.tenant, headers);
      object.labels = await fetchMultiRel(id.labels, headers);
      object.incomingTransportProducts = await fetchMultiRel(
        id.incomingTransportProducts,
        headers
      );

      return object;
    });

    Promise.all(objects).then((values) => {
      dispatch(updateTransports(values));
    });

    if (
      json &&
      json["hydra:view"] &&
      json["hydra:view"] !== undefined &&
      json["hydra:view"]["hydra:next"] &&
      json["hydra:view"]["hydra:next"] !== undefined
    ) {
      dispatch(getNextTransports(json["hydra:view"]["hydra:next"]));
    }
  }
};

export const updateIncomingTransport =
  (transport, keepModal = false) =>
  async (dispatch, getState) => {
    dispatch(putTransport());
    const {
      incomingTransports: {
        data,
        query: { total },
      },
    } = getState();

    const response = await axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}${transport["@id"].replace(
        "/api",
        ""
      )}`,
      headers: {
        "Content-Type": "application/ld+json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: transport,
    });

    if (response.status === 200) {
      const resultData = data.map((transport) => {
        if (transport["@id"] === response.data["@id"]) {
          return response.data;
        }

        return transport;
      });

      dispatch(setTransports(resultData, total));
      if (!keepModal) {
        dispatch(hideModal());
      }
    } else {
      setError("Something went wrong updating");
    }
  };

export const createIncomingTransport =
  (transport, callback) => async (dispatch, getState) => {
    dispatch(postTransport());
    const {
      incomingTransports: {
        data,
        query: { total },
        modal: childModal,
      },
    } = getState();

    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/incoming_transports`,
      headers: {
        "Content-Type": "application/ld+json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: transport,
    });

    if (response.status === 201) {
      const resultData = [...data, ...[response.data]];

      dispatch(setTransports(resultData, total));

      if (childModal) {
        dispatch(hideChildModal());
        callback("transport", response.data["@id"]);
      } else {
        dispatch(hideModal());
      }
    } else {
      setError("Something went wrong deleting");
    }
  };

export const removeIncomingTransport =
  (transport) => async (dispatch, getState) => {
    dispatch(putTransport());
    const {
      incomingTransports: {
        data,
        query: { total },
      },
    } = getState();

    const response = await axios({
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}${transport["@id"].replace(
        "/api",
        ""
      )}`,
      headers: {
        "Content-Type": "application/ld+json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (response.status === 204) {
      const resultData = data.filter((x) => x["@id"] !== transport["@id"]);

      dispatch(setTransports(resultData, total));
      dispatch(hideModal());
    } else {
      setError("Something went wrong deleting");
    }
  };

export const loadTransports = () => ({ type: FETCH_INCOMING_TRANSPORTS });

export const putTransport = () => ({ type: PUT_INCOMING_TRANSPORTS });

export const postTransport = () => ({ type: PUT_INCOMING_TRANSPORTS });

export const deleteTransport = () => ({ type: DELETE_INCOMING_TRANSPORTS });

export const setTransports = (transports, query) => ({
  type: FETCH_INCOMING_TRANSPORTS_SUCCESS,
  payload: { transports, query },
});

export const loadNextTransports = (page) => ({
  type: FETCH_NEXT_INCOMING_TRANSPORTS,
  payload: page,
});

export const setNextTransports = (transports) => ({
  type: FETCH_NEXT_INCOMING_TRANSPORTS_SUCCESS,
  payload: transports,
});

export const updateTransports = (transports) => ({
  type: UPDATE_INCOMING_TRANSPORTS,
  payload: transports,
});

export const setError = (msg) => ({
  type: FETCH_INCOMING_TRANSPORTS_ERROR,
  payload: msg,
});

/***********/
/* REDUCER */
/***********/
const incomingTransportsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_INCOMING_TRANSPORTS:
      return {
        ...state,
        loading: true,
        error: "",
        query: {
          total: 0,
        },
      };
    case FETCH_INCOMING_TRANSPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        data: payload.transports,
        query: {
          ...payload.query,
        },
      };
    case PUT_INCOMING_TRANSPORTS:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case POST_INCOMING_TRANSPORTS:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case DELETE_INCOMING_TRANSPORTS:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_INCOMING_TRANSPORTS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case FETCH_NEXT_INCOMING_TRANSPORTS:
      return {
        ...state,
        error: "",
      };
    case FETCH_NEXT_INCOMING_TRANSPORTS_SUCCESS:
      console.log(payload);
      return {
        ...state,
        loading: false,
        data: [...state.data, ...payload],
      };
    case UPDATE_INCOMING_TRANSPORTS:
      console.log(
        state.data,
        payload,
        state.data.map(
          (transport) =>
            payload.find((o) => o["@id"] === transport["@id"]) || transport
        )
      );
      return {
        ...state,
        loading: false,
        data: state.data.map(
          (transport) =>
            payload.find((o) => o["@id"] === transport["@id"]) || transport
        ),
      };
    default:
      return state;
  }
};
export default incomingTransportsReducer;
