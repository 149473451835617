import * as React from 'react';
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";

export const InputTextareaField = ({ name, label, control, required = false }: any) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <TextField
          variant="outlined"
          helperText={ error ? error.message : null }
          error={!!error}
          onChange={onChange}
          value={value ?? ''}
          fullWidth
          label={label}
          required={ required }
          multiline
          rows={4}
          inputProps={{ style: { overflow: 'auto' } }}
        />
      )}
    />
  );
};