// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `form .form-fields-title {
    margin-bottom: 7px;
    color: #1976D2;
}

form div.MuiContainer-root {
    padding: 0;
}

form div.form-field-datetime input {
    padding: 9px;
}

form div.form-field-datetime label {
    line-height: 1;
}

form div.form-field-datetime div.MuiFormControl-root {
    width: 100%;
}

form div.MuiPaper-root {
    padding: 10px;
}

form div.form-fields-container {
    display: flex;
    gap: 5px;
    margin-bottom: 12px;
}

form:focus-visible, div.MuiContainer-root:focus-visible, div.MuiBox-root:focus-visible {
    outline: 0;
}

form div.button-wrapper {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #DDDDDD;
    margin-top: 10px;
    padding: 10px 0 0 !important;
}

div.MuiFormGroup-root {
    display: flex;
    flex-direction: row;
}

div.transport-medium-content {
    scale: 0.7;
    height: 45px;
    width: 45px;
    background-color: #DD7320;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #000000;
    flex-direction: column;
}

div.transport-medium-content > hr.MuiDivider-root {
    border-color: #000000;
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/styles/form.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,QAAQ;IACR,mBAAmB;AACvB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,6BAA6B;IAC7B,gBAAgB;IAChB,4BAA4B;AAChC;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,WAAW;IACX,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,yBAAyB;IACzB,sBAAsB;AAC1B;;AAEA;IACI,qBAAqB;IACrB,WAAW;AACf","sourcesContent":["form .form-fields-title {\n    margin-bottom: 7px;\n    color: #1976D2;\n}\n\nform div.MuiContainer-root {\n    padding: 0;\n}\n\nform div.form-field-datetime input {\n    padding: 9px;\n}\n\nform div.form-field-datetime label {\n    line-height: 1;\n}\n\nform div.form-field-datetime div.MuiFormControl-root {\n    width: 100%;\n}\n\nform div.MuiPaper-root {\n    padding: 10px;\n}\n\nform div.form-fields-container {\n    display: flex;\n    gap: 5px;\n    margin-bottom: 12px;\n}\n\nform:focus-visible, div.MuiContainer-root:focus-visible, div.MuiBox-root:focus-visible {\n    outline: 0;\n}\n\nform div.button-wrapper {\n    display: flex;\n    justify-content: space-between;\n    border-top: 1px solid #DDDDDD;\n    margin-top: 10px;\n    padding: 10px 0 0 !important;\n}\n\ndiv.MuiFormGroup-root {\n    display: flex;\n    flex-direction: row;\n}\n\ndiv.transport-medium-content {\n    scale: 0.7;\n    height: 45px;\n    width: 45px;\n    background-color: #DD7320;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border: 1px solid #000000;\n    flex-direction: column;\n}\n\ndiv.transport-medium-content > hr.MuiDivider-root {\n    border-color: #000000;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
