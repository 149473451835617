import contactsReducer from "./contacts.js";
import userReducer from "./user.js";
import usersReducer from "./users.js";
import productReducer from "./product.js";
import labelsReducer from "./labels.js";
import classificationsReducer from "./classifications.js";
import modalReducer from "./modal.tsx";
import { configureStore } from "@reduxjs/toolkit";
import incomingTransportReducer from "./incomingTransport.js";
import incomingTransportsReducer from "./incomingTransports.js";
import outgoingTransportReducer from "./outgoingTransport.js";
import outgoingTransportsReducer from "./outgoingTransports.js";
import transporterReducer from "./transporters.js";
import packagingReducer from "./packaging.js";
import siteReducer from "./site.js";
import tenantReducer from "./tenant.js";
import filterReducer from "./filter.tsx";
import currentUserReducer from "./currentUser.tsx";
import fileReducer from "./files.js";
import trainingReducer from "./training.js";
import driverReducer from "./driver.js";
import inspectionReducer from "./inspection.js";

const store = configureStore({
  reducer: {
    currentUser: currentUserReducer,
    filter: filterReducer,
    user: userReducer,
    users: usersReducer,
    labels: labelsReducer,
    products: productReducer,
    modal: modalReducer,
    contacts: contactsReducer,
    classifications: classificationsReducer,
    incomingTransports: incomingTransportsReducer,
    incomingTransport: incomingTransportReducer,
    outgoingTransports: outgoingTransportsReducer,
    outgoingTransport: outgoingTransportReducer,
    transporters: transporterReducer,
    packagings: packagingReducer,
    sites: siteReducer,
    tenants: tenantReducer,
    files: fileReducer,
    trainings: trainingReducer,
    drivers: driverReducer,
    inspections: inspectionReducer,
  },
});

export default store;
