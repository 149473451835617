import React, { useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { DEFAULT_LANGUAGES, formatAddressToString, formatDateToString, getPackagingByLanguages, getProductDiscriptionByLanguages, thousandSeparator } from "../Helpers.tsx";
import { hideModal } from "../../data/modal.tsx";
import { useDispatch, useSelector } from "react-redux";
import { InputTextField } from "../FormFields/RenderInputTextField.tsx";
import { SignatureField } from "../FormFields/RenderSignatureField.tsx";
import GeneratePDF, { DOCUMENT_ORIENTATION_PORTRAIT } from "../partial/GeneratePdf.tsx";
import ProductView from "../partial/ProductView.tsx";
import ProductSDS from "../partial/ProductSDS.tsx";
import i18next from '../../i18n.js';

import {
    updateIncomingTransport,
} from "../../data/incomingTransports.js";

import {
    updateOutgoingTransport,
} from "../../data/outgoingTransports.js";

import {
    Button,
    Grid,
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow,
    Typography,
} from "@mui/material";

import {
    Close as CloseIcon,
    Save as SaveIcon,
  } from "@mui/icons-material";
  
const categories = [
    {
        value: "0",
        label: "Category 0",
        multiplier: 10,
    },
    {
        value: "1",
        label: "Category 1",
        multiplier: 20,
    },
    {
        value: "2",
        label: "Category 2",
        multiplier: 3,
    },
    {
        value: "3",
        label: "Category 3",
        multiplier: 1,
    },
    {
        value: "4",
        label: "Category 4",
        multiplier: 0,
    },
];

const ADRTransport = ({ transport, type }) => {
    const dispatch = useDispatch();
    
    const selectedData = useSelector((state) => ({
        user: state.currentUser.data,
    }));
    
    const {
        user
    } = selectedData;

    const currentUser = transport.user ?? user
    const tenant =  currentUser.tenant ?? undefined;
    const logo = tenant && tenant.logo ? tenant.logo?.contentUrl : undefined;
    const documentLanguages = transport && transport.documentLanguages ? transport.documentLanguages : DEFAULT_LANGUAGES;
    const transportProductsType =  type === 'incoming' ? 'incomingTransportProducts' : 'outgoingTransportProducts'

    const getADRpoints = (transportProduct) => {
        let category = categories.find(({ value }) => value === transportProduct.product?.category);

        if (category === undefined) {
          return 0;
        }

        return transportProduct.quantity * category.multiplier;
    }

    const getTotalADRpoints = () => {
        let totalPoints = 0;
        
        transport[transportProductsType].map((transportProduct) => {
            totalPoints += getADRpoints(transportProduct);
        })

        return totalPoints;
    }

    const getTotalADRpointsString = () => {
        let totalPoints = 0;
        
        transport[transportProductsType].map((transportProduct) => {
            totalPoints += getADRpoints(transportProduct);
        })

        return thousandSeparator(getTotalADRpoints());
    }

    const getTotalQuantity = () => {
        let totalQuantity = 0;
        
        transport[transportProductsType].map((transportProduct) => {
            totalQuantity += transportProduct.quantity ?? 0;
        })

        return thousandSeparator(totalQuantity);
    }

    const { handleSubmit, formState, control, setValue } = useForm({
        defaultValues: transport,
    });
  
    const { isSubmitting } = formState;

    useEffect(() => {
        if (!transport.transportIssuer) {
          setValue('transportIssuer', `${user?.lastName ?? ''} ${user?.firstName ?? ''}`)
        }

        if (!transport.transportSignatory) {
          setValue('transportSignatory', transport.transporter?.name ?? '')
        }
      }, [user]);

    const onSubmit: SubmitHandler = (data) => {
        if (!transport) {
          return;
        }
        
        let incoming = transport["@type"].includes("incoming")
        dispatch(incoming ? updateIncomingTransport(data) : updateOutgoingTransport(data));
    };

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container className='pdf-document-content content--landscape' flexDirection='column'>
            <Grid item>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell width={200} sx={{ backgroundColor: 'transparent !important' }}>
                                    { logo ? (
                                        <img src={`${ logo }`} height={ 80 } alt="TenantLogo"/>
                                    ) : ''}
                                </TableCell>
                                <TableCell align='center' sx={{ backgroundColor: 'transparent !important' }}>
                                    <Typography fontWeight={700} fontSize='20px'>ADR Transport</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </TableContainer>
                <TableContainer>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell>{ tenant && tenant.name }</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>{ formatDateToString(transport.documentDate)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{ formatAddressToString( tenant && tenant.address) }</TableCell>
                                <TableCell>Transport Nr</TableCell>
                                <TableCell>{ transport.number ?? '' }</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Transporter</TableCell>
                                <TableCell>{ transport.transporter?.name ?? '' }</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>License plate</TableCell>
                                <TableCell>{ transport.vehicleNumber ?? '' } </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <TableContainer>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell width='45%' align='center'>
                                    <Typography fontWeight={700} size='inherit'>{ transport?.fromContact?.name ?? '' }</Typography>
                                    { formatAddressToString(transport?.fromContact?.address) }
                                </TableCell>
                                <TableCell align='center'> 
                                    <img src='/img/arrow-right.jpg' height='15px' />
                                </TableCell>
                                <TableCell width='45%'  align='center'>
                                    <Typography fontWeight={700} size='inherit'>{ transport.toContact?.name ?? '' }</Typography>
                                    { formatAddressToString(transport.toContact?.address) }
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={12} sm={12}>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                            <TableCell width={150} sx={{ backgroundColor: '#DDDDDD !important' }}>UN</TableCell>
                            <TableCell width={800} sx={{ backgroundColor: '#DDDDDD !important' }}>Official name</TableCell>
                            <TableCell sx={{ backgroundColor: '#DDDDDD !important' }} align='center'>Quantity</TableCell>
                            <TableCell sx={{ backgroundColor: '#DDDDDD !important', width: 10 }} align='center'>Unit</TableCell>
                            <TableCell width={250} sx={{ backgroundColor: '#DDDDDD !important' }}>Packaging</TableCell>
                            <TableCell width={230} sx={{ backgroundColor: '#DDDDDD !important' }} align='center'>ADR points</TableCell>
                            <TableCell sx={{ backgroundColor: '#DDDDDD !important', borderRight: '1px solid #DDDDDD' }} align='center'>Cat</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { transport[transportProductsType].map((transportProduct) => (
                                <TableRow>
                                    <TableCell sx={{ borderLeft: '1px solid #DDDDDD' }}>
                                        <Typography fontWeight={700} fontSize='inherit'>
                                            {`UN ${transportProduct.product?.unNr ?? ''}`}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <ProductView product={transportProduct.product} languages={documentLanguages}/>
                                    </TableCell>
                                    <TableCell align='center'>
                                        { transportProduct.quantity }
                                    </TableCell>
                                    <TableCell>
                                        { `${ transportProduct.product && transportProduct.product.unit && transportProduct.product.unit ? transportProduct.product.unit : ''  }`}
                                    </TableCell>
                                    <TableCell>
                                        <Grid container flexWrap='nowrap' gap='5px' alignItems='center'>
                                            <Grid item>
                                                <Typography fontWeight={700} fontSize='inherit'>
                                                    { transportProduct.packagingQuantity ? thousandSeparator(transportProduct.packagingQuantity) : '' }
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                { transportProduct.product && transportProduct.product.packaging ? (
                                                    getPackagingByLanguages(transportProduct.product.packaging, documentLanguages) 
                                                ) : ''}
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                    <TableCell align='center' >{ thousandSeparator(getADRpoints(transportProduct)) }</TableCell>
                                    <TableCell align='center' sx={{ borderRight: '1px solid #DDDDDD'  }}>{`${transportProduct.product?.category ?? ''}`}</TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell colSpan={2} sx={{ borderLeft: '1px solid #DDDDDD' }} align='right'>
                                    <Typography fontWeight={700} fontSize='inherit'>Total</Typography>
                                </TableCell>
                                <TableCell colSpan={2} align='center' sx={{ backgroundColor:'#DDDDDD', fontWeight:700 }}>{ getTotalQuantity() }</TableCell>
                                <TableCell>
                                    <Typography fontWeight={700} fontSize='inherit'>Total ADR points</Typography>
                                </TableCell>
                                <TableCell  colSpan={2} align='center' sx={{ backgroundColor:'#DDDDDD', fontWeight:700, borderRight: '1px solid #DDDDDD' }}>{ getTotalADRpointsString() }</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan='100%' sx={{ borderBottom: 'transparent', paddingLeft: '0' }} align='center'>
                                    <Typography fontSize='9px'>
                                        { getTotalADRpoints() <= 1000 ? (
                                            <>
                                                Transport in accordance with 1.1.3.6.3 and 1.1.3.6.4
                                                / Vervoer in overeenstemming met 1.1.3.6.3 en 1.1.3.6.4
                                                / Transport selon le 1.1.3.6.3 et 1.1.3.6.4
                                                / Beförderung gemäß den Abschnitten 1.1.3.6.3 und 1.1.3.6.4
                                            </>
                                        ): '' }
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={12} sm={12} sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '15px' }}>
                <Grid item xs={6}>
                    <Typography fontWeight={700} align='center'>Issuer</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography fontWeight={700} align='center'>Driver</Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} sx={{ display: 'flex', justifyContent: 'space-between', gap: '5px' }}>
                <Grid item xs={6}>
                    <Grid item data-html2canvas-ignore="true">
                    <InputTextField
                        name='transportIssuer'
                        label='Issuer'
                        control={ control }
                    />
                    </Grid>
                    <SignatureField
                        name = 'transportIssuerSignature'
                        setValue = { setValue }
                        signatureLabel = {transport.transportIssuer ?? ''}
                        existingSignature = { transport.transportIssuerSignature  }
                    />
                </Grid>
                <Grid item xs={6}>
                    <Grid item data-html2canvas-ignore="true">
                        <InputTextField
                            name='transportSignatory'
                            label='Driver'
                            control={ control }
                        />
                    </Grid>
                    <SignatureField
                        name = 'transportSignatorySignature'
                        setValue = { setValue }
                        signatureLabel = {transport.transportSignatory ?? ''}
                        existingSignature = { transport.transportSignatorySignature  }
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6} data-html2canvas-ignore="true">
                { transport[transportProductsType].reduce((objectArray, product) => {
                    if (!objectArray.some(object => object['@id'] !== product['@id'])) {
                        objectArray.push(product);
                    }
                    return objectArray;
                }, []).map((transportProduct) => (
                    <ProductSDS product={transportProduct.product} />
                ))}                       
            </Grid>
        </Grid>
        <Grid item xs={12} className="button-wrapper">
            <Grid item>
              <Button
                onClick={() => {
                  dispatch(hideModal());
                }}
                disabled={isSubmitting}
                variant="contained"
              >
                <CloseIcon fontSize="inherit" />
                &nbsp;{i18next.t("close")}
              </Button>
            </Grid>
            <Grid item sx={{ display: "flex", gap: "5px" }}>
              <GeneratePDF filename={transport.number} orientation={ DOCUMENT_ORIENTATION_PORTRAIT } />
              <Button type="submit" disabled={isSubmitting} variant="contained">
                <SaveIcon fontSize="inherit" />
                &nbsp;{i18next.t("save")}
              </Button>
            </Grid>
        </Grid>
        </form>
    )
}

export default ADRTransport;
