import axios from "axios";

/*****************/
/* INITIAL STATE */
/*****************/
const initialState = {
  loading: false,
  error: "",
  data: [],
  progress: {
    page: 0,
    pages: 0,
    total: 0,
    loaded: 0,
  },
};

/*********/
/* TYPES */
/*********/
const FETCH_CLASSIFICATIONS = "FETCH_CLASSIFICATIONS";
const FETCH_CLASSIFICATIONS_SUCCESS = "FETCH_CLASSIFICATIONS_SUCCESS";
const FETCH_CLASSIFICATIONS_ERROR = "FETCH_CLASSIFICATIONS_ERROR";
const FETCH_NEXT_CLASSIFICATIONS = "FETCH_NEXT_CLASSIFICATIONS";
const FETCH_NEXT_CLASSIFICATIONS_SUCCESS = "FETCH_NEXT_CLASSIFICATIONS_SUCCESS";

/*******************/
/* ACTION CREATORS */
/*******************/
export const getClassifications = () => async (dispatch) => {
  dispatch(loadClassifications());

  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/classifications?page=1`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  
  if (response.data["hydra:member"].length === 0) {
    dispatch(setError("No classifications found"));
  } else {
    dispatch(
      setClassifications(
        response.data["hydra:member"],
        parseInt(response.data["hydra:totalItems"], 10)
      )
    );
    if (
      parseInt(response.data["hydra:totalItems"], 10) >
      response.data["hydra:member"].length
    ) {
      dispatch(getClassifications());
    }
  }
};

export const getNextClassifications = () => async (dispatch, getState) => {
  const {
    classifications: {
      progress: { page, pages },
    },
  } = getState();
  const pageToLoad = page + 1;
  dispatch(loadNextClassifications(pageToLoad));

  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/classifications?page=${pageToLoad}`,
    headers: {
      "Content-Type": "application/ld+json; charset=utf-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  dispatch(setNextClassifications(response.data["hydra:member"]));
  if (pageToLoad < pages) {
    dispatch(getNextClassifications());
  }
};

export const loadClassifications = () => ({ type: FETCH_CLASSIFICATIONS });

export const setClassifications = (classifications, total) => ({
  type: FETCH_CLASSIFICATIONS_SUCCESS,
  payload: { classifications, total },
});

export const loadNextClassifications = (page) => ({
  type: FETCH_NEXT_CLASSIFICATIONS,
  payload: page,
});
export const setNextClassifications = (classifications) => ({
  type: FETCH_NEXT_CLASSIFICATIONS_SUCCESS,
  payload: classifications,
});

export const setError = (msg) => ({
  type: FETCH_CLASSIFICATIONS_ERROR,
  payload: msg,
});

/***********/
/* REDUCER */
/***********/
const classificationsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CLASSIFICATIONS:
      return {
        ...state,
        loading: true,
        error: "",
        progress: {
          page: 1,
          pages: 0,
          loaded: 0,
          total: 0,
        },
      };
    case FETCH_CLASSIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        data: payload.classifications,
        progress: {
          ...state.progress,
          loaded: payload.classifications.length,
          pages: Math.ceil(payload.total / payload.classifications.length),
          total: payload.total,
        },
      };

    case FETCH_CLASSIFICATIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case FETCH_NEXT_CLASSIFICATIONS:
      return {
        ...state,
        error: "",
        progress: {
          ...state.progress,
          page: payload,
        },
      };
    case FETCH_NEXT_CLASSIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, ...payload],
        progress: {
          ...state.progress,
          loaded: state.progress.loaded + payload.length,
        },
      };
    default:
      return state;
  }
};

export default classificationsReducer;
